import { useSelector } from "react-redux";
import { groupSelector } from "../../app/reducers/GroupsReducer";
import { isNullOrEmpty } from "../../utils/SyncUtils";

export const GroupAdminOnly = ({ children }) => {
  const { currentGroup } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let isAdmin = false;
  var currentUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentUser.length > 0) {
    isAdmin = currentUser[0].isAdmin && currentUser[0].accept ? true : false;
  }
  return isAdmin ? children : null;
};

export const GroupAdminPlusInvite = ({ children }) => {
  const { currentGroup } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let isAdmin = false;
  var currentUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentUser.length > 0) {
    isAdmin = currentUser[0].isAdmin ? true : false;
  }
  return isAdmin ? children : null;
};

export const IsYouAndGroupAdminInviteNotAccepted = ({ children, rowuser }) => {
  const { currentGroup, groupUser } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let isMeAndGroupAdmin = false;
  var currentGroupUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentGroupUser.length > 0) {
    isMeAndGroupAdmin =
      currentGroupUser[0].isAdmin && !currentGroupUser[0].accept ? true : false;
  }

  if (rowuser) {
    if (groupUser.toLowerCase() !== rowuser.toLowerCase()) {
      isMeAndGroupAdmin = false;
    }
  }
  return isMeAndGroupAdmin ? children : null;
};

export const AcceptedUser = ({ children, rowuser }) => {
  const { currentGroup, groupUser } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let acceptedMember = false;
  var currentGroupUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentGroupUser.length > 0) {
    acceptedMember = currentGroupUser[0].accept ? true : false;
  }

  if (rowuser) {
    if (groupUser.toLowerCase() !== rowuser.toLowerCase()) {
      acceptedMember = false;
    }
  }

  return acceptedMember ? children : null;
};

export const NotAcceptedUser = ({ children, rowuser }) => {
  const { currentGroup, groupUser } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let notacceptedMember = false;
  var currentGroupUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentGroupUser.length > 0) {
    notacceptedMember = !currentGroupUser[0].accept ? true : false;
  }

  if (rowuser) {
    if (groupUser.toLowerCase() !== rowuser.toLowerCase()) {
      notacceptedMember = false;
    }
  }

  return notacceptedMember ? children : null;
};

export const IsYouAndAmUser = ({ children, rowuser }) => {
  const { currentGroup, groupUser } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let isMeAndMember = false;
  var currentGroupUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentGroupUser.length > 0) {
    isMeAndMember = currentGroupUser[0].isAdmin
      ? false
      : currentGroupUser[0].accept
      ? true
      : false;
  }

  if (rowuser) {
    if (groupUser.toLowerCase() !== rowuser.toLowerCase()) {
      isMeAndMember = false;
    }
  }
  // isMeAndMember = false;
  return isMeAndMember ? children : null;
};

export const IsYouAndAmUserPlusInvite = ({ children, rowuser }) => {
  const { currentGroup, groupUser } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let isMeAndMember = false;
  var currentGroupUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentGroupUser.length > 0) {
    isMeAndMember = currentGroupUser[0].isAdmin ? false : true;
  }

  if (rowuser) {
    if (groupUser.toLowerCase() !== rowuser.toLowerCase()) {
      isMeAndMember = false;
    }
  }
  return isMeAndMember ? children : null;
};

export const IsNotMeButAmUserPlusInvite = ({ children, rowuser }) => {
  const { currentGroup } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let isNotMeAndMember = false;
  var currentGroupUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (rowuser) {
    var result =
      currentGroupUser[0].email !== rowuser && !currentGroupUser[0].isAdmin;
    //console.log(currentGroupUser, rowuser, groupUser, result, "Charles000")
    isNotMeAndMember = result;
  }
  // else {
  //     isNotMeAndMember = false;
  // }
  // if (currentGroupUser.length > 0) {
  //     isNotMeAndMember = currentGroupUser[0].isAdmin ? false : true;
  // }

  return isNotMeAndMember ? children : null;
};

export const IsYouOnly = ({ children }) => {
  const { currentGroup } =
    useSelector(groupSelector);
  const stateAuth = useSelector((state) => state.auth);
  const user = stateAuth.user;

  let isMe = false;
  var currentUser = currentGroup.members.filter((x) => {
    return x.user && x.user.id.toString() === user.profile.sub;
  });
  if (currentUser.length > 0) {
    isMe = true;
  }
  return isMe ? children : null;
};

export const IAMOnly = ({ children }) => {
  const { user } = useSelector((state) => state.mycontainer);
  const stateAuth = useSelector((state) => state.auth);
  const authUser = stateAuth.user;

  let isMe = false;

  if (user === authUser.profile.username) {
    isMe = true;
  }
  return isMe ? children : null;
};

export const GroupAdminOrIAMOnly = ({ children, containerGuid }) => {
  const { currentGroup } = useSelector(groupSelector);
  const { user, container } = useSelector((state) => state.mycontainer);
  const stateAuth = useSelector((state) => state.auth);
  const authUser = stateAuth.user;
  if (isNullOrEmpty(containerGuid)){
    return null
  }
  if (container.guid === containerGuid) {
    let isMe = false;

    if (user === authUser.profile.username) {
      isMe = true;
    }
    return isMe ? children : null;
  }
  if (currentGroup.guid === containerGuid) {
    let isAdmin = false;
    var currentUser = currentGroup.members.filter((x) => {
      return x.user && x.user.id.toString() === authUser.profile.sub;
    });
    if (currentUser.length > 0) {
      isAdmin = currentUser[0].isAdmin && currentUser[0].accept ? true : false;
    }
    return isAdmin ? children : null;
  }

  return null;
};
