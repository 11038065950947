import React, { Fragment } from "react";
import { useField } from "formik";
import { FormGroup, Label, FormFeedback, CustomInput } from "reactstrap";
import {removeWhiteSpace} from '../utils/SyncUtils'

const SyncRadioButtonList = ({ label, options, children,
  form: { touched, errors, setFieldValue, values },
  ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const {showLabel = false, inline = true, inputprops } = props;
  const [field, meta] = useField({ ...props, ...inputprops, type: "radio" });
  let error = errors[field.name];
  
  const handleChange = (e) => {
  //  console.log(e.target.value)
    setFieldValue(field.name, e.target.value);
  }
  var inputs = options.map((x) => {
    let value = x;
    let label = x;
    if (x.label){
      label = x.label;
      value = x.value;
    }
    if (inline) {
      return (
        <Label check>
          <CustomInput value={value}  onChange={(e) => handleChange(e)} type="radio" label={label} inline={inline} {...inputprops} {...props} id={removeWhiteSpace(value)} />
        </Label>
      );
    } else {
      return (
        <FormGroup check>
        <Label check>
          <CustomInput value={value}  onChange={(e) => handleChange(e)} type="radio" label={label} inline={inline} {...inputprops} {...props} id={removeWhiteSpace(value)} />
        </Label>
        </FormGroup>
      )
    }
   
  });
 
  return (
    <div className="mb-3">
        {showLabel ? <><Label for={props.id || props.name} className={props.labelColor}>{label}</Label><br/></> : ""}
      {inline ? <FormGroup check inline={inline}>
      
        {inputs}
      </FormGroup> :  <Fragment>{inputs}</Fragment>}
      {/* {meta.touched && meta.error ? (
        <div className="error text-danger">{meta.error}</div>
      ) : null} */}
        {touched[field.name] && errors[field.name] && (
                <div className="error text-danger">&nbsp;{errors[field.name]}</div>
            )}
            {error && <FormFeedback>{error}</FormFeedback>}
    </div>
  );
};

export default SyncRadioButtonList;
