import React, {useState, useEffect} from 'react';
// import {FaArrowCircleUp} from 'react-icons/fa';
import './styles.css';


const ScrollTopArrow = (props) =>{

  const [showScroll, setShowScroll] = useState(false)

  useEffect(() => {
    checkScrollTop();
  }, [props.showScroll])

  const checkScrollTop = () => {
    if (props.showScroll) {
      setShowScroll(true)
    }
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }

    // console.log(window.pageYOffset, props.itemPosition, "Prospect List 10")
  };

  const scrollTop = () => {
    if (props.onScrollTop) {
      props.onScrollTop();
    } else {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
   
  };

  // useEffect(() => {
  //   window.addEventListener('scroll', checkScrollTop)
  // })

 

  return (
        // <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{height: 30, display: showScroll ? 'flex' : 'none'}}/>
        <i className="fa fa-chevron-circle-up fa-2x scrollTop" onClick={scrollTop} style={{height: 30, width: props.containerWidth && props.containerWidth > 0 ? props.containerWidth : "100%", display: showScroll ? 'flex' : 'none'}}></i>
  );
}

export default ScrollTopArrow