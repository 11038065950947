import React, { useEffect, useState, Suspense } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { ToastContainer, toast } from "react-toastify";
import { SecuredRoute } from "./auth/components";
import { UpdateServiceWorker, ErrorFallback } from "./components/UtilWidgets";
import { isEmpty, retry } from "./utils/SyncUtils";
import { ErrorBoundary } from "react-error-boundary";
// import './App.scss';

const DefaultLayout = React.lazy(() =>
  retry(() => import("./containers/TheLayout"))
);
const SettingsLayout = React.lazy(() =>
  retry(() => import("./containers/TheSettingsLayout"))
);
const GroupSettingsLayout = React.lazy(() =>
  retry(() => import("./containers/TheGroupSettingsLayout"))
);
const SignUp = React.lazy(() => retry(() => import("./auth/pages/signup")));
const CodeVerification = React.lazy(() =>
  retry(() => import("./auth/pages/signupcodeverification"))
);
const ActivationCodeVerification = React.lazy(() =>
  retry(() => import("./auth/pages/activationcodeverification"))
);
const Login = React.lazy(() => retry(() => import("./auth/components/Login")));
const Callback = React.lazy(() =>
  retry(() => import("./auth/components/Callback"))
);
const SilentRenew = React.lazy(() =>
  retry(() => import("./auth/components/SilentRenew"))
);
const Home = React.lazy(() => retry(() => import("./Home")));
const PushNotification = React.lazy(() =>
  retry(() => import("./admin/pages/pushnotificationpage"))
);

// Test Pages
const TestCodeVerification = React.lazy(() =>
  retry(() => import("./auth/pages/testcodeverification"))
);
const TestCodeVerificationCompleted = React.lazy(() =>
  retry(() => import("./auth/pages/testverificationcompleted"))
);

function AppRoutes(props) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const {
    serviceWorkerInitialized,
    serviceWorkerUpdated,
    serviceWorkerRegistration,
  } = useSelector((state) => state.serviceworker);
  const { container } = useSelector((state) => state.mycontainer);

  const [currentUpdatedState, setCurrentUpdatedState] =
    useState(serviceWorkerUpdated);
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  useEffect(() => {
    if (serviceWorkerUpdated) {
      if (serviceWorkerUpdated === true) {
        toast.info(
          <UpdateServiceWorker
            message="There is a new version available."
            onClick={updateServiceWorker}
          />,
          {
            position: isMobile ? "top-center" : "bottom-right",
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      // toast.info(<UpdateServiceWorker message="There is a new version available." onClick={updateServiceWorker} />, {
      //   position: "bottom-center",
      //   autoClose: 10000,
      //   hideProgressBar: true,
      //   closeOnClick: false,
      //   pauseOnHover: true,
      //   draggable: true,
      // })
    }
  }, [serviceWorkerUpdated]);

  const updateServiceWorker = () => {
    if (
      serviceWorkerRegistration !== null &&
      serviceWorkerRegistration !== undefined
    ) {
      const registrationWaiting = serviceWorkerRegistration.waiting;
      if (registrationWaiting) {
        registrationWaiting.postMessage({ type: "SKIP_WAITING" });
        registrationWaiting.addEventListener("statechange", (e) => {
          if (e.target.state === "activated") {
            window.location.reload();
          }
        });
      }
    }
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/dl">
          <Login />
        </Route>
        <Route path="/callback">
          <Callback />
        </Route>
        <Route path="/silent_callback">
          <SilentRenew />
        </Route>
        <Route path="/silent_renew.html">
          <SilentRenew />
        </Route>
        <Route path="/silent_renew">
          <SilentRenew />
        </Route>

        <Route
          exact
          path="/register"
          name="Register Page"
          render={(props) => <SignUp {...props} />}
        />
        <Route
          exact
          path="/signup"
          name="SignUp Page"
          render={(props) => <SignUp {...props} />}
        />
        <Route
          exact
          path="/verify-code"
          name="Code Verification"
          render={(props) => <CodeVerification {...props} />}
        />
         <Route
          exact
          path="/verify-activation-code"
          name="Activation Code Verification"
          render={(props) => <ActivationCodeVerification {...props} />}
        />
        <Route
          exact
          path="/pushmessage"
          name="Push Message Page"
          render={(props) => <PushNotification {...props} />}
        />
        <Route
          exact
          path="/push-message"
          name="Push Message Page"
          render={(props) => <PushNotification {...props} />}
        />
        {/* <Route exact path="/signupcompleted" name="SignUp Completed Page" render={props => <SignUpCompleted {...props} />} /> */}
        {/* Testing Routes - Remove after testing completed */}
        <Route
          exact
          path="/test-verify"
          name="Test Code Verification"
          render={(props) => <TestCodeVerification {...props} />}
        />
        <Route
          exact
          path="/test-verify-completed"
          name="Test Code Verification Completed"
          render={(props) => <TestCodeVerificationCompleted {...props} />}
        />

        <SecuredRoute path="/dashboard">
          <DefaultLayout {...props} />
        </SecuredRoute>
        <SecuredRoute path="/settings/group">
          <Suspense fallback={loading()}>
            <GroupSettingsLayout {...props} />
          </Suspense>
        </SecuredRoute>
        <SecuredRoute path="/settings">
          <Suspense fallback={loading()}>
            <SettingsLayout {...props} />
          </Suspense>
        </SecuredRoute>

       
        {/* <SecuredRoute path="/dashboard" name="Dashboard" render={props => <DefaultLayout {...props}/>} />  */}
        <Route path="/">
          <Suspense fallback={loading()}>
            <Home />
          </Suspense>
        </Route>
      </Switch>
    </ErrorBoundary>
  );
}

export default AppRoutes;
