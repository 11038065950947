import { createSlice } from '@reduxjs/toolkit'
import {array_move, isEmpty} from '../../utils/SyncUtils'



export const initialState = {
    groupUser: "",
    currentGroup: {prospects: [], members: [], harvestLocations: [], buckets: []},
    totalProspects: 0,
    currentGroupIndex: null,
    allGroups: [],
    currentGroupSoulWinOptions: {},
    currentGroupLabelDetails: [],
    currentGroupDashboardData: {
        rawData: [],
        totalProspects: 0,
        genderSpread: [],
        locations: [],
        dayOfWeek: [],
        attendance: 0,
        followUp: 0,
        abidingRate: 0,
        convertRate: 0,
        newProspectsDayOfWeek: [],
        followUpDayOfWeek: [],
        trend: [],
        trendPeriods: [],
        sentDate: null,
        attendanceRate: 0,
        followUpRate: 0,
        reportTable: []
    },
    currentGroupFormsDefinitions:{
        beginners: {},
        registration: {},
        membership: {},
        guests: {},
        outreach: {},
        houseFellowship: {},
       
    },
    currentGroupFormsData:{
        beginners:{data: [], headings: []},
        registration: {data: [], headings: []},
        membership: {data: [], headings: []},
        guests:{data: [], headings: []},
        outreach: {data: [], headings: []},
        houseFellowship: {data: [], headings: []}
    },
    currentGroupPermissions: [],
    currentGroupUserPermissions: [],
    currentGroupAttendances: []
};


const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {
        setGroups: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))
            if (state.currentGroupIndex !== null && state.currentGroupIndex !== undefined) {
                if (serializedPayload.length >= state.currentGroupIndex) {
                    serializedPayload[state.currentGroupIndex].members = [];
                    serializedPayload[state.currentGroupIndex].prospects = [];
                    serializedPayload[state.currentGroupIndex].harvestLocations = [];
                    serializedPayload[state.currentGroupIndex].buckets = [];
                }
            }
            return { ...state, allGroups: serializedPayload }
        },
        setCurrentGroup: (state, { payload }) => {
            let newPayload = {}
            Object.assign(newPayload, payload)
            let members = newPayload.members.slice().sort((a, b) => {
                var x = a.isAdmin
                var y = b.isAdmin
                return (x === y) ? 0 : x ? -1 : 1;
            })
            //array_move(members, 4, 0);
            
            let amMemberIndex = members.findIndex((element) => {
               // console.log(element.email, state.groupUser, "Charlesss")
               
                return element.email !== null && element.email !== undefined && element.email.toLowerCase() === state.groupUser.toLowerCase();
            })
            
            if (amMemberIndex > 0)
            {
                array_move(members, amMemberIndex, 0);
            }
            newPayload["members"] = members;
            
            
            let buckets = newPayload.buckets.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })
        
            let amDefaultBucket = buckets.findIndex((element) => {
                
                return element.guid.toLowerCase() === newPayload.settings.defaultBucketGuid.toLowerCase() || element.name.toLowerCase() === state.groupUser.toLowerCase();
            })

            if (amDefaultBucket > 0)
            {
                array_move(buckets, amDefaultBucket, 0);
            }
            newPayload["buckets"] = buckets;

            let harvestLocations = newPayload.harvestLocations.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })

            let amDefaultHarvestLocations = harvestLocations.findIndex((element) => {
                // console.log(element.guid, newPayload.settings.defaultHarvestLocationGuid)
                return element.guid.toLowerCase() === newPayload.settings.defaultHarvestLocationGuid.toLowerCase() || element.name.toLowerCase() === state.groupUser.toLowerCase();
            })
            if (amDefaultHarvestLocations > 0)
            {
                array_move(harvestLocations, amDefaultHarvestLocations, 0);
            }
            newPayload["harvestLocations"] = harvestLocations;
            // if (newPayload.members !== null)
            // {
            //     if (newPayload.members.length > 0)
            //     {
            //         newPayload.members.sort((a, b) => {
            //             var x = a.isAdmin
            //             var y = b.isAdmin
            //             return (x === y) ? 0 : x ? -1 : 1; 
            //         })
            //     }

            // }

            return { ...state, currentGroup: newPayload, totalProspects: newPayload.prospects.length  }
        },
        addGroupProspect: (state, { payload }) => {

            state.currentGroup.prospects.unshift(payload)
           // return { ...state }
        },
        updateOrAddGroupProspect: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                let amExisting = state.currentGroup.prospects.findIndex((element) => {
                    return element.guid.toLowerCase() === payload.guid.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.currentGroup.prospects[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    state.currentGroup.prospects.unshift(payload)
                }


            }
           
        },
        setCurrentGroupIndex: (state, { payload }) => {
            return { ...state, currentGroupIndex: payload }
        },
        updateGroupsItem: (state, { payload }) => {
            //console.log("UpdateGroup", state.allGroups);
            var serializedPayload = JSON.parse(JSON.stringify(payload.item))
            if (payload.index !== null && payload.index !== undefined) {
                if (!isEmpty(serializedPayload)) {
                    serializedPayload.members = [];
                    serializedPayload.prospects = [];
                    serializedPayload.harvestLocations = [];
                    serializedPayload.buckets = [];
                    state.allGroups[payload.index] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.
                }
            }
            
           // state.allGroups[payload.index] = payload.item; //This Update works because am using createReducer from Redux Toolkit and Immer.
            //return {...state}
        },
        setGroupUser: (state, { payload }) => {
            return { ...state, groupUser: payload }
        },
        setCurrentGroupSoulWinOptions: (state, { payload }) => {
            return { ...state, currentGroupSoulWinOptions: payload }
        },
        setCurrentGroupDashboardData: (state, { payload }) => {
            return { ...state, currentGroupDashboardData: payload }
        },
        setCurrentGroupLabelDetails: (state, { payload }) => {
            return { ...state, currentGroupLabelDetails: payload }
        },
        setCurrentGroupFormDefinition: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload.data))
            if (state.currentGroupFormsDefinitions !== null && state.currentGroupFormsDefinitions !== undefined){
                var formDefinitions = JSON.parse(JSON.stringify(state.currentGroupFormsDefinitions))
                if (state.currentGroupFormsDefinitions.hasOwnProperty(serializedPayload.key)){
                    // console.log("Has Key", payload)
                    formDefinitions[serializedPayload.key] = serializedPayload.value;
                    // console.log( formDefinitions[serializedPayload.key])
                    return { ...state, currentGroupFormsDefinitions: formDefinitions }
                } else {
                    formDefinitions[serializedPayload.key] = serializedPayload.value;
                    return { ...state, currentGroupFormsDefinitions: formDefinitions }
                }
            } else {

                var definitions = {
                    beginners: {},
                    registration: {},
                    membership: {},
                    outreach: {},
                    houseFellowship: {}
                };
                definitions[serializedPayload.key] = serializedPayload.value;
                return { ...state, currentGroupFormsDefinitions: definitions }
            }
           
           
        },
        resetCurrentGroupFormDefinition: (state, { payload }) => {
            var definitions = {
                beginners: {},
                registration: {},
                membership: {},
                outreach: {},
                houseFellowship: {}
            };
            return { ...state, currentGroupFormsDefinitions: definitions }
           
        },
        setCurrentGroupFormsData: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload.data))
            if (state.currentGroupFormsData !== null && state.currentGroupFormsData !== undefined){
                var formsDataInfo = JSON.parse(JSON.stringify(state.currentGroupFormsData))
                if (state.currentGroupFormsData.hasOwnProperty(serializedPayload.key)){
                    // console.log("Has Key", payload)
                    formsDataInfo[serializedPayload.key].data = serializedPayload.value;
                    formsDataInfo[serializedPayload.key].headings= serializedPayload.headings;
                    return { ...state, currentGroupFormsData: formsDataInfo }
                } else {
                    formsDataInfo[serializedPayload.key] = {data: [], headings: []}
                    formsDataInfo[serializedPayload.key].data = serializedPayload.value;
                    formsDataInfo[serializedPayload.key].headings= serializedPayload.headings;
                    return { ...state, currentGroupFormsData: formsDataInfo }
                }
            } else {

                var dataInfo = {
                    beginners:{data: [], headings: []},
                    registration: {data: [], headings: []},
                    membership: {data: [], headings: []},
                    outreach: {data: [], headings: []},
                    houseFellowship: {data: [], headings: []}
                };
                dataInfo[serializedPayload.key].data = serializedPayload.value;
                dataInfo[serializedPayload.key].headings = serializedPayload.headings;
                return { ...state, currentGroupFormsData: dataInfo }
            }
           
           
        },
        resetCurrentGroupFormsData: (state, { payload }) => {
            var dataInfo = {
                beginners:{data: [], headings: []},
                registration: {data: [], headings: []},
                membership: {data: [], headings: []},
                outreach: {data: [], headings: []},
                houseFellowship: {data: [], headings: []}
            };
            return { ...state, currentGroupFormsDefinitions: dataInfo }
           
        },
        updateCurrentGroupFormsData: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload.data))
            if (state.currentGroupFormsData !== null && state.currentGroupFormsData !== undefined){
                var formsDataInfo = JSON.parse(JSON.stringify(state.currentGroupFormsData))
                if (state.currentGroupFormsData.hasOwnProperty(serializedPayload.key)){
                    // console.log("Has Key", payload)
                    var existingData = JSON.parse(JSON.stringify(formsDataInfo[serializedPayload.key].data))
                    existingData.push(serializedPayload.value);
                    formsDataInfo[serializedPayload.key].data = existingData;
                    return { ...state, currentGroupFormsData: formsDataInfo }
                }
            }
        },
        setCurrentGroupPermissions: (state, { payload }) => {
            return { ...state, currentGroupPermissions: payload }
        },
        setCurrentGroupUserPermissions: (state, { payload }) => {
            return { ...state, currentGroupUserPermissions: payload }
        },
        setCurrentGroupAttendances: (state, { payload }) => {
            return { ...state, currentGroupAttendances: payload }
        },
        addAttendance: (state, { payload }) => {
            state.currentGroupAttendances.push(payload)
            //return { ...state, container: newPayload}
        },
        updateAttendance: (state, { payload }) => {
           // state.currentGroupAttendances.push(payload)
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                let amExisting = state.currentGroupAttendances.findIndex((element) => {
                    return element.guid.toLowerCase() === payload.guid.toLowerCase();
                })
                if (amExisting >= 0) {
                    // console.log(amExisting, "Updating Attendance index")
                    if (!isEmpty(serializedPayload)) {
                        state.currentGroupAttendances[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    // console.log("Attendance index not existing")
                    state.currentGroupAttendances.unshift(payload)
                }


            }
            //return { ...state, container: newPayload}
        },

    }

})

export const { setGroups, setCurrentGroup, setCurrentGroupIndex, addGroupProspect, updateOrAddGroupProspect, updateGroupsItem, 
    setGroupUser, setCurrentGroupSoulWinOptions, setCurrentGroupDashboardData, setCurrentGroupLabelDetails, 
    setCurrentGroupFormDefinition, resetCurrentGroupFormDefinition, setCurrentGroupFormsData, resetCurrentGroupFormsData, updateCurrentGroupFormsData,
setCurrentGroupPermissions, setCurrentGroupUserPermissions, setCurrentGroupAttendances, addAttendance, updateAttendance} = groupsSlice.actions;

// A selector
export const groupSelector = state => state.groups

// The reducer
export default groupsSlice.reducer