import { createSlice } from '@reduxjs/toolkit'
import {array_move, isEmpty} from '../../utils/SyncUtils'



export const initialState = {
    user: "",
    userProfile: {},
    userProfileComplete: {key: 0, value: {}},
    userNotifications: [],
    userUnreadNotifications: [],
    userSoulWinOptions: {},
    soulWinOptionTypes: {},
    platformOptions: {},
    container: {prospects : [], members: [], buckets: [], harvestLocations: []},
    totalProspects: 0,
    dashboardData: {
        rawData: [],
        totalProspects: 0,
        genderSpread: [],
        locations: [],
        dayOfWeek: [],
        attendance: 0,
        followUp: 0,
        abidingRate: 0,
        convertRate: 0,
        newProspectsDayOfWeek: [],
        followUpDayOfWeek: [],
        trend: [],
        trendPeriods: [],
        sentDate: null,
        attendanceRate: 0,
        followUpRate: 0,
        reportTable: []
    },
    formsDefinitions:{
        outreach: {},
        houseFellowship: {}
    }
};


const myContainerSlice = createSlice({
    name: 'mycontainer',
    initialState,
    reducers: {
        
        setContainer: (state, { payload }) => {
            let newPayload = {}
            Object.assign(newPayload, payload)
            let members = newPayload.members.slice().sort((a, b) => {
                var x = a.isAdmin
                var y = b.isAdmin
                return (x === y) ? 0 : x ? -1 : 1;
            })
            //array_move(members, 4, 0);
            let amMemberIndex = members.findIndex((element) => {
                return element.email !== null && element.email !== undefined && element.email.toLowerCase() === state.user.toLowerCase();
            })
            
            if (amMemberIndex > 0)
            {
                array_move(members, amMemberIndex, 0);
            }
            newPayload["members"] = members;
            
            // Sort by Created On
            let buckets = newPayload.buckets.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })
        
            let amDefaultBucket = buckets.findIndex((element) => {
                // return element.name.toLowerCase() === "default" || element.name.toLowerCase() === state.user.toLowerCase();
                return element.guid.toLowerCase() === newPayload.settings.defaultBucketGuid.toLowerCase() || element.name.toLowerCase() === state.user.toLowerCase();
            })

            if (amDefaultBucket > 0)
            {
                array_move(buckets, amDefaultBucket, 0);
            }
            newPayload["buckets"] = buckets;

            let harvestLocations = newPayload.harvestLocations.slice().sort((a, b) => {
                var x = a.createdOn
                var y = b.createdOn
                return (x === y) ? 0 : x ? -1 : 1;
            })

            let amDefaultHarvestLocations = harvestLocations.findIndex((element) => {
                // return element.status.toLowerCase() === "default" || element.name.toLowerCase() === state.user.toLowerCase();
                return element.guid.toLowerCase() === newPayload.settings.defaultHarvestLocationGuid.toLowerCase() || element.name.toLowerCase() === state.user.toLowerCase();
            })
            if (amDefaultHarvestLocations > 0)
            {
                array_move(harvestLocations, amDefaultHarvestLocations, 0);
            }
            newPayload["harvestLocations"] = harvestLocations;
            // if (newPayload.members !== null)
            // {
            //     if (newPayload.members.length > 0)
            //     {
            //         newPayload.members.sort((a, b) => {
            //             var x = a.isAdmin
            //             var y = b.isAdmin
            //             return (x === y) ? 0 : x ? -1 : 1; 
            //         })
            //     }

            // }

            return { ...state, container: newPayload, totalProspects: newPayload.prospects.length }
        },
        addProspect: (state, { payload }) => {
            state.container.prospects.unshift(payload)
            //return { ...state, container: newPayload}
        },
        updateOrAddProspect: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                let amExisting = state.container.prospects.findIndex((element) => {
                    return element.guid.toLowerCase() === payload.guid.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.container.prospects[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    state.container.prospects.unshift(payload)
                }


            }
           
        },
        setUser: (state, { payload }) => {
            return { ...state, user: payload }
        },

        setUserProfile: (state, { payload }) => {
            return { ...state, userProfile: payload }
        },
        setUserProfileComplete: (state, { payload }) => {
            return { ...state, userProfileComplete: payload }
        },
        setUserNotifications: (state, { payload }) => {
            return { ...state, userNotifications: payload }
        },
        setUserUnreadNotifications: (state, { payload }) => {
            return { ...state, userUnreadNotifications: payload }
        },
        addUserNotification: (state, { payload }) => {
            state.userNotifications.unshift(payload);
           // return { ...state, userNotifications: payload }
        },
        removeUserNotification: (state, { payload }) => {
            var newNotifications = state.userNotifications.filter(x => {
                return x.guid !== payload.guid;
            })
            return { ...state, userNotifications: newNotifications }
        },
        markUserNotificationAsRead: (state, { payload }) => {

            //var newNotifications = state.userNotifications.slice();

            // let notifIndex = state.userNotifications.findIndex((x) => {
            //     return x.guid !== payload.guid;
            // })
            // console.log(payload);
            // console.log(notifIndex);
            // if (notifIndex >= 0)
            // {
            //     state.userNotifications[notifIndex].notificationStatus = "Read"
            //     console.log("Modification Done");
            // }
            // console.log(state.userNotifications[notifIndex]);
            state.userNotifications.forEach(x => {
                if (x.guid === payload.guid && x.notificationStatus === "Unread")
                {
                    x.notificationStatus = "Read";
                   // break;
                }
            })
           // return { ...state}
        },
        markAllUserNotificationAsRead: (state, { payload }) => {

           // var newNotifications = state.userNotifications.slice();

            state.userNotifications.forEach(x => {
                if (x.notificationStatus === "Unread")
                {
                    x.notificationStatus = "Read";
                }
            })
           // return { ...state, userNotifications: newNotifs }
        },

        setUserSoulWinOptions:  (state, { payload }) => {
            return { ...state, userSoulWinOptions: payload }
        },
        setPlatformOptions:  (state, { payload }) => {
            return { ...state, platformOptions: payload }
        },
        setSoulWinOptionTypes:  (state, { payload }) => {
            return { ...state, soulWinOptionTypes: payload }
        },
        setDashboardData:  (state, { payload }) => {
            return { ...state, dashboardData: payload }
        },
        setFormDefinition: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload.data))
            if (state.formsDefinitions !== null && state.formsDefinitions !== undefined){
                var myFormDefinitions = JSON.parse(JSON.stringify(state.formsDefinitions))
                if (state.formsDefinitions.hasOwnProperty(serializedPayload.key)){
                    myFormDefinitions[serializedPayload.key] = serializedPayload.value;
                    return { ...state, formsDefinitions: myFormDefinitions }
                } else {
                    myFormDefinitions[serializedPayload.key] = serializedPayload.value;
                    return { ...state, formsDefinitions: myFormDefinitions }
                }
            } else {

                var definitions = {};
                definitions[serializedPayload.key] = serializedPayload.value;
                return { ...state, formsDefinitions: definitions }
            }
           
           
        },
        resetFormDefinition: (state, { payload }) => {
            var definitions = {
                outreach: {},
                houseFellowship: {}
            };
            return { ...state, formsDefinitions: definitions }
           
        }


    }

})

export const { setContainer, addProspect, updateOrAddProspect, setUserNotifications, addUserNotification, removeUserNotification, 
    markUserNotificationAsRead, markAllUserNotificationAsRead, setDashboardData, setFormDefinition, resetFormDefintion,
    setUser, setUserProfile, setUserProfileComplete, setPlatformOptions, setUserSoulWinOptions, setSoulWinOptionTypes } = myContainerSlice.actions;

// A selector
export const myContainerSelector = state => state.mycontainer

// The reducer
export default myContainerSlice.reducer