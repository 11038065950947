import React from 'react';
import { processSilentRenew } from 'redux-oidc';

const SilentRenewComponent = () => {
    console.log("Silent Call back")
    processSilentRenew();
    console.log('callback silent signin successfull');
    return (
        <div></div>
    );
}

export default SilentRenewComponent;