import * as React from 'react';
import { FormFeedback, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Button } from "reactstrap";
// import { Formik, Form, useField } from 'formik';
import {
    CSelect
} from '@coreui/react'

/*let handleBlur = event => {
    if (this.ignoreNextBlur === true) {
        // The parent components are relying on the bubbling of the event.
        event.stopPropagation();
        this.ignoreNextBlur = false;
        event.target.name = this.props.name;
        return;
    }
};*/

const SyncSelectInput = ({
    field,
    iconOrText, appendStyle = {}, appendClass = "",
    showPrepend = true, showAppend = false, disableAppend = false,
    appendIconOrText = '', onAppendClick,
    form: { isSubmitting, touched, errors, values },
    disabled = false,
    ...props
}) => {
    // const [field, meta] = useField(props);
    let error = errors[field.name];
    let touch = touched[field.name];
    let currentValue = values[field.name];

    const icon = iconOrText ?? ""
    const { showLabel = false, isRequired = false } = props;
    // console.log(props.inputprops.options, "Options")

    return (
        <div>
            <FormGroup>
                {showLabel ? <Label for={props.inputprops.id} className={props.labelColorClass}>{props.label}</Label> : ""}
                <InputGroup >
                    {/* <label htmlFor={props.id || props.name}>{label}</label> */}
                    {showPrepend &&  <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            {icon}
                        </InputGroupText>
                    </InputGroupAddon>}
                   
                    {/* <Input disabled={props.inputprops.disabled} id={props.inputprops.id} {...field} {...props} type="select"
                        invalid={Boolean(touched[field.name] && errors[field.name])}
                        placeholder="Test">
                        <option value="">{props.inputprops.defaultOption}</option>
                        {props.inputprops.options.map((option, index) => {
                            if (option.label)
                                return (<option value={option.value} key={index}>{option.label}</option>);
                            return (<option value={option} key={index}>{option}</option>)
                        })}
                    </Input> */}
                    <CSelect disabled={props.inputprops.disabled} id={props.inputprops.id} {...field} {...props}  
                    custom
                     invalid={Boolean(touched[field.name] && errors[field.name])} 
                    //  isvalid={(touched[field.name] && !errors[field.name])}
                     valid={(touched[field.name] && !errors[field.name])}
                     
                    // {...(touched[field.name]  && errors[field.name]
                    //     ? { isInvalid: true, className: "is-invalid" }
                    //     : props.isRequired ? { isValid: true, className: "is-valid"} : {isValid: true})}
                    >
                        {props.inputprops && props.inputprops.defaultOption &&  <option value="">{props.inputprops.defaultOption}</option>}
                     
                      {props.inputprops && props.inputprops.options && props.inputprops.options.map((option, index) => {
                            if (option.label){
                                if (option.label === props.inputprops.defaultOption){
                                    return (<option value={option.value} key={index} selected>{option.label}</option>);
                                }
                                return (<option value={option.value} key={index}>{option.label}</option>);
                            }
                            if (option === props.inputprops.defaultOption){
                                return (<option value={option} key={index} selected>{option}</option>)
                            }
                            return (<option value={option} key={index}>{option}</option>)
                        })}

                    </CSelect>
                    {showAppend && !disableAppend && <InputGroupAddon addonType="append"><Button className={appendClass} style={appendStyle} onClick={onAppendClick} >{appendIconOrText}</Button></InputGroupAddon>}
                    <label>{currentValue === "" && isRequired && <span className="text-danger">&nbsp;*</span>}</label>
                </InputGroup>
                {touched[field.name] && errors[field.name] && (
                <div className="error text-danger">&nbsp;{errors[field.name]}</div>
            )}
                {error && <FormFeedback>{error}</FormFeedback>}

                {/* {touch && error && <FormFeedback>{error}</FormFeedback>} */}

            </FormGroup>
            {/* {meta.touched && meta.error ? (
                <div className="error text-danger">&nbsp;{meta.error}</div>
            ) : null} */}
        </div>
    )
};

export default SyncSelectInput;