import { createUserManager } from 'redux-oidc';
import {WebStorageStateStore} from 'oidc-client'

const userManagerConfig = {
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  client_id:  process.env.REACT_APP_SOULWIN_CLIENT_ID,
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  post_logout_redirect_uri:  `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/?ref=logout`,
  response_type: 'code',
  scope: 'openid profile api1',
  authority: process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL,
  grantType: "authorization_code",   
  //silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew.html`,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
  //  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_callback`,
// silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/callback`,
  automaticSilentRenew: true,
  // automaticSilentRenew: true,
  filterProtocolClaims: true,
  revokeAccessTokenOnSignout: true,
  loadUserInfo: true,
  clockSkew: 3600,
  monitorSession: false,
  
};

//console.log(process.env.REACT_APP_SOULWIN_CLIENT_ID);
//console.log(process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL)
//console.log(process.env.NODE_ENV)
//console.log(userManagerConfig.post_logout_redirect_uri)
const userManager = createUserManager(userManagerConfig);

export default userManager;