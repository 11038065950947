/* eslint-disable no-unused-vars */
import React, {Fragment, useRef, useState, useEffect} from 'react'
import { Formik, useField, useFormikContext } from "formik";
import { FormFeedback, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {splitCamelCase} from '../utils/SyncUtils'
// import { useEffect } from 'react/cjs/react.production.min';


const SyncTypeaheadSelect = ({ name, options = [], multiple = false, labelKey, placeholder, feedback,  defaultSelectedOptions, allowNew = false, ...props }) => {
    const [field, meta, helper] = useField(name);
    const { error, touched } = meta;
    const typeaheadRef = useRef(null);
    const [reset, setReset] = useState(() => {
      if (props.reset){
        return props.reset;
      }
      return false;
    })

    useEffect(() => {
      if (props.reset === true){
        typeaheadRef.current.clear()
      }
    }, [props.reset])

    // useEffect(() => {

    //   // console.log(props, name,  "TypeHead", allowNew, options)

    // }, [])

    return (
      <Fragment>
        <FormGroup controlId={`form-${name}`}>
          {props.showLabel && <Label>{props.label}</Label>}
          <Typeahead
           clearButton
           ref={typeaheadRef}
            id={name}
            multiple={multiple}
            onChange={(selected) => {
             // console.log(selected)
             if (!multiple){
              const value = selected.length > 0 ? selected[0].label ? selected[0].label : selected[0] : "";
              helper.setValue(value);
             } else {
              // const value = selected.length > 0 ? selected[0] : "";
              helper.setValue(selected.map(x => {
                if (x.label){
                  return x.label
                }
                return x;
              }));
              if (!allowNew){
                typeaheadRef.current.toggleMenu();
              }
             
             }
            
            }}
            onInputChange={(text) => helper.setValue(text)}
            placeholder={placeholder}
            onBlur={() => helper.setTouched(true)}
            allowNew={allowNew}
            labelKey={labelKey}
            newSelectionPrefix={`Add a new ${splitCamelCase(name)} : `}
            options={options}
            {...(meta.touched && meta.error
              ? { isInvalid: true, className: "is-invalid" }
              : meta.touched ? { isValid: true} : {})}
            {...props}
          />
          {meta.touched && meta.error ? (
            <div className="invalid-feedback">{meta.error}</div>
          ) : null}
        </FormGroup>
        {/* <pre>{JSON.stringify(meta, null, 2)}</pre>
        <pre>{JSON.stringify(field, null, 2)}</pre> */}
      </Fragment>
    );
  };


  export default SyncTypeaheadSelect