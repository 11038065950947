import React from "react";
import {
  SyncSwitch,
  SyncCheckBox,
  SyncCheckBoxList,
  SyncRadioButtonList,
  SyncTextInput,
  SyncSelectInput,
  SyncReactMultiSelectInput,
  SyncReactDatesSinglePicker,
  SyncTypeaheadSelect,
  SyncSelectSearch,
  SyncPhoneInput2
} from "./";
import CIcon from "@coreui/icons-react";
import moment from "moment";
import { Field } from "formik";
import { useMediaQuery } from "react-responsive";
import { getAcronym } from "../utils/SyncUtils";
import {DayMonthDateField} from '@carlospence/synccomponents-test'
import '@carlospence/synccomponents-test/dist/esm/index.css'


function DynamicInput({
  value,
  onChange,
  htmlType,
  type,
  maxWidth = 450,
  readonly = false,
  disabled = false,
  ...props
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // console.log(props.name, "DynamicInput")
  switch (htmlType) {
    case "text":
      return (
        <SyncTextInput
          label={props?.label}
          // iconOrText={<i className="fa fa-sticky-note"></i>}
          disabled={disabled}
          readonly={readonly}
          iconOrText={getAcronym(props?.name)}
          name={props?.name}
          type="text"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "number":
      return (
        <SyncTextInput
          label={props?.label}
          disabled={disabled}
          readonly={readonly}
          iconOrText={<i className="fa fa-hashtag"></i>}
          name={props?.name}
          type="number"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "tel":
      return (
        <Field
          label={props?.label}
          placeholder={props?.placeholder}
          isRequired={props?.required}
          disabled={disabled}
          readonly={readonly}
          country="ng"
          preferredCountries={["ng", "gh", "us"]}
          name={props?.name}
          component={SyncPhoneInput2}
          showLabel={true}
          style={{ maxWidth: maxWidth + 50 }}
          inputprops={{
            name: props?.name,
            id: props?.name,
            required: props?.required,
            // defaultOption: "None"
          }}
        />
      );
    case "email":
      return (
        <SyncTextInput
          label={props?.label}
          iconOrText={<i className="fa fa-at"></i>}
          // iconOrText="@"
          disabled={disabled}
          readonly={readonly}
          name={props?.name}
          type="email"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "textarea":
      return (
        <SyncTextInput
          label={props?.label}
          iconOrText={<i className="fa fa-sticky-note"></i>}
          disabled={disabled}
          readonly={readonly}
          name={props?.name}
          type="textarea"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "dropdown":
      return (
        <Field
          name={props?.name}
          label={props?.label}
          showLabel={true}
          // iconOrText={<i className="fa fa-handshake"></i>}
          iconOrText={getAcronym(props?.name)}
          component={SyncSelectInput}
          isRequired={props?.required}
          style={{ maxWidth: maxWidth }}
          required={props?.required}
          inputprops={{
            name: props?.name,
            id: props?.name,
            options: props.optionLabels ? props?.optionLabels : props?.options,
            placeholder: props?.placeholder,
            defaultOption: props?.defaultValue,
            isRequired: props?.required,
            disabled: disabled,
            readonly: readonly
          }}
          //   onChange={(value) => {
          //     onChange(value);
          //   }}
        ></Field>
      );
    case "checkbox":
      if ((Array.isArray(props?.options) && props?.options.length === 0) || !Array.isArray(props?.options)) {
        if (props.useSwitch){
          return (
            <SyncSwitch
              name={props?.name}
              disabled={disabled}
              readonly={readonly}
              id={props?.name}
              label={props?.label}
              {...props}
            >
              {props?.label}
            </SyncSwitch>
          );
        }
        return (
          <SyncCheckBox
            name={props?.name}
            id={props?.name}
            label={props?.label}
            disabled={disabled}
            readonly={readonly}
          >
            {props?.label}
          </SyncCheckBox>
        );
      }
      if (Array.isArray(props?.options) && props?.options.length > 0) {
        return (
          <Field
            name={props?.name}
            label={props?.label}
            showLabel={true}
            disabled={disabled}
            readonly={readonly}
            component={SyncCheckBoxList}
            isRequired={props?.required}
            inline={
              isMobile ? (props?.options.length > 3 ? false : true) : true
            }
            // required={props?.required}
            options={props.optionLabels ? props?.optionLabels : props?.options}
            id={props?.name}
            inputprops={{
              name: props?.name,
              id: props?.name,
              options: props.optionLabels ? props?.optionLabels : props?.options,
              placeholder: props?.placeholder,
              defaultOption: props?.defaultValue,
              isRequired: props?.required,
              disabled: disabled,
              readonly: readonly
            }}
            //   onChange={(value) => {
            //     onChange(value);
            //   }}
          ></Field>
          //   <SyncCheckBoxList
          //     showLabel={true}
          //     options={props?.options}
          //     name={props?.name}
          //     id={props?.name}
          //     label={props?.label}
          //   >
          //     {props?.label}
          //   </SyncCheckBoxList>
        );
      }
      // if (Array.isArray(props?.options) && props?.options.length > 0){
      //     return props?.options.map(u => {
      //         return <SyncCheckBox inline name={props?.name} id={props?.name} label={u}>
      //         {u}
      //      </SyncCheckBox>
      //     })
      // }
      return <> </>;
    case "radio":
      if (Array.isArray(props?.options) && props?.options.length > 0) {
        return (
          <Field
            name={props?.name}
            label={props?.label}
            showLabel={true}
            component={SyncRadioButtonList}
            disabled={disabled}
            readonly={readonly}
            // required={props?.required}
            inline={
              isMobile ? (props?.options.length > 3 ? false : true) : true
            }
            isRequired={props?.required}
            options={props.optionLabels ? props?.optionLabels : props?.options}
            id={props?.name}
            inputprops={{
              name: props?.name,
              id: props?.name,
              options: props.optionLabels ? props?.optionLabels : props?.options,
              placeholder: props?.placeholder,
              defaultOption: props?.defaultValue,
              isRequired: props?.required,
              disabled: disabled,
              readonly: readonly
            }}
            //   onChange={(value) => {
            //     onChange(value);
            //   }}
          ></Field>
          //   <SyncRadioButtonList
          //     showLabel={true}
          //     options={props?.options}
          //     name={props?.name}
          //     id={props?.name}
          //     label={props?.label}
          //   >
          //     {props?.label}
          //   </SyncRadioButtonList>
        );
      }
      return <> </>;
    case "multiselect":
      if (isMobile) {
        return (
          <Field
            name={props?.name}
            label={props?.label}
            showLabel={true}
            disabled={disabled}
            readonly={readonly}
            multiple
            iconOrText={<i className="fa fa-handshake"></i>}
            component={SyncSelectInput}
            isRequired={props?.required}
            // required={props?.required}
            inputprops={{
              name: props?.name,
              id: props?.name,
              options: props.optionLabels ? props?.optionLabels : props?.options,
              placeholder: props?.placeholder,
              defaultOption: props?.defaultValue,
              isRequired: props?.required,
              disabled: disabled,
              readonly: readonly
            }}
            // onChange={(value) => {
            //   onChange(value);
            // }}
          ></Field>
        );
      } else {
        return (
          <Field
            name={props?.name}
            iconOrText={<i className="fa fa-sort-numeric-asc"></i>}
            component={SyncReactMultiSelectInput}
            options={props.optionLabels ? props?.optionLabels : props?.options.map((x) => {
              return { value: x, label: x };
            })}
            placeholder={props?.placeholder}
            maxWidth={maxWidth}
            showLabel={true}
            label={props?.label}
            hasSelectAll={false}
            selectValueOnly={true}
            // required={props?.required}
            //onSelectChange={handleSelectChange}
            isRequired={props?.required}
            disableSearch={
              isMobile ? true : props?.options.length > 20 ? false : true
            }
            onMenuToggle={(expanded) => {
              //handleMultiSelectToggle(expanded, "services-select")
            }}
          />
        );
      }
    case "date":
    case "datetime":
      return (
        <SyncReactDatesSinglePicker
          name={props?.name}
          label={props?.label}
          isRequired={props?.required}
          placeholder={props?.placeholder}
          placeholderText={props?.placeholder}
          iconOrText={<i className="fa fa-calendar-day"></i>}
          enableOutsideDays={true}
          showLabel={true}
          maxWidth={maxWidth}
          // isOutsideRange={() => false}
          isOutsideRange={(date) =>
            date.isBefore(moment().subtract(180, "days")) ||
            date.isAfter(moment().add(0, "days"))
          }
          small={true}
          customInputIcon={<CIcon name="cil-calendar" className="mfe-2" />}
          inputIconPosition="after"
          disabled={disabled}
          readonly={readonly}
        ></SyncReactDatesSinglePicker>
      );
      case "monthday":
      return (
        <DayMonthDateField
          // className="mb-2"
          name={props?.name}
          label={props?.label}
          isRequired={props?.required}
          placeholder={props?.placeholder}
          placeholderText={props?.placeholder}
          leading={<i className="fa fa-calendar-day mx-1"></i>}
          style={{ maxWidth: maxWidth }}
          disabled={disabled}
          readonly={readonly}
        ></DayMonthDateField>
      );
    case "autocomplete":
      // console.log((props.defaultValue))
      //console.log(JSON.stringify(props))
      var theOptions = props.optionLabels ? props?.optionLabels.map((x) => {
        return { label: x.label, id: x.value };
      }) ?? [] :
        props?.options.map((x) => {
          return { label: x, id: x };
        }) ?? [];


      return (
        <SyncTypeaheadSelect
          {...props}
          name={props?.name}
          label={props?.label}
          isRequired={props?.required}
          placeholder={props?.placeholder}
          placeholderText={props?.placeholder}
          iconOrText={<i className="fa fa-calendar-day"></i>}
          showLabel={true}
          options={theOptions}
          labelKey={"label"}
          maxWidth={maxWidth}
          small={true}
          multiple={props?.multipleSelection}
          defaultSelected={props.defaultValue ?? []}
          customInputIcon={<CIcon name="cil-calendar" className="mfe-2" />}
          disabled={disabled}
          readonly={readonly}
        ></SyncTypeaheadSelect>
      );
    case "autocompletes":
      var theOptions2 =
        props?.options.map((x) => {
          return { label: x, id: x };
        }) ?? [];
      return (
        <Field
          name={props?.name}
          label={props?.label}
          showLabel={true}
          component={SyncSelectSearch}
          isRequired={props?.required}
          style={{ maxWidth: maxWidth }}
          required={props?.required}
          placeholder={props?.placeholder}
          placeholderText={props?.placeholder}
          options={theOptions2}
          labelKey={"label"}
          multiple={props?.multipleSelection}
          inputprops={{
            name: props?.name,
            id: props?.name,
            charles: props?.name,
            options: theOptions2,
            placeholder: props?.placeholder,
            defaultOption: props?.defaultValue,
            isRequired: props?.required,
            multiple: props?.multipleSelection,
            allowNew: props?.allowNew,
          }}
          //   onChange={(value) => {
          //     onChange(value);
          //   }}
        ></Field>
        // <SyncSelectSearch
        // {...props}
        //   name={props?.name}
        //   label={props?.label}
        //   isRequired={props?.required}
        //   placeholder={props?.placeholder}
        //   placeholderText={props?.placeholder}
        //   iconOrText={<i className="fa fa-calendar-day"></i>}
        //   showLabel={true}
        //   options={theOptions}
        //   labelKey={"label"}
        //   maxWidth={maxWidth}
        //   small={true}
        //   multiple={props?.multipleSelection}
        //   customInputIcon={<CIcon name="cil-calendar" className="mfe-2" />}

        // ></SyncSelectSearch>
      );

    default:
      return <div>{`Unknown Type ${htmlType}`}</div>;
  }
}

export default DynamicInput;
