import React, { useCallback, useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Badge } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import overlayFactory from "react-bootstrap-table2-overlay";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import { EmptyRowsView } from "../components/UtilWidgets";
import { useMediaQuery } from "react-responsive";
import { CButton, CSelect } from "@coreui/react";
import CIcon from "@coreui/icons-react";
import { utils, writeFile } from "xlsx";
import { downloadDataFile, isEmpty, isNumber } from "../utils/SyncUtils";
import { getDataSummation, formatDateColumn } from "../components";
import { SimpleTable } from "@carlospence/react-simple-table2";
import jsPDF from "jspdf";
import autoTable, { applyPlugin } from "jspdf-autotable";
import ReactPaginate from "@carlospence/react-paginate";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BootstrapTableCustomSearchBar, {BootstrapTableMiniCustomSearchBar} from './BootstrapTableCustomSearchBar'
import moment from "moment";
applyPlugin(jsPDF);

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  return isMobile ? children : null;
};
const NotMobile = ({ children }) => {
  const isNotMobile = useMediaQuery({ minWidth: 768 });
  return isNotMobile ? children : null;
};


function DynamicFormTable({
  columns,
  mobileColumns,
  data,
  name,
  isLoading = false,
  footerClasses,
  onShare,
  onExportPdf,
  onExportExcel,
  onExportCsv,
  useCalendarView,
  useSingleDisplay,
  selectColumn = "CreatedOn",
  ...props
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const singlePerPage = 1;
  const [isCalendarView, setIsCalendarView] = useState(useCalendarView);
  const [isSingleDisplay, setIsSingleDisplay] = useState(useSingleDisplay);
  const [isTableDisplay, setIsTableDisplay] = useState(() => {
    if (useCalendarView) return false;
    return !isMobile ? true : false;
  });
  const [isPivotDisplay, setIsPivotDisplay] = useState(() => {
    if (useCalendarView) return false;
    return isMobile ? true : false;
  });

  const [singleData, setSingleData] = useState(() => {
    if (data && Array.isArray(data) && data.length > 0) {
      // console.log(data, "Current Data 2")
      var newData =  data.slice(0, 1);
      return newData;
    }
    return [];
  });

  const [customPageViewData, setCustomPageViewData] = useState(() => {
    if (data && Array.isArray(data) && data.length > 0) {
     var result = data.map((x, index) => {
      if (x[selectColumn] !== null && x[selectColumn] !== undefined){
        if (!isNumber(x[selectColumn])) {
          if (moment(x[selectColumn]).isValid()) {
           // return moment(data).format("ll");
            return {label:  moment(x[selectColumn]).format("ll"), value: index}
          }
        }
        return {label: x[selectColumn], value: index}
      }
      return {label: index + 1, value: index}
     })
     return result;
    }
    return []
  })

  const [currentPage, setCurrentPage] = useState(0);
  // const [offset, setOffset] = useState(0);
  // const isPivotDisplay = useState(isMobile ? true : false)
  useEffect(() => {
    // console.log(useSingleDisplay, "UseSingleDisplay")
    setIsSingleDisplay(useSingleDisplay)
  }, [useSingleDisplay]);

  useEffect(() => {
    // console.log(isTableDisplay, isPivotDisplay, isMobile, !isMobile)
    if (!isSingleDisplay) {
      var tableView = !isMobile ? true : false;
      var pivotView = isMobile ? true : false;
      setIsTableDisplay(tableView);
      setIsPivotDisplay(pivotView);
    } else {
      setIsTableDisplay(false);
      setIsPivotDisplay(false);
    }
  }, [isMobile, isSingleDisplay]);

  

  const paginationOptions = {
    hideSizePerPage: isMobile ? true : false,
    sizePerPage: 10,
    sizePerPageList: [
      {
        text: "5",
        value: 5,
      },
      {
        text: "10",
        value: 10,
      },
      {
        text: "25",
        value: 25,
      },
      {
        text: "50",
        value: 50,
      },
      {
        text: "100",
        value: 100,
      },
      {
        text: "All",
        value: data.length,
      },
    ],
  };

  // const handleExportExcel = () => {

  // }

  const handleExportPdf = () => {
    if (Array.isArray(data) && data.length > 0) {
      var firstObject = data[0];
      var hiddenColumns = ["Id", "CreatedOn", "PostedBy"];
      let headers = Object.keys(firstObject);
      var exportColumns = [];
      var allHeaders = headers
        .map((x) => {
          var hide = hiddenColumns.filter((u) => u === x).length > 0;
          if (hide) {
            return {};
          }
          return { header: x, dataKey: x };
        })
        .filter((v) => !isEmpty(v));
      exportColumns.push(headers);
      var formattedData = formatDateColumn(data);
      var footerData = getDataSummation(data);
      formattedData.push(footerData);
      const bodyData = [];
      bodyData.push(bodyData);
      var neededData = data.map((x) => {
        return Object.values(x);
      });
      const doc = new jsPDF("landscape");
      doc.setFontSize(16);
      doc.text(`${name} Information`, 14, 22);

      doc.setFontSize(11);
      var totalPagesExp = "{total_pages_count_string}";
      autoTable(doc, {
        //head: exportColumns,
        columns: allHeaders,
        body: formattedData,
        startY: 30,
        didParseCell: function (hookData) {
          console.log(hookData, "Cell");
          // console.log(celldata, "Celldata")
          if (hookData.row.index === formattedData.length - 1) {
            hookData.cell.styles.fontStyle = "bold";
          }
        },
        didDrawPage: function (thedata) {
          // Footer
          var str = "Page " + doc.getCurrentPageInfo().pageNumber;
          // Total page number plugin only available in jspdf v1.0+
          if (typeof doc.putTotalPages === "function") {
            str = str + " of " + totalPagesExp;
          }
          doc.setFontSize(10);

          // jsPDF 1.4+ uses getHeight, <1.4 uses .height
          var pageSize = doc.internal.pageSize;
          var pageHeight = pageSize.height
            ? pageSize.height
            : pageSize.getHeight();
          doc.text(str, thedata.settings.margin.left, pageHeight - 10);
        },
        theme: "grid",
        //  columnStyles: { 0: { halign: 'center', fillColor: [0, 255, 0] } }, // Cells in first column centered and green
        margin: { top: 10 },
        //  styles: { fillColor: [255, 0, 0] },
      });
      if (typeof doc.putTotalPages === "function") {
        doc.putTotalPages(totalPagesExp);
      }
      let finalY = doc.lastAutoTable.finalY;
      //doc.text(text, 14, finalY + 10)
      doc.save(`${name}_export_${Date.now()}.pdf`);
    }
  };

  const handleExportCsv = useCallback(() => {
    /* generate worksheet from state */
    const ws = utils.json_to_sheet(data);
    /* export to Csv */
    var csv = utils.sheet_to_csv(ws);
    // download_file(csv, `${name}_export_${Date.now()}.csv`, 'text/csv;encoding:utf-8');
    downloadDataFile({
      data: csv,
      fileName: `${name}_export_${Date.now()}.csv`,
      fileType: "text/csv;encoding:utf-8",
    });
  }, [data]);

  const handleShareSummary = () => {
    alert("Coming soon");
  };

  const handleExportExcel = useCallback(() => {
    /* generate worksheet from state */
    var fullData = data.slice();
    var footerData = getDataSummation(data);
    fullData.push(footerData);
    const ws = utils.json_to_sheet(fullData);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, name);
    /* export to XLSX */
    writeFile(wb, `${name}_export_${Date.now()}.xlsx`);
  }, [data]);

  const handlePageClick = (pageInfo) => {
    // console.log('onPageChange', pageInfo);
    let selected = pageInfo.selected;
    let offset = Math.ceil(selected * singlePerPage);
    // setOffset(offset);
    setCurrentPage(Math.round(offset / singlePerPage))
    if (data && Array.isArray(data) && data.length > 0) {
      //return data.slice(0, 1);
      // console.log(data, "Current Data 1")
       //console.log(`Page Selected ${selected} End  ${selected + 1}`)
      var newData = data.slice(selected,  selected + 1)
      // console.log(newData, "New Data PageChange");
      setSingleData(newData)
    }
  };

  const handleOptionsChange = (pageInfo) => {
   //  console.log('onOptionsChange', pageInfo.target.value);
   //  console.log('onOptionsChange 2', pageInfo.currentTarget.value);
    let selected = parseInt(pageInfo.target.value, 10);
    let offset = Math.ceil(selected * singlePerPage);
    // setOffset(offset);
    setCurrentPage(Math.round(offset / singlePerPage))
    if (data && Array.isArray(data) && data.length > 0) {
      //return data.slice(0, 1);
      // console.log(data, "Current Data 2")
    //  console.log(`Options Selected ${selected} End  ${selected + 1}`)
      var newData = data.slice(selected, selected + 1)
      // console.log(newData, "New Data OptionsChange");
      setSingleData(newData)
    }
  };

  const ReportTableCaption = (props) => (
    <div className="row mx-1">
      {/* <div className="col-12 report-table-caption">Activity Report for {getPeriod(true)}</div> */}
      {/* <div className="col-12 report-table-caption">Total {getPeriod(true)}</div> */}
      <div className="col-6 dynamic-table-caption">
        <span className="pageTitle">
          Total -{" "}
          <Badge pill color="success">
            {data.length}
          </Badge>
        </span>
      </div>

      <div className="col-6">
        <CButton
          size="sm"
          onClick={handleShareSummary}
          color="primary"
          className="mr-2 float-right"
        >
          {/* <CIcon name="cil-share-alt" /> {' '} {!isMobile && `Share`} */}
          <CIcon className={!isMobile && `mr-2`} name="cil-share-alt" />
          <span style={{ verticalAlign: "middle" }}>
            {" "}
            {!isMobile && `Share`}{" "}
          </span>
          {/* <span ><i className="fa fa-share-alt mx-2"></i>{' '} {!isMobile && `Share`} </span> */}
        </CButton>
        <CButton
          size="sm"
          onClick={handleExportPdf}
          color="danger"
          className="mr-2 float-right"
        >
          {/* <CIcon name="cil-share-alt" /> {' '} {!isMobile && `Share`} */}
          {/* <CIcon className={!isMobile && `mr-2`} name="cil-cloud-download" /><span style={{ verticalAlign: "middle" }} >{' '} {!isMobile && `Export Csv`} </span> */}
          <span>
            <i className={`fa fa-file-pdf fa-1-4x ${!isMobile && `mr-2`}`}></i>
            <span style={{ verticalAlign: "text-bottom" }}>
              {" "}
              {!isMobile && `Export PDF`}{" "}
            </span>{" "}
          </span>
        </CButton>
        <CButton
          size="sm"
          onClick={handleExportCsv}
          color="info"
          className="mr-2 float-right"
        >
          {/* <CIcon name="cil-share-alt" /> {' '} {!isMobile && `Share`} */}
          {/* <CIcon className={!isMobile && `mr-2`} name="cil-cloud-download" /><span style={{ verticalAlign: "middle" }} >{' '} {!isMobile && `Export Csv`} </span> */}
          <span>
            <i className={`fa fa-file-csv fa-1-4x ${!isMobile && `mr-2`}`}></i>
            <span style={{ verticalAlign: "text-bottom" }}>
              {" "}
              {!isMobile && `Export Csv`}{" "}
            </span>{" "}
          </span>
        </CButton>
        <CButton
          size="sm"
          onClick={handleExportExcel}
          color="success"
          className="mr-2 float-right"
        >
          {/* <CIcon name="cil-share-alt" /> {' '} {!isMobile && `Share`} */}
          <span>
            <i
              className={`fa fa-file-excel fa-1-4x ${!isMobile && `mr-2`}`}
            ></i>
            <span style={{ verticalAlign: "text-bottom" }}>
              {" "}
              {!isMobile && `Export Excel`}{" "}
            </span>{" "}
          </span>
          {/* <span ><i className="fa fa-share-alt mx-2"></i>{' '} {!isMobile && `Share`} </span> */}
        </CButton>
      </div>
    </div>
  );

  return (
    <div className="dynamic-form-table">
      {isTableDisplay && (
        <ToolkitProvider
          bootstrap4={true}
          keyField="Id"
          data={data}
          columns={columns}
          search
          // exportCSV={{
          //     fileName: `Activities for ${getPeriod()}.csv`,
          //     ignoreFooter: false
          // }}
        >
          {(props) => (
            <React.Fragment>
               <BootstrapTableMiniCustomSearchBar { ...props.searchProps } />
              <BootstrapTable
                bordered={false}
                wrapperClasses="table-responsive"
                headerWrapperClasses="soulwin-table-header"
                rowClasses="dynamic-form-table-row"
                caption={<ReportTableCaption />}
                // caption={<DynamicTableCaption caption={<span className="pageTitle">Total - <Badge pill color="success">{data.length}</Badge></span>} />}
                loading={isLoading}
                pagination={paginationFactory(paginationOptions)}
                noDataIndication={() => <EmptyRowsView />}
                // overlay={ overlayFactory() }
                footerClasses={
                  data && data.length > 0 ? `${footerClasses}` : ``
                }
                overlay={overlayFactory({
                  spinner: true,
                  styles: {
                    overlay: (base) => ({
                      ...base,
                      background: "rgba(0, 0, 0, 0.5)",
                    }),
                  },
                })}
                {...props.baseProps}
              />
            </React.Fragment>
          )}
        </ToolkitProvider>
      )}

      {isPivotDisplay && (
        <React.Fragment>
          <ReportTableCaption />
          <SimpleTable
            data={data}
            dataKeyFn={(item) => item?.Id || "empty"}
            cols={mobileColumns}
            useCards={true}
            // loading={true}
            breakpoint={"xxl"}
          />
        </React.Fragment>
      )}

      {isSingleDisplay && (
        <React.Fragment>
          <ReportTableCaption />
          <div className="mt-3">
            <ReactPaginate
              containerClassName="pagination d-flex justify-content-between mb-0"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              pageClassName="page-item"
              previousClassName="page-item"
              nextClassName="page-item"
              pageLinkClassName="page-link"
              previousLinkClassName="page-link"
              nextLinkClassName="page-link"
              activeClassName="active"
              onPageChange={handlePageClick}
              forcePage={currentPage}
              // renderCustomPageView={
              //   <select
              //     className="mx-3"
              //     onChange={handleOptionsChange}
              //     value={currentPage}
              //   >
              //     <option value={0}>Select Page</option>
              //     {[...Array(data.length)].map((x, index) => (
              //       <option key={index} value={index}>
              //         {index + 1}
              //       </option>
              //     ))}
              //   </select>
              // }
              renderCustomPageView={ <CSelect style={{width: 150, border: 'none', fontWeight: 'bolder', fontSize:'medium'}} value={currentPage} onChange={handleOptionsChange} name="customPageView" id="customPageView">
              {data.length <= 0 && <option value={0}>Select..</option>}  
              {
                  customPageViewData.map((x, index) => (
                      <option  className="bold-option" key={x.value} value={x.value}>{x.label}</option>
                  ))

              }

          </CSelect>}
              nextLabel= { <FontAwesomeIcon size="lg" icon="chevron-right"  ></FontAwesomeIcon>}
              previousLabel= { <FontAwesomeIcon size="lg" icon="chevron-left"  ></FontAwesomeIcon>}
              pageCount={data.length}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
            />
          </div>

          <SimpleTable
            data={singleData}
            dataKeyFn={(item) => item?.Id || "empty"}
            cols={mobileColumns}
            useCards={true}
            // loading={true}
            breakpoint={"xxl"}
          />
        </React.Fragment>
      )}
    </div>
  );
}

DynamicFormTable.propTypes = {
  columns: PropTypes.array.isRequired,
  mobileColumns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  footerClasses: PropTypes.string,
  useCalendarView: PropTypes.bool,
};

DynamicFormTable.defaultProps = {
  isLoading: false,
  name: "dynamic-form",
  footerClasses: "dynamic-footer-class",
  useCalendarView: false,
};

export default DynamicFormTable;
