import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import {  useDispatch } from "react-redux";
import * as Yup from 'yup';
import { Progress, Collapse, Button, Card, CardBody, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row, CardHeader } from 'reactstrap';
import { SyncSelectInput, SyncTextInput } from '../../components'
import { soulWinOptions } from '../../utils/options'
//import logo from '../../assets/img/brand/logo.png'
import {useHistory } from 'react-router-dom';
import { updateUserProfile} from '../../utils/ApiUtils'
import { isNullOrEmpty, toPascalCase } from '../../utils/SyncUtils'
import { LoadingAlertConfig, LoadingSpinner } from '../../components/UtilWidgets'
//import { setUserProfile, setUserProfileComplete } from '../../app/reducers/MyContainerReducer'
import { toast } from 'react-toastify';
import classNames from 'classnames'
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import '../../components/styles.css'
//import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/mobile'
import { usePlatformOnline, offlineMessages } from '../../hooks/SyncHooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


const UserProfileUpdateForm = (props) => {
    const history = useHistory();
    // const dispatch = useDispatch();
    // const [phoneNumber, setValue] = useState()
    const [successMessage, setSuccessMessage] = useState(false);
    const [collapse, setCollapse] = useState("")
    const [progressState, setProgressState] = useState(props.profileStatus.key)
    const [completeStatus, setCompleteState] = useState(() => {
        var newScores = {};
        var keys = Object.keys(props.profileStatus.value);
        keys.map(x => {
            newScores[x] = {value : props.profileStatus.value[x], completed : false}
        })
        return newScores;
    })
    const [isOnline, offlineMessage] = usePlatformOnline()
    const closeBtn = <div></div>;


    const toggleCollapse = (field) => {
        if (field == collapse) {
            setCollapse("");
        }
        else {
            setCollapse(field);
        }

    }

    const toggleSuccessMessage = () => {
        setSuccessMessage(!successMessage);
        // this.setState({
        //   large: !this.state.large,
        // });
    }

    const handleFormSuccess = () => {
        setSuccessMessage(!successMessage);
        history.push("/");
    }

    const handleOnChange = (e, values) => {
        var newCompletStatus = completeStatus;
       // console.log(e.target.name)
        if (isNullOrEmpty(e.target.value))
       {
           newCompletStatus[toPascalCase(e.target.name)].completed = false;
       }
       else
       {
        newCompletStatus[toPascalCase(e.target.name)].completed = true;
       
       }
        //let statusScore = props.profileStatus.value[titleCase(e.target.name)];
       setCompleteState(newCompletStatus);
       var newState = Object.values(newCompletStatus).filter(x => x.completed == true);
       var val = newState.reduce((acc, curr) => {
           return acc + curr.value
       }, props.profileStatus.key)
       setProgressState(val);
        console.log(values, newCompletStatus, newState, val);
    }

    return (
        <div className="app align-items-center">
            <Container>
                <Row className="justify-content-center no-gutters">

                    <Col md="12" lg="12" xl="12">
                        <Card >
                            <CardBody className="p-4">
                                <div className="flex-row justify-content-center mb-2">
                                    {/* <img src={logo} className="" alt="admin@bootstrapmaster.com" height="32px" /> */}
    <Progress color={progressState === 100 ? "success" : "info"}  value={progressState}>{progressState}{progressState === 100 ? '% Completed' : '%'}</Progress>
                                </div>
                                <Row className="justify-content-center no-gutters">
<Col md="9" lg="7" xl="6">


                               
                                <h2 className="my-3 text-center">Complete Profile</h2>
                                {/* <Button color="secondary" onClick={toggleSuccessMessage}>Toggle</Button> */}
                                {/* <p className="text-muted">Personal Information</p> */}
                                <Formik
                                    initialValues={{
                                        email: props.user.email,
                                        firstName: props.user.firstName || '',
                                        lastName: props.user.lastName || '',
                                        gender: props.user.additionalInfo.gender || '',
                                        maritalStatus: props.user.additionalInfo.maritalStatus || '',
                                        address: props.user.additionalInfo.address || '',
                                        // postalCode: props.user.additionalInfo.postalCode || '',
                                        phoneNumber: props.user.additionalInfo.phoneNumber,


                                        // contactdate: new Date()
                                    }}
                                    validationSchema={Yup.object({
                                        firstName: Yup.string()
                                            .max(15, 'Must be 15 characters or less')
                                            .required('First name Required'),
                                        lastName: Yup.string()
                                            .max(15, 'Must be 15 characters or less')
                                            .required('Last name Required'),
                                        // phoneNumber: Yup.string()
                                        //     .max(25, 'Must be 25 characters or less')
                                        //     .required('Phone number Required')
                                        //     .test("is-valid-phone", "Phone Number must be valid", (value) => {
                                        //         return new Promise((resolve, reject) => {
                                        //             if (value)
                                        //             {
                                        //                 resolve(parseMax(value).isValid());
                                        //             }
                                        //             else
                                        //             {
                                        //                 resolve(false)
                                        //             }


                                        //         })
                                        //     })
                                        //     .test('checkAvailability', 'Phone Number already registered', (value) => {

                                        //         return new Promise((resolve, reject) => {
                                        //             if (value)
                                        //             {
                                        //                 if (parseMax(value).isValid()) {
                                        //                     checkPhoneNumberIsRegistered(value).then(result => {
                                        //                         resolve(!result);
                                        //                     }).catch(err => {
                                        //                         resolve(false);
                                        //                     })
                                        //                 }
                                        //                 else
                                        //                 {
                                        //                     resolve(false);
                                        //                 }
                                        //             }
                                        //             else
                                        //             {
                                        //                 resolve(false)
                                        //             }


                                        //         })
                                        //     }),

                                        address: Yup.string()
                                            .required('Address Required'),
                                        // postalCode: Yup.string()
                                        //     .required('Postal Code Required'),    
                                        

                                        // acceptedTerms: Yup.boolean()
                                        //     .required('Required')
                                        //     .oneOf([true], 'You must accept the terms and conditions.'),
                                        gender: Yup.string()
                                            .oneOf(
                                                soulWinOptions().gender.map(x => x.value),
                                                'Invalid Gender'
                                            )
                                            .required('Gender Required'),
                                    })}
                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                        toast.info(<LoadingSpinner message="Please wait...." />, LoadingAlertConfig)
                                        setTimeout(() => {
                                            //  alert(JSON.stringify(values, null, 2));
                                            //  toggleSuccessMessage();
                                            values["password"] = "############";
                                            values["confirmPassword"] = "############";
                                            if (isOnline)
                                            {
                                                updateUserProfile(values).then(res => {
                                                    setSubmitting(false);
                                                    if (res.success) {
                                                        toast.dismiss();
                                                        toast.success("Profile Update Successful");
                                                        toggleSuccessMessage();
                                                       
                                                        if (props.onSubmitSuccess)
                                                        {
                                                            props.onSubmitSuccess(res.result)
                                                        }
                                                        //history.push("/signupcompleted");
                                                    }
                                                    else {
                                                        toast.error(`${res.errorMessage}`)
                                                    }
                                                    //console.log(res)
    
                                                    // if (result.)
                                                }).catch(err => {
                                                    console.log(err);
                                                    toast.error(err)
                                                    setSubmitting(false);
    
                                                }) 
                                            }
                                            else
                                            {
                                                offlineMessage(offlineMessages.impossibleOperation, true)
                                            }
                                            
                                            setSubmitting(false);
                                        }, 400);
                                    }}
                                >

                                    {formikProps => (
                                        <form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset} {...formikProps}>
                                            <Row>
                                                <Col md="12">

                                                    {isNullOrEmpty(props.user.firstName) && <Card key="firstName" className="mb-1">
                                                        <CardHeader 
                                                        className={classNames({'bg-danger' : isNullOrEmpty(formikProps.values["firstName"]), 'bg-success' : !isNullOrEmpty(formikProps.values["firstName"])})}  
                                                        onClick={() => toggleCollapse("firstName")}>
                                                            First Name
                                                        </CardHeader>
                                                        <Collapse isOpen={collapse === "firstName"}>
                                                            <CardBody>
                                                                <SyncTextInput
                                                                    label="First Name"
                                                                    iconOrText={<i className="fas fa-user"></i>}
                                                                    name="firstName"
                                                                    type="text"
                                                                    placeholder="First Name"
                                                                    isRequired={true}
                                                                    onChange={(value) => {
                                                                        formikProps.handleChange(value);
                                                                        handleOnChange(value, formikProps.values)
                                                                    }}
                                                                />
                                                            </CardBody>
                                                        </Collapse>
                                                    </Card>}
                                                    
                                                    {isNullOrEmpty(props.user.lastName)&& <Card key="lastName" className="mb-1">
                                                        <CardHeader 
                                                        onClick={() => toggleCollapse("lastName")}
                                                        className={classNames({'bg-danger' : isNullOrEmpty(formikProps.values["lastName"]), 'bg-success' : !isNullOrEmpty(formikProps.values["lastName"])})}>
                                                            Last Name
                                                        </CardHeader>
                                                        <Collapse isOpen={collapse === "lastName"}>
                                                            <CardBody>
                                                                <SyncTextInput
                                                                    label="Last Name"
                                                                    iconOrText={<i className="fas fa-user-female"></i>}
                                                                    name="lastName"
                                                                    type="text"
                                                                    placeholder="Last Name"
                                                                    isRequired={true}
                                                                    onChange={(value) => {
                                                                        formikProps.handleChange(value);
                                                                        handleOnChange(value, formikProps.values)
                                                                    }}
                                                                />
                                                            </CardBody>
                                                        </Collapse>
                                                    </Card>}
                                                    
                                                    { isNullOrEmpty(props.user.additionalInfo.gender) && <Card key="gender" className="mb-1">
                                                        <CardHeader className={classNames({'bg-danger' : isNullOrEmpty(formikProps.values["gender"]), 'bg-success' : !isNullOrEmpty(formikProps.values["gender"])})} onClick={() => toggleCollapse("gender")}>
                                                            Gender
                                                        </CardHeader>
                                                        <Collapse isOpen={collapse === "gender"}>
                                                            <CardBody>
                                                                <Field
                                                                    name="gender"
                                                                    iconOrText={<i className="fa fa-intersex"></i>}
                                                                    component={SyncSelectInput}
                                                                    options={soulWinOptions().gender}
                                                                    placeholder="Gender"
                                                                    isRequired={true}
                                                                    onChange={(value) => {
                                                                        formikProps.handleChange(value);
                                                                        handleOnChange(value, formikProps.values)
                                                                    }}
                                                                    inputprops={{
                                                                        name: "gender",
                                                                        id: "gender",
                                                                        options: soulWinOptions().gender.map(x => x.value),
                                                                        placeholder: "Gender",
                                                                        defaultOption: "Gender"
                                                                    }}
                                                                />
                                                            </CardBody>
                                                        </Collapse>
                                                    </Card>}
                                                    
                                                    {/* {isNullOrEmpty(props.user.additionalInfo.postalCode) && <Card key="postalCode" className="mb-1">
                                                        <CardHeader 
                                                        onClick={() => toggleCollapse("postalCode")}
                                                        className={classNames({'bg-danger' : isNullOrEmpty(formikProps.values["postalCode"]), 'bg-success' : !isNullOrEmpty(formikProps.values["postalCode"])})}>
                                                            Postal Code
                                                        </CardHeader>
                                                        <Collapse isOpen={collapse === "postalCode"}>
                                                            <CardBody>
                                                            
                                                    <SyncTextInput
                                                        label="Postal Code"
                                                        iconOrText={<i className="fa fa-address-card"></i>}
                                                        name="postalCode"
                                                        type="text"
                                                        placeholder="Postal Code"
                                                        isRequired={true}
                                                        onChange={(value) => {
                                                            formikProps.handleChange(value);
                                                            handleOnChange(value, formikProps.values)
                                                        }}
                                                    />
                                             
                                                        </CardBody>
                                                        </Collapse>
                                                    </Card>} */}

                                                    {isNullOrEmpty(props.user.additionalInfo.address) && <Card key="address" className="mb-1">
                                                        <CardHeader 
                                                        onClick={() => toggleCollapse("address")}
                                                        className={classNames({'bg-danger' : isNullOrEmpty(formikProps.values["address"]), 'bg-success' : !isNullOrEmpty(formikProps.values["address"])})}>
                                                            Address
                                                        </CardHeader>
                                                        <Collapse isOpen={collapse === "address"}>
                                                            <CardBody>
                                                            <SyncTextInput
                                                        label="Address"
                                                        iconOrText={<i className="icon-home"></i>}
                                                        name="address"
                                                        type="text"
                                                        placeholder="Address"
                                                        isRequired={true}
                                                        onChange={(value) => {
                                                            formikProps.handleChange(value);
                                                            handleOnChange(value, formikProps.values)
                                                        }}
                                                    />
                                                        </CardBody>
                                                        </Collapse>
                                                    </Card>}

                                                    

                                                    {/* <Card key="lastName" className="mb-1">
                                                        <CardHeader onClick={() => toggleCollapse("lastName")}>
                                                            Last Name
                                                        </CardHeader>
                                                        <Collapse isOpen={collapse === "lastName"}>
                                                            <CardBody>
                                                                Last Name
                                                        </CardBody>
                                                        </Collapse>
                                                    </Card> */}

                                                </Col>

                                               
                                            </Row>

                                           
                                            <Row>
                                                {/* <Col md="4">
                                                   
                                                    <Field placeholder="Enter Phone Number" isRequired={true} 
                                                    country="ng" preferredCountries={['ng', 'gh', 'us']} 
                                                    name="phoneNumber" 
                                                    component={SyncPhoneInput2} inputprops={{
                                                        name: "phoneNumber",
                                                        id: "phoneNumber",
                                                        required: true,
                                                    }} />


                                                </Col>
                                                <Col md="4">
                                                    <SyncTextInput
                                                        label="Email"
                                                        iconOrText="@"
                                                        name="email"
                                                        type="text"
                                                        placeholder="Email"
                                                        autoComplete="email"
                                                        isRequired={true}
                                                    />
                                                </Col> */}

                                               

                                            </Row>
                                           
                                            {/* 
          <MySelect label="Job Type" name="jobType">
            <option value="">Select a job type</option>
            <option value="designer">Designer</option>
            <option value="development">Developer</option>
            <option value="product">Product Manager</option>
            <option value="other">Other</option>
          </MySelect>
          <MyCheckbox name="acceptedTerms">
            I accept the terms and conditions
          </MyCheckbox> */}
                                            {/* <SyncCheckBox name="acceptedTerms" id="acceptedTerms" label="">
                                                By using this application, you agree to the
           <a href="/termsofservice"> Terms and Conditions.</a>
                                            </SyncCheckBox> */}
                                            <div className="ml-auto mt-4">
                                                <button type="submit" className="btn btn-success ml-auto" disabled={!(formikProps.isValid && formikProps.dirty) || formikProps.isSubmitting}>Update</button>
                                            </div>

                                        </form>
                                    )}
                                    {/* <Form>

                                       
                                    </Form> */}
                                </Formik>
                                </Col>
                                </Row>

                            </CardBody>
                            {/* <CardFooter>
                                <div>
                                    <Link to="/login">
                                        <h6><strong>Already have an account?</strong></h6>
                                    </Link>
                                </div>
                            </CardFooter> */}
                        </Card>
                    </Col>
                </Row>

            </Container>
            <Modal isOpen={successMessage} toggle={toggleSuccessMessage} backdrop={"static"}
                className={'modal-md modal-confirm'}>
                <ModalHeader toggle={toggleSuccessMessage} close={closeBtn}>
                    <div class="icon-box">
                       <FontAwesomeIcon icon="check-circle" ></FontAwesomeIcon>
                    </div>
                    <h4 >Awesome!</h4>
                </ModalHeader>
                <ModalBody>
                    <p class="text-center">Your account created successfully. Check your email for verification details.</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-block" color="success" onClick={handleFormSuccess}>OK</Button>{' '}
                    {/* <Button color="secondary" onClick={toggleSuccessMessage}>Cancel</Button> */}
                </ModalFooter>
            </Modal>
        </div>

    );
};

export default UserProfileUpdateForm;