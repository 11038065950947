
const config = {
    api: {
      host: process.env.REACT_APP_SOULWIN_API_HOST_URL,
      authorization_host: process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL,
      timeout: 20000,
      app_id: process.env.REACT_APP_SOULWIN_CLIENT_ID,
      app_public_key: process.env.REACT_APP_SOULWIN_PUBLIC_KEY,
    },
    analytics: {
      soulwin_analytic_id: process.env.REACT_APP_SOULWIN_ANALYTIC_ID,
      analytic_id: process.env.REACT_APP_CLIENT_ANALYTIC_ID
    }
  };
  
  const API_HOST = config.api.host;
  const APP_ID = config.api.app_id;
  const APP_KEY = config.api.app_public_key;
  const API_AUTHORIZATION_HOST = config.api.authorization_host;
  
  export {
    API_HOST,
    APP_ID, APP_KEY, API_AUTHORIZATION_HOST
  }
  
  export default config