import React from 'react';
// import { useSelector } from "react-redux";
import classNames from 'classnames';


function AppPageHeader(props)
{
    const { className, children, tag: Tag = "nav", ...attributes } = props;
    const classes = classNames(className, 'app-page-header', 'navbar');
    return (
        <Tag className={classes} {...attributes}>
        {children}
        </Tag>
    )
}

export function AppModalPageHeader(props)
{
    const { className, children, tag: Tag = "nav", ...attributes } = props;
    const classes = classNames(className, 'app-modal-page-header', 'navbar');
    return (
        <Tag className={classes} {...attributes}>
        {children}
        </Tag>
    )
}


export default AppPageHeader