import React from "react";
import { Badge, Label } from "reactstrap";
import {
  splitCamelCase,
  isNumber,
  booststrapColors,
  numberWithCommas,
  SyncColors,
  isEmpty,
  isNullOrEmpty
} from "../utils/SyncUtils";
import {
 sortObjectArraysToColumnKeyData
} from "../utils/DataUtils";
import moment from "moment";
import {
  CDropdown,
  CDropdownMenu,
  CDropdownToggle,
  CDropdownItem,
} from "@coreui/react";
import { GroupAdminOnly } from "../admin/components";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/max'

export const DynamicFormValue = (definition, stringValue) => {
  const { htmlType, validationType } = definition;
  // console.log("InputDefinition", definition, "StringValue", stringValue)
  switch (validationType) {
    case "text":
    case "string":
    case "textarea":  
      return stringValue;
    case "number":
    case "email":
      if (stringValue === "n/a"){
        return "";
      }
        return stringValue;
    case "date":
      if (stringValue === "n/a"){
        return "";
      }
      return stringValue;
    case 'array':
      if (stringValue === "n/a"){
        return [];
      }
      if (isNullOrEmpty(stringValue)){
        return [];
      }
      return stringValue.split(',')
    default:
      return stringValue;
  }
  return stringValue
}

export const DynamicFormColumns = (
  columnData = [],
  sortableColumns = [],
  hideColumns = [],
  showActions = false,
  onEdit,
  onRemove,
  maxDepth = 10,
  columnClassName = "soulwin-group-name",
  columnWidth = "150px"
) => {
  // console.log(columnData, "ColumnData");
  // console.log(sortableColumns, "SortableColumns");
  var addColumns = [];
  var sortColumns = ["CreatedOn", "PostedBy"].concat(sortableColumns);

  var hiddenColumns = ["Id"].concat(hideColumns);

  const handleEdit = (row) => {
    // console.log(row, "Tried to Edit");
    if (onEdit) {
      onEdit(row);
    }
  };

  const handleRemove = (row) => {
    console.log(row, "Tried to Delete");
    if (onEdit) {
      onEdit(row);
    }
  };

  columnData.forEach((x) => {
    var hasData = sortColumns.filter((u) => u === x);
    var canSort = hasData.length === 1;
    // console.log(hasData, canSort, x);

    var hide = hiddenColumns.filter((u) => u === x).length > 0;

    var info = {
      hidden: hide,
      text: splitCamelCase(x),
      dataField: x,
      sort: { canSort },
      classes: { columnClassName },
      width: { columnWidth },
      formatExtraData: { columnData, column: x },
      formatter: (data, row, rowIndex, formatExtraData) => {
        // var dataField = formatExtraData[rowIndex];
        // console.log(dataField)
        if (formatExtraData.column === "CreatedOn") {
          return moment(data).format("lll");
        }

        if (formatExtraData.column === "PostedBy") {
          var color = SyncColors.getColorFromString(data);
          return (
            <div>
              <div className="group-badge">
                <Label
                  className="GroupLabel"
                  style={{
                    backgroundColor: `${color.name}`,
                    color: `${color.foreColor}`,
                  }}
                >
                  {data}
                </Label>
              </div>
            </div>
          );
        }
        if (formatExtraData.column.toLowerCase().includes("phone")) {
          return (
            <div style={{ fontSize: "0.75rem" }} className="group-badge">
              {data}
            </div>
          );
        }
        if (!isNumber(data)) {
          if (moment(data).isValid()) {
            if (formatExtraData.column.toLowerCase().includes("birth")) {
              return moment(data).format("MMM D");
            }
            return moment(data).format("ll");
          }
        }

        if (isNumber(data)) {
          var color = booststrapColors.getColorFromString(
            formatExtraData.column
          );
          return numberWithCommas(data);
          //   return (
          //     <div><h5><strong><Badge color={color.name}>{data}</Badge></strong></h5></div>
          // )
        }

        return (
          <div style={{ fontSize: "0.75rem" }} className="group-badge">
            {data}
          </div>
        );
      },

      footer: (columnData, column, columnIndex) => {
        if (columnData.length <= 0) {
          return "";
        }
        if (column.dataField === "Id" || column.dataField === "CreatedOn") {
          return "";
        }
        if (column.dataField.toLowerCase().includes("date")) {
          return "";
        }
        if (column.dataField.toLowerCase().includes("phone")) {
          return "";
        }
       
        //Check if any cell has a number
        var maxChecking = Math.max(columnData.length, maxDepth);
        let hasNumber = false;
        // for (let i = 0; i < maxChecking; i++) {
        //   var item = columnData[i];
        //   if (isNumber(item)) {
        //     hasNumber = true;
        //     break; }
        // }
        let i = 0;
        while (i < maxChecking) {
          var item = columnData[i];
          if (isNumber(item)) {
            hasNumber = true;
            break;
          }
          i = i + 1;
        }

        if (!hasNumber) {
          return "";
        }

        var color = booststrapColors.getColorFromString(column.dataField);
        var result = columnData.reduce((acc, item) => {
          var countItem = item;
          if (!isNumber(item)) {
            countItem = 0;
          }

          return acc + countItem;
        }, 0);

        return (
          <div>
            <h5>
              <strong>
                <Badge pill color={color.name}>
                  {numberWithCommas(result)}
                </Badge>
              </strong>
            </h5>
          </div>
        );
      },
    };
    info["hidden"] = hide;
    info["sort"] = canSort;
    addColumns.push(info);
  });

  var actions = {
    hidden: false,
    text: "Actions",
    isDummyField: true,
    dataField: "actions",
    // width: "32px",
    headerClasses: "mr-4 float-right",
    // classes: "soulwin-table-th",
    classes: { columnClassName },
    width: { columnWidth },
    footer: (columnData, column, columnIndex) => {
      return "";
    },
    formatter: (data, row) => {
      return (
        <div className="">
          {/* 
              <GroupAdminOnly>
                  
              </GroupAdminOnly> */}
          <CDropdown
            className="c-header-nav-items mx-2 float-right"
            direction="down"
            inNav
          >
            <CDropdownToggle className="c-header-nav-link" caret={false}>
            <FontAwesomeIcon icon="ellipsis-h"  size="lg" className='menu-icon' ></FontAwesomeIcon>
            </CDropdownToggle>
            <CDropdownMenu className="py-0" placement="bottom-end">
              <CDropdownItem onClick={() => handleEdit(row)}>
                <i className="fa fa-exchange-alt mfe-2"></i>
                Edit
              </CDropdownItem>
              <GroupAdminOnly>
              <CDropdownItem disabled onClick={() => handleRemove(row)}>
                <FontAwesomeIcon icon="times" className="mfe-2"></FontAwesomeIcon>
                Delete
              </CDropdownItem>
              <CDropdownItem disabled onClick={() => handleRemove(row)}>
                <i className="fa fa-lock mfe-2"></i>
                Lock
              </CDropdownItem>
              </GroupAdminOnly>
             
            </CDropdownMenu>
          </CDropdown>
        </div>
      );
    },
  };

  if (showActions) {
    addColumns.push(actions);
  }

  return addColumns;
};

export const DynamicFormMobileColumns = (columnData = [],
  sortableColumns = [],
  hideColumns = [],
  showActions = false,
  onEdit,
  onRemove,
  maxDepth = 10,
  columnClassName = "soulwin-group-name",
  columnWidth = "150px") => {

    var addColumns = [];
    var sortColumns = ["CreatedOn", "PostedBy"].concat(sortableColumns);

    var hiddenColumns = ["Id"].concat(hideColumns);
  
    const handleEdit = (row) => {
      // console.log(row, "Tried to Edit");
      if (onEdit) {
        onEdit(row);
      }
    };
  
    const handleRemove = (row) => {
      console.log(row, "Tried to Delete");
      if (onEdit) {
        onEdit(row);
      }
    };

    columnData.forEach((x) => {
      var hasData = sortColumns.filter((u) => u === x);
      var canSort = hasData.length === 1;
      var hide = hiddenColumns.filter((u) => u === x).length > 0;
      
      var info = [x, splitCamelCase(x), (item) => {
        var data = item[x]
        if (x === "CreatedOn"){
          return moment(data).format("lll");
        }
        if (x === "PostedBy") {
          var color = SyncColors.getColorFromString(data);
          return (
            <div>
              <div className="group-badge">
                <Label
                  className="GroupLabel"
                  style={{
                    backgroundColor: `${color.name}`,
                    color: `${color.foreColor}`,
                  }}
                >
                  {data}
                </Label>
              </div>
            </div>
          );
        }
        if (!isNumber(data)) {
          if (moment(data).isValid()) {
            if (x.toLowerCase().includes("birth")) {
              return moment(data).format("MMM D");
            }
            return moment(data).format("ll");
          }
        }
       //console.log(data, "Parsing Attempt")
        if (typeof data === "string"){
          var parsePhone = parseMax(data)
        if (parsePhone && parsePhone.isValid()) {
          return parsePhone.number
      }
        }
        
        
        if (isNumber(data)) {
          var color = booststrapColors.getColorFromString(x);
          // return numberWithCommas(data);
            return (
              <div><h5><strong><Badge pill color={color.name}>{numberWithCommas(data)}</Badge></strong></h5></div>
          )
        }
        return item[x]
      }]
      if (hide){
          info.push({visibility: "xxl"})
      }
      addColumns.push(info);
    
    });

    var actions = ["actions", "", (row) => {
      return (
       <div >
         {/* 
             <GroupAdminOnly>
                 
             </GroupAdminOnly> */}
         <CDropdown
           className="c-header-nav-items float-right"
           direction="down"
         >
           <CDropdownToggle className="c-header-nav-link" caret={false}>
           <FontAwesomeIcon icon="ellipsis-v"  size="lg" className='menu-icon' ></FontAwesomeIcon>
           </CDropdownToggle>
           <CDropdownMenu className="py-0" placement="bottom-end">
             <CDropdownItem onClick={() => handleEdit(row)}>
               <i className="fa fa-exchange-alt mfe-2"></i>
               Edit
             </CDropdownItem>
             <GroupAdminOnly>
             <CDropdownItem disabled onClick={() => handleRemove(row)}>
               <FontAwesomeIcon icon="times" className="mfe-2"></FontAwesomeIcon>
               Delete
             </CDropdownItem>
             <CDropdownItem disabled onClick={() => handleRemove(row)}>
               <i className="fa fa-lock mfe-2"></i>
               Lock
             </CDropdownItem>
             </GroupAdminOnly>
            
           </CDropdownMenu>
         </CDropdown>
       </div>
     );
     }, {thVisibility: "xl", tdAttrs: "class='nopadding'"    }]
    if (showActions) {
      addColumns.unshift(actions);
    }
    return addColumns;

}

export const dataSummationCalc = (columnData, column, maxDeep = 10) => {
  if (columnData.length <= 0) {
            return "";
          }
           if (column === "Id" || column === "CreatedOn") {
            return "";
          }
           var maxChecking = Math.max(columnData.length, maxDeep);
          let hasNumber = false;
          let i = 0;
          while (i < maxChecking) {
            var item = columnData[i];
            if (isNumber(item)) {
              hasNumber = true;
              break;
            }
            i = i + 1;
          }
  
          if (!hasNumber) {
            return "";
          }
          
          var result = columnData.reduce((acc, item) => {
            var countItem = item;
            if (!isNumber(item)) {
              countItem = 0;
            }
  
            return acc + countItem;
          }, 0);
  return result
  }


  export const getDataSummation = (data) => {
    let summationData = {};
    if (Array.isArray(data) && data.length > 0) {
      var firstObject = data[0];
      let headers = Object.keys(firstObject);
      var columnData = sortObjectArraysToColumnKeyData(data, headers);
      if (!isEmpty(columnData)){
        headers.forEach(x => {
          var data = columnData[x];
          var result = dataSummationCalc (data, x)
          summationData[x] = result;
          //console.log(result);
         })
      }
    }

    return summationData;
  }

  export const formatDateColumn = (data) => {
    let summationData = data;
    if (Array.isArray(data) && data.length > 0) {
      var firstObject = data[0];
      let headers = Object.keys(firstObject).filter(x => x.includes("Date"));
      let finalData = data.map(u => {
        var nextData = {...u};
        headers.forEach(w => {
          if (nextData[w]){
            var prevData = nextData[w];
            if (prevData !== "n/a"){
              nextData[w] = moment(prevData).format("ll")
            }
          }
          
        })
        return nextData;
      })
      return finalData;
    }

    return summationData;
  }
