import { createSlice } from '@reduxjs/toolkit'
import { array_move, isEmpty } from '../../utils/SyncUtils'


export const initialState = {

    userUpComingInvites: [],
    userPendingInvites: [],
    userTodayInvites: [],
    userInvitesProspects: [],
    currentGroupUpComingInvites: [],
    currentGroupPendingInvites: [],
    currentGroupTodayInvites: [],
    currentGroupInvitesProspects: [],
    allInvitesProspects: [],
    loadedInvitesProspects: [],
    invitesAccordion: [],
    userInvites: [],
    currentGroupInvites: [],
    pendingUserInvitesBasket: {},
    pendingGroupInvitesBasket: {},
};

const invitesSlice = createSlice({
    name: 'invitations',
    initialState,
    reducers: {
        
        setUserInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, userInvites: [] }
            }
            return { ...state, userInvites: payload }
        },
        setCurrentGroupInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, currentGroupInvites: [] }
            }
            return { ...state, currentGroupInvites: payload }
        },

        setUserTodayInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, userTodayInvites: [] }
            }
            return { ...state, userTodayInvites: payload }
        },
        setCurrentGroupTodayInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, currentGroupTodayInvites: [] }
            }
            return { ...state, currentGroupTodayInvites: payload }
        },

        setUserUpComingInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, userUpComingInvites: [] }
            }
            return { ...state, userUpComingInvites: payload }
        },
        setCurrentGroupUpComingInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, currentGroupUpComingInvites: [] }
            }
            return { ...state, currentGroupUpComingInvites: payload }
        },
        setUserPendingInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, userPendingInvites: [] }
            }
            return { ...state, userPendingInvites: payload }
        },
        setCurrentGroupPendingInvites: (state, { payload }) => {
            if (payload.length <= 0) {
                return { ...state, currentGroupPendingInvites: [] }
            }
            return { ...state, currentGroupPendingInvites: payload }
        },
        setUserInvitesProspects: (state, { payload }) => {
            return { ...state, userInvitesProspects: payload }
        },
        setCurrentGroupInvitesProspects: (state, { payload }) => {
            return { ...state, currentGroupInvitesProspects: payload }
        },
        addToUserUpComingInvites: (state, { payload }) => {
            state.userUpComingInvites.unshift(payload);
           // return { ...state, userNotifications: payload }
        },
        addToCurrentGroupUpComingInvites: (state, { payload }) => {
            state.currentGroupUpComingInvites.unshift(payload);
           // return { ...state, userNotifications: payload }
        },
        addToUserInvites: (state, { payload }) => {
            state.userInvites.unshift(payload);
           // return { ...state, userNotifications: payload }
        },
        addToCurrentGroupInvites: (state, { payload }) => {
            state.currentGroupInvites.unshift(payload);
        },
        addInvitesProspects: (state, { payload }) => {
            let arr1 = JSON.parse(JSON.stringify(state.allInvitesProspects));;
            //let arr2 = [payload, ...arr1,];
            var props = payload.concat(arr1);
            let hashedMoreProspects = [
                ...new Map(props.map(obj => [`${obj.guid}`, obj]))
                    .values()
            ];

            return { ...state, allInvitesProspects: hashedMoreProspects }
            
        },
        addOrUpdateLoadedInvitesProspect: (state, { payload }) => {
            // console.log(payload, "Update Invite Prospects")
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                let amExisting = state.allInvitesProspects.findIndex((element) => {
                    return element.guid.toLowerCase() === payload.guid.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.allInvitesProspects[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                // else {
                //     state.loadedTaskProspects.unshift(payload)
                // }


            } else {
                console.log(payload, "Couldn't add to Invites Prospects")
            }
           
        },
        addOrUpdateInvitesAccordion: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.key !== null && payload.key !== undefined && state.invitesAccordion !== undefined) {
                let amExisting = state.invitesAccordion.findIndex((element) => {
                    return element.key.toLowerCase() === payload.key.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.invitesAccordion[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    state.invitesAccordion.unshift(payload)
                }


            }
           
        },
        resetAndAddOrUpdateInvitesAccordion: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload.data))
            if (payload.reset){
                state.invitesAccordion.forEach(x => {
                    if (x.key !== payload.data.key && x.value >= 0)
                    {
                        x.value = -1;
                       // break;
                    }
                })
            }

            if (payload.data.key !== null && payload.data.key !== undefined && state.invitesAccordion !== undefined) {
                let amExisting = state.invitesAccordion.findIndex((element) => {
                    return element.key.toLowerCase() === payload.data.key.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.invitesAccordion[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    state.invitesAccordion.unshift(payload.data)
                }


            }
           
        },
        clearInvitesAccordion: (state) => {

            return { ...state, invitesAccordion: [] }
        },
        setPendingGroupInvitesBasket: (state, { payload }) => {
           
            return { ...state, pendingGroupInvitesBasket: payload }
        },
        setPendingUserInvitesBasket: (state, { payload }) => {
           
            return { ...state, pendingUserInvitesBasket: payload }
        },

    }

})

export const { setUserUpComingInvites, setCurrentGroupUpComingInvites, addToCurrentGroupUpComingInvites, clearInvitesAccordion,
    addToUserUpComingInvites, addInvitesProspects, addOrUpdateLoadedInvitesProspect, addOrUpdateInvitesAccordion, resetAndAddOrUpdateInvitesAccordion,
setUserTodayInvites, setCurrentGroupTodayInvites, setUserPendingInvites, setCurrentGroupPendingInvites,
setUserInvites, setCurrentGroupInvites, addToCurrentGroupInvites, addToUserInvites, setPendingGroupInvitesBasket, setPendingUserInvitesBasket} = invitesSlice.actions;

// A selector
export const invitationsSelector = state => state.invitations

// The reducer
export default invitesSlice.reducer