import * as React from 'react'
// import { Formik, Form, Field, FieldProps } from 'formik'
import { FormFeedback, FormGroup, Label } from "reactstrap";
import MultiSelect from '@carlospence/react-multi-select-component';
import MultiSelectItemRenderer from './MultiSelectItemRenderer'

const PlainReactMultiSelectInput = ({
    // field, // { name, value, onChange, onBlur }
    // name,
    iconOrText,
    itemRenderer,
    invalid = false, valid = true,
    selectedValues = [],
    onSelectChange,
    // form: { touched, errors, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const { options, isRequired = false, label,  name, maxWidth = 0,  showLabel = false, selectValueOnly = false, } = props
    // console.log(name, "MultiSelect")
    const icon = iconOrText ?? ""
    // let error = errors[field.name];
    // let touch = touched[field.name];
    let currentValue = "";
    //console.log(errors)
    //console.log(error)
    //console.log(touch)
    // console.log(currentValue, "CharlesE")
    // const getValue = () => {
    //     if (options) {
    //       return options.filter(option => field.value.indexOf(option.value) >= 0)
          
    //     } else {
    //       return []
    //     }
    //   };


    return (
        <FormGroup style={maxWidth <= 0 ? {} : {maxWidth: maxWidth + 35}}>
            {/* <label htmlFor={field.name}>{props.label}</label> */}
            {showLabel ? <Label for={props.id || props.name} className={props.labelColor}>{label}</Label> : "" }
            {/* <label htmlFor={props.id || props.name}>{label}</label> */}
            {/* <InputGroup >
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        {icon}
                    </InputGroupText>
                </InputGroupAddon> */}
                  {/* <label>{isRequired && <span className="text-danger">&nbsp;*</span>}</label> */}
                <MultiSelect
                    // {...field}
                    {...props}
                    options={options}
                    style={{maxWidth: 450}}
                    className={currentValue && currentValue.length <= 0 && isRequired ? `multi-select ${name}-select multi-select-error` : `multi-select ${name}-select select-panel`}
                    // value={getValue}
                    // value={(options ? options.find(option => option.value === field.value) ? options.find(option => option.value === field.value) : '' : '')}
                    ItemRenderer={itemRenderer !== null ? itemRenderer : MultiSelectItemRenderer}
                    onChange={option => {
                        if (onSelectChange)
                        {
                            onSelectChange(option)
                        }
                        // if (option)
                        // {
                        //     setFieldValue(field.name, option.map(x => x))
                           
                           
                        // }
                        // else
                        // {
                        //     setFieldValue(field.name, [])
                        // }
                      
                    }}
                />
                
            {/* </InputGroup> */}
            {/* {errors[field.name] && (
                <div>{errors[field.name]}</div>
            )} */}
             {/* {touched[field.name] && errors[field.name] && (
                <div className="error text-danger">&nbsp;{errors[field.name]}</div>
            )} */}
            {/* {error && <FormFeedback>{error}</FormFeedback>} */}
            {/* {currentValue.length <= 0 && isRequired &&  <div className="error text-danger">&nbsp;Is Required</div>} */}
            {/* <div className="error text-danger">&nbsp;Is Requireddd</div> */}
        </FormGroup>
    )
}

export default PlainReactMultiSelectInput