import numFormat from 'number-format'
import moment from 'moment'

/// Check for Empty Object
export function isEmpty(obj) {
    // null and undefined are "empty"
    if (obj === null) return true;

    if (obj === undefined) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== "object") return true;
    for (var prop in obj) {
        if (obj.hasOwnProperty(prop))
            return false;
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export function removeArray(arr) {
    var what, a = arguments, L = a.length, ax;
    while (L > 1 && arr.length) {
        what = a[--L];
        while ((ax = arr.indexOf(what)) !== -1) {
            arr.splice(ax, 1);
        }
    }
    return arr;
}

export function array_move(arr, old_index, new_index) {
    if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
            arr.push(undefined);
        }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
};

export function compareArray(value, other) {

    if (!Array.isArray(value)) {
        console.log("Array 1 is not an array", value)
        return false
    }
    if (!Array.isArray(other)) {
        console.log("Array 2 is not an array", other)
        return false
    }
    if (!value || !other) {
        console.log("Both is not an array", other)
        return false
    }

    // Get the value type
    var type = Object.prototype.toString.call(value);

    // If the two objects are not the same type, return false
    if (type !== Object.prototype.toString.call(other)) return false;

    // If items are not an object or array, return false
    if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

    // Compare the length of the length of the two items
    var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
    var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
    if (valueLen !== otherLen) return false;

    // Compare two items
    var compare = function (item1, item2) {

        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
            if (!compareArray(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {

            // If the two items are not the same type, return false
            if (itemType !== Object.prototype.toString.call(item2)) return false;

            // Else if it's a function, convert to a string and compare
            // Otherwise, just compare
            if (itemType === '[object Function]') {
                if (item1.toString() !== item2.toString()) return false;
            } else {
                if (item1 !== item2) return false;
            }

        }
    };

    // Compare properties
    if (type === '[object Array]') {
        for (var i = 0; i < valueLen; i++) {
            if (compare(value[i], other[i]) === false) return false;
        }
    } else {
        for (var key in value) {
            if (value.hasOwnProperty(key)) {
                if (compare(value[key], other[key]) === false) return false;
            }
        }
    }

    // If nothing failed, return true
    return true;

};


export function compareArray2(arr1, arr2) {

    if (!Array.isArray(arr1)) {
        console.log("Array 1 is not an array", arr1)
        return false
    }
    if (!Array.isArray(arr2)) {
        console.log("Array 2 is not an array", arr2)
        return false
    }
    if (!arr1 || !arr2) {
        console.log("Both is not an array", arr2)
        return false
    }


    let result;

    arr1.forEach((e1, i) => arr2.forEach(e2 => {

        if (e1.length > 1 && e2.length) {
            result = compareArray(e1, e2);
        } else if (e1 !== e2) {
            result = false
        } else {
            result = true
        }
    })
    )

    return result

}

export function hasClass(element, className) {
    return (' ' + element.className + ' ').indexOf(' ' + className + ' ') > -1;
}

export function hasClass2(element, className) {
    return element.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(element.className);
}


export function toggleClass(el, className) {
    var pattern = new RegExp('(^|\\s)' + className + '(\\s|$)');
    if (pattern.test(el.className)) {
        el.className = el.className.replace(pattern, ' ');
    } else {
        el.className += ' ' + className;
    }
};





export function formatCurrency(Amount, format, code) {
    // console.log(format)
    //console.log(numFormat(format, Amount))
    var DecimalSeparator = Number("1.2").toLocaleString().substr(1, 1);
    var AmountWithCommas = Amount.toLocaleString();
    var arParts = String(AmountWithCommas).split(DecimalSeparator);
    var intPart = arParts[0];
    var decPart = (arParts.length > 1 ? arParts[1] : '');
    decPart = (decPart + '00').substr(0, 2);
    if (!code) {
        code = 'NGN '
    }
    if (format) {
        return numFormat(format, Amount)
    }

    return code + ' ' + intPart + DecimalSeparator + decPart;
}

export function checkComponentValidation(validations) {
    //console.log((validations))
    //console.log(Object.values(validations))
    const validation = {}
    var values = Object.values(validations);
    for (let o of values) {
        if (o.isValid === false) {
            return o;
        }
        else {
            return { isValid: true, message: "" }
        }
    }
}


export function clearSessionStorage() {

}

export function GetTimeInWords(value) {
    if (value <= 0) {
        return "0 secs";
    }
    var diff = moment.duration(value, 'minutes');
    if (diff.days() > 1) {
        return diff.days() + " days"
    }
    if (diff.days() === 1) {
        return diff.days() + " day"
    }
    if (diff.hours() > 1) {
        return diff.hours() + " hours"
    }
    if (diff.hours() === 1) {
        return diff.hours() + " hour"
    }
    if (diff.minutes() > 1) {
        return diff.minutes() + " mins"
    }
    if (diff.minutes() === 1) {
        return diff.minutes() + " min"
    }
    if (diff.minutes() < 1) {
        return "1 min"
    }
    return "0 secs";
}

export function titleCase(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    return str.toLowerCase().split(' ').map(function (word) {
        return (word.charAt(0).toUpperCase() + word.slice(1));
    }).join(' ');
}


export function toTitleCase(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase());
    }).join(' ');
}

export function capitalizeFirstLetter(string) {
    if (isNullOrEmpty(string)) {
        return ""
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function stringFirstLetters(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    //var matches = str.match(/\b(\w|['-])/g);
    var acronym = matches.join(''); // JSON
    return acronym.toUpperCase();
}
export function stringCapitalLetters(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    // var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    var matches = str.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g);
    var spacedWords = matches.join(' '); // JSON
    return stringFirstLetters(spacedWords);
}

export function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const booststrapColors = {
    names: {
        primary: "#321fdb",
        secondary: "#ced2d8",
        success: "#2eb85c",
        lightsuccess: "#adebc2",
        danger: "#e55353",
        warning: "#f9b115",
        info: "#3399ff",
        light: "#ebedef",
        dark: "#636f83",
    },
    foreColor: {
        primary: "#ffffff",
        secondary: "#000000",
        success: "#ffffff",
        lightsuccess: "#000000",
        danger: "#ffffff",
        warning: "#ffffff",
        info: "#ffffff",
        light: "#000000",
        dark: "#ffffff",
    },
    random: () => {
        var result;
        var count = 0;
        for (var prop in booststrapColors.names)
            if (Math.random() < 1 / ++count)
                result = prop;
        return { name: result, rgb: booststrapColors.names[result], hex: booststrapColors.names[result], foreColor: booststrapColors.foreColor[result] };
    },
    getColor: (value = 0) => {
        if (value <= 0) {
            return { name: "primary", rgb: booststrapColors.names.primary, hex: SyncColors.names["primary"], foreColor: booststrapColors.foreColor['primary'] };
        }
        let colorKeys = Object.keys(booststrapColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        return { name: "danger", rgb: booststrapColors.names.danger, hex: booststrapColors.names["danger"], foreColor: booststrapColors.foreColor['danger'] };
    },
    getColorFromString: (stringValue = "null") => {
        if (isNullOrEmpty(stringValue)) {
            stringValue = "null";
        }

        var colors = Object.keys(booststrapColors.names)
        var isAvalaible = colors.findIndex(x => x === stringValue);
        if (isAvalaible >= 0) {
            return { name: stringValue, rgb: booststrapColors.names[stringValue], hex: booststrapColors.names[stringValue], foreColor: booststrapColors.foreColor[stringValue] };
        }
        var value = Math.abs(hashCode(stringValue));
        //console.log(value.toString().length)
        if (value.toString().length > 5) {
            value = parseInt(value.toString().substr(0, 2))
        }
        if (value <= 0) {
            return { name: "success", rgb: booststrapColors.names.success, hex: booststrapColors.names["success"], foreColor: booststrapColors.foreColor['success'] };
        }
        let colorKeys = Object.keys(booststrapColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: booststrapColors.names[color], hex: booststrapColors.names[color], foreColor: booststrapColors.foreColor[color] };
        }

        return { name: "danger", rgb: booststrapColors.names.danger, hex: booststrapColors.names["danger"], foreColor: booststrapColors.foreColor['danger'] };
    }
}

export const SyncColors = {
    foreColor: {
        aqua: "#000000",
        black: "#ffffff",
        blue: "#ffffff",
        brown: "#ffffff",
        cyan: "#000000",
        coral: "#ffffff",
        crimson: "#ffffff",
        darkblue: "#ffffff",
        darkcyan: "#ffffff",
        darkgrey: "#ffffff",
        darkgreen: "#ffffff",
        darkkhaki: "#ffffff",
        darkmagenta: "#ffffff",
        darkolivegreen: "#ffffff",
        darkorange: "#ffffff",
        darkorchid: "#ffffff",
        darkred: "#ffffff",
        darksalmon: "#ffffff",
        darkviolet: "#ffffff",
        forestgreen: "#ffffff",
        fuchsia: "#ffffff",
        gold: "#000000",
        green: "#ffffff",
        indigo: "#ffffff",
        khaki: "#000000",
        lightblue: "#000000",
        lightgreen: "#000000",
        lightgrey: "#000000",
        lightpink: "#000000",
        lime: "#000000",
        magenta: "#ffffff",
        maroon: "#ffffff",
        navy: "#ffffff",
        olive: "#ffffff",
        orange: "#ffffff",
        pink: "#000000",
        purple: "#ffffff",
        violet: "#ffffff",
        red: "#ffffff",
        silver: "#000000",
        teal: "#ffffff",
        turquoise: "#000000",
        yellow: "#000000",

    },
    names: {
        aqua: "#00ffff",
        black: "#000000",
        blue: "#0000ff",
        brown: "#a52a2a",
        cyan: "#00ffff",
        coral: "#ff7f50",
        crimson: "#dc143c",
        darkblue: "#00008b",
        darkcyan: "#008b8b",
        darkgrey: "#a9a9a9",
        darkgreen: "#006400",
        darkkhaki: "#bdb76b",
        darkmagenta: "#8b008b",
        darkolivegreen: "#556b2f",
        darkorange: "#ff8c00",
        darkorchid: "#9932cc",
        darkred: "#8b0000",
        darksalmon: "#e9967a",
        darkviolet: "#9400d3",
        forestgreen: "#228b22",
        fuchsia: "#ff00ff",
        gold: "#ffd700",
        green: "#008000",
        indigo: "#4b0082",
        khaki: "#f0e68c",
        lightblue: "#add8e6",
        lightgreen: "#90ee90",
        lightgrey: "#d3d3d3",
        lightpink: "#ffb6c1",
        lime: "#00ff00",
        magenta: "#ff00ff",
        maroon: "#800000",
        navy: "#000080",
        olive: "#808000",
        orange: "#ffa500",
        pink: "#ffc0cb",
        purple: "#800080",
        violet: "#800080",
        red: "#ff0000",
        silver: "#c0c0c0",
        teal: "#008080",
        turquoise: "#40e0d0",
        yellow: "#ffff00",
    },
    random: () => {
        var result;
        var count = 0;
        for (var prop in SyncColors.names)
            if (Math.random() < 1 / ++count)
                result = prop;
        console.log(result)
        //console.log(SyncColors.names.length)
        return { name: result, rgb: SyncColors.names[result], hex: SyncColors.names[result], foreColor: SyncColors.foreColor[result] };
    },
    getColor: (value = 0) => {
        if (value <= 0) {
            return { name: "green", rgb: SyncColors.names.green, hex: SyncColors.names["green"], foreColor: SyncColors.foreColor["green"] };
        }
        let colorKeys = Object.keys(SyncColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color], foreColor: SyncColors.foreColor[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color], foreColor: SyncColors.foreColor[color] };
        }

        return { name: "red", rgb: SyncColors.names.red, hex: SyncColors.names["red"], foreColor: SyncColors.foreColor['red'] };
    },
    getColorFromString: (stringValue = "null") => {
        if (isNullOrEmpty(stringValue)) {
            stringValue = "null";
        }

        var colors = Object.keys(SyncColors.names)
        var isAvalaible = colors.findIndex(x => x === stringValue);
        if (isAvalaible >= 0) {
            return { name: stringValue, rgb: SyncColors.names[stringValue], hex: SyncColors.names[stringValue], foreColor: SyncColors.foreColor[stringValue] };
        }
        var value = Math.abs(hashCode(stringValue));
        //console.log(value.toString().length)
        if (value.toString().length > 5) {
            value = parseInt(value.toString().substr(0, 2))
        }
        if (value <= 0) {
            return { name: "green", rgb: SyncColors.names.green, hex: SyncColors.names["green"], foreColor: SyncColors.foreColor["green"] };
        }
        let colorKeys = Object.keys(SyncColors.names)
        if (value < colorKeys.length) {
            let color = colorKeys[value]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color], foreColor: SyncColors.foreColor[color] };
        }

        if (value >= colorKeys.length) {
            var i = value;
            do {
                i = (i % colorKeys.length)
            } while (i > colorKeys.length)

            let color = colorKeys[i]
            return { name: color, rgb: SyncColors.names[color], hex: SyncColors.names[color], foreColor: SyncColors.foreColor[color] };
        }

        return { name: "red", rgb: SyncColors.names.red, hex: SyncColors.names["red"], foreColor: SyncColors.foreColor["red"] };
    }
}

export function getlength(number) {
    return number.toString().length;
}

export function hashCode(s) {
    for (var i = 0, h = 0; i < s.length; i++)
        h = Math.imul(31, h) + s.charCodeAt(i) | 0;
    return h;
}

export function addDays(date, days) {
    const copy = new Date(Number(date))
    copy.setDate(date.getDate() + days)
    return copy
}


export function copyToClipboard(text) {
    var dummy = document.createElement("textarea");
    //dummy.style.display = 'none';
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
}

function toCamelCase(str) {
    return str.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });
};

String.prototype.toCamelCase = function () {
    return this.replace(/^([A-Z])|\s(\w)/g, function (match, p1, p2, offset) {
        if (p2) return p2.toUpperCase();
        return p1.toLowerCase();
    });
};

export function toPascalCase(string) {
    return `${string}`
        .replace(new RegExp(/[-_]+/, 'g'), ' ')
        .replace(new RegExp(/[^\w\s]/, 'g'), '')
        .replace(
            new RegExp(/\s+(.)(\w+)/, 'g'),
            ($1, $2, $3) => `${$2.toUpperCase() + $3.toLowerCase()}`
        )
        .replace(new RegExp(/\s/, 'g'), '')
        .replace(new RegExp(/\w/), s => s.toUpperCase());
}

export function toCamelkeys(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
      return o.map(function(value) {
          if (typeof value === "object") {
            value = toCamelkeys(value)
          }
          return value
      })
    } else {
      newO = {}
      for (origKey in o) {
        if (o.hasOwnProperty(origKey)) {
          newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
          value = o[origKey]
          if (value instanceof Array || (value !== null && value.constructor === Object)) {
            value = toCamelkeys(value)
          }
          newO[newKey] = value
        }
      }
    }
    return newO
  }

  export function camelizeKeys(obj) {
    if (Array.isArray(obj)) {
      return obj.map(v => camelizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [toCamelCase(key)]: camelizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };

  export function pascalizeKeys(obj) {
    if (Array.isArray(obj)) {
      return obj.map(v =>  pascalizeKeys(v));
    } else if (obj != null && obj.constructor === Object) {
      return Object.keys(obj).reduce(
        (result, key) => ({
          ...result,
          [toPascalCase(key)]:  pascalizeKeys(obj[key]),
        }),
        {},
      );
    }
    return obj;
  };

export function breakUpString(str) {
    if (isNullOrEmpty(str)) {
        return str
    }
    const result = str.split(/(?=[A-Z])/);
  return result.join(" ")
    // return str.replace(new RegExp(/(?=[A-Z])/),
    // " ")
};

export function isNullOrEmpty(value) {
    return (!value);
}

export function parseBool(v) { return v === "false" || v === "False" || v === "null" || v === "NaN" || v === "undefined" || v === "0" ? false : !!v; }

// Object.prototype.isNullOrEmpty = function(value){
//     return (!value);
// }

export function retry(fn, retriesLeft = 5, interval = 1000) {
    return new Promise((resolve, reject) => {
        // console.log("Retrying")
        fn()
            .then(resolve)
            .catch((error) => {
                setTimeout(() => {
                    if (retriesLeft === 1) {
                        console.log("Retrying maximum retries exceeded")
                        // reject('maximum retries exceeded');
                        reject(error);
                        return;
                    }

                    // Passing on "reject" is the important part
                    retry(fn, retriesLeft - 1, interval).then(resolve, reject);
                }, interval);
            });
    });
}


export function download(dataurl, filename) {
    var a = document.createElement("a");
    a.href = dataurl;
    a.setAttribute("download", filename);
    a.click();
    return false;
}

export function downloadFile(url, filename) {
    fetch(url).then(function (t) {
        return t.blob().then((b) => {
            var a = document.createElement("a");
            a.href = URL.createObjectURL(b);
            a.setAttribute("download", filename);
            a.click();
        }
        );
    });
}

export const downloadDataFile = ({ data, fileName, fileType }) => {
    const blob = new Blob([data], { type: fileType });
  
    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  export function download_file(content, fileName, mimeType) {
    var a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if (navigator.msSaveBlob) { // IE10
        navigator.msSaveBlob(new Blob([content], {
            type: mimeType
        }), fileName);
    } else if (URL && 'download' in a) { //html5 A[download]
        a.href = URL.createObjectURL(new Blob([content], {
            type: mimeType
        }));
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        // eslint-disable-next-line no-restricted-globals
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
} 

export function splitInteger(num, parts) {
    // Complete this function

    var val;
    var mod = num % parts;
    var retData = []
    if (mod == 0) {
        val = num / parts;
        retData = Array(parts).fill(val);
    } else {
        val = (num - mod) / parts;
        retData = Array(parts).fill(val);
        for (var i = 0; i < mod; i++) {
            retData[i] = retData[i] + 1;
        }
        retData.reverse()
    }

    return retData;

}

export function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1)); // random index from 0 to i

        // swap elements array[i] and array[j]
        // we use "destructuring assignment" syntax to achieve that
        // you'll find more details about that syntax in later chapters
        // same can be written as:
        // let t = array[i]; array[i] = array[j]; array[j] = t
        [array[i], array[j]] = [array[j], array[i]];
    }
}


export const httpHelpers = {
    // Main wrapper for Fetch API
    httpRequest: (url, method, payload, headers) => {
        // Configuration to accept json as a default
        const config = {
            method,
            headers: {
                'Content-Type': 'application/json'
            }
        };
        // method = post and payload, add it to the fetch request
        if (method.toLowerCase() === 'post' && payload && payload.length > 0) {
            config.body = JSON.stringify(payload);
        }
        // if custom headers need to be set for the specific request
        // override them here
        if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
            config.headers = headers;
        }
        return fetch(
            url,
            config
        ).then((response) => {

            // Check if the request is 200
            if (response.ok) {
                let data = response;

                // if the type is json return, interpret it as json
                if (response.headers.get('Content-Type').indexOf('application/json') > -1) {

                    data = response.json();
                }
                return data;
            }
            // if an errors, anything but 200 then reject with the actuall response
            return Promise.reject(response);
        });
    },
};


export function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = (performance && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function removeLeadingZero(value) {
    if (isNullOrEmpty(value)) {
        return ""
    }
    return value.replace(/^0+/, '')
}

export function hasLeadingZero(value) {
    if (isNullOrEmpty(value)) {
        return false
    }
    var matches = value.match(/^0+/g);
    if (matches === null) {
        return false
    }
    if (Array.isArray(matches)) {
        return matches.length > 0
    }
    return false
}

export function obscureEmail(emailString) {
    if (isNullOrEmpty(emailString)) {
        return emailString
    }
    var splitEmail = emailString.split("@")
    var domain = splitEmail[1];
    var name = splitEmail[0];
    return name.substring(0, 3).concat("*********@").concat(domain)
}

export function obscureEmailSameLength(emailString) {
    if (isNullOrEmpty(emailString)) {
        return emailString
    }
    var splitEmail = emailString.split("@")
    var domain = splitEmail[1];
    var name = splitEmail[0];
    return name.substring(0, 3).concat(Array(name.length - 3).join("*")).concat("@").concat(domain)
}

export function obscurePhoneNumber(phoneNumber) {
    if (isNullOrEmpty(phoneNumber)) {
        return phoneNumber
    }

    var last4Digits = phoneNumber.substring(phoneNumber.length - 4)

    return (Array(phoneNumber.length - 4).join("*")).concat(last4Digits)
}

export const findHeaderByUuid = (uuid) =>
    document.querySelector(`[data-header-key='${uuid}-header']`);

export const findRowByUuidAndKey = (uuid, key) =>
    document.querySelector(`[data-row-key='${uuid}-${key}']`);


export function isEven(x) { return (x % 2) == 0; }
export function isOdd(x) { return !isEven(x); }

export function isNumeric(str) {
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
        !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

/**
   * Tells if a given input is a number
   * @method
   * @memberof SyncUtils
   * @param {*} input to check
   * @return {Boolean}
   */
export function isNumber(n) {
    return n !== '' && !isNaN(parseFloat(n)) && isFinite(n);
  }

export function isLikelyPhoneNumber(value) {
    var regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,8}$/im;
    return regex.test(value);
}

export function pagination(length, currentPage, itemsPerPage) {
    return {
        total: length,
        per_page: itemsPerPage,
        current_page: currentPage,
        last_page: Math.ceil(length / itemsPerPage),
        from: ((currentPage - 1) * itemsPerPage) + 1,
        to: currentPage * itemsPerPage
    };
};

export function checkInput(input, words) {
    return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
}


export function checkInputRegex(input, words) {
    return words.some(word => new RegExp(word, "i").test(input));
}

export function checkInputArray(input, words) {
    var result = []
    for (let index = 0; index < words.length; index++) {
        if (input.toLowerCase().indexOf(words[index].toLowerCase()) >= 0) {
            result.push(words[index])
        }
    }
    return result;
    //return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
}

export function checkInputStartArray(input, words) {
    var result = []
    for (let index = 0; index < words.length; index++) {
        if (input.toLowerCase().startsWith(words[index].toLowerCase())) {
            result.push(words[index])
        }
    }
    return result;
    //return words.some(word => input.toLowerCase().includes(word.toLowerCase()));
}

export const replaceTabWithSpace = (str, numSpaces = 4) => str.replaceAll('\t', ' '.repeat(numSpaces));

export const removeWhiteSpace = (str) => {
    if (isNullOrEmpty(str)) {
        return ""
    }
    return str.replace(/\s/g, "")
};;

export function removeDoubleSpaces(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    return str.replace(/ +(?= )/g,'');;
}
  

export function countWords(str) {
    const arr = str.split(' ');
  
    return arr.filter(word => word !== '').length;
  }

  export function wordsInString(str) {
    var result = [];
    const arr = str.split(' ');
    if (Array.isArray(arr)){
        return arr.filter(word => word !== '');
    }
    return result;
  }

  export function firstLetter(word) {
    return word[0];
 };

//  export function getAcronym(str){
//     var words = str.split(" "); // ["for","your","information"]
//     if (words.length > 1){
//         var acr = words.map(firstLetter); // ["f","y","i"]
//         return acr.join("").toUpperCase();
//     }

//     var breakstring = breakUpString(str);
//     console.log(breakstring);
//     words = breakstring.split(" ");
//     var acr2 = words.map(firstLetter); // ["f","y","i"]
//     return acr2.join("").toUpperCase();
  
//   };

// Same as stringCapitalLetters function
export function getAcronym(str) {
    if (isNullOrEmpty(str)) {
        return ""
    }
    // var matches = str.match(/\b(\w)/g); // ['J','S','O','N']
    var matches = str.match(/[A-Z]+[^A-Z]*|[^A-Z]+/g);
    var spacedWords = matches.join(' '); // JSON
    return stringFirstLetters(spacedWords);
}

/**
 * Splits a Pascal-Case word into individual words separated by spaces. 
 * @param {Object} word
 * @returns {String}
 */
export function splitPascalCase(word) {
	var wordRe = /($[a-z])|[A-Z][^A-Z]+/g;
	return word.match(wordRe).join(" ");
}

/**
 * Splits a camelCase or PascalCase word into individual words separated by spaces. 
 * @param {Object} word
 * @returns {String}
 */
export function splitCamelCase(word) {
	var output, i, l, capRe = /[A-Z]/;
	if (typeof(word) !== "string") {
		throw new Error("The \"word\" parameter must be a string.");
	}
	output = [];
	for (i = 0, l = word.length; i < l; i += 1) {
		if (i === 0) {
			output.push(word[i].toUpperCase());
		}
		else {
			if (i > 0 && capRe.test(word[i])) {
				output.push(" ");
			}
			output.push(word[i]);
		}
	}
	return output.join("");
}

/** Splits a camel-case or Pascal-case variable name into individual words.
 * @param {string} s
 * @returns {string[]}
 */
export function splitWords(s) {
	var re, match, output = [];
	// re = /[A-Z]?[a-z]+/g
	re = /([A-Za-z]?)([a-z]+)/g;

	/*
	matches example: "oneTwoThree"
	["one", "o", "ne"]
	["Two", "T", "wo"]
	["Three", "T", "hree"]
	*/

	match = re.exec(s);
	while (match) {
		// output.push(match.join(""));
		output.push([match[1].toUpperCase(), match[2]].join(""));
		match = re.exec(s);
	}

	return output;

}

  export function acr(s){
    var words, acronym, nextWord;

    words = s.split(' ');
    acronym= "";
    var index = 0
    while (index<words.length) {
            nextWord = words[index];
            acronym = acronym + nextWord.charAt(0);
            index = index + 1 ;
    }
    return acronym
}

export const buildAcronym = (str = '') => {
    const strArr = str.split(' ');
    let res = '';
    strArr.forEach(el => {
       const [char] = el;
       if(char === char.toUpperCase() && char !== char.toLowerCase()){
          res += char;
       };
    });
    return res;
 };

 export function numberWithCommas(x) {
    if (isNullOrEmpty(x)) {
      if (x === undefined){
        return  "";
      }
      if (x.toString() !== "0") {
        return "";
      }
    }
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  
  export function numberToAmount(value, fixed = 2) {
    // const theValue = parseInt(value, 10);
    if (fixed <= 0) {
      return value.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return value.toFixed(fixed).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  }

  export function truncateString(str, num = 20) {
    // If the length of str is less than or equal to num
    // just return str--don't truncate it.
    if (str.length <= num) {
      return str
    }
    // Return str truncated with '...' concatenated to the end of str.
    return str.slice(0, num) + '...'
  }

  export function calcPercentage(num, total, fixed = 2) {
    const percent = (num / total) * 100;
    
    if(!isNaN(percent)){
      return Number(percent.toFixed(fixed));
    }else{
      return null;
    }
    
  }

  export function getInitials(name, { maxInitials }) {
    return name.split(/\s/)
        .map(part => part.substring(0, 1).toUpperCase())
        .filter(v => !!v)
        .slice(0, maxInitials)
        .join('')
        .toUpperCase();
}

export const truncateFromMiddle = (fullStr, strLen, separator = '...') => {
    if (fullStr.length <= strLen) return fullStr;
    const sepLen = separator.length;
    const charsToShow = strLen - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);
    return (
      fullStr.substr(0, frontChars) +
      separator +
      fullStr.substr(fullStr.length - backChars)
    );
  };

 export  function findArrayDuplicates(arr, freq = 2) {


    // Sort the array so that repeating elements can be tracked
    arr.sort();
    let n = arr.length;
    var result = [];

    // Duplicates indexing starts from Zero
    // Used this to resolve normal numbering to zero-based indexing
    if (freq <= 2){
        freq = 0;
    }
    if (freq > 2){
        freq = (freq - 2);
    }
   
    // Initialize index = 0
    let i = 0;
    while (i < n) {
      let first_index = i;
   
      // Initialize last_index to i plus the frequency
    //   console.log( arr.filter(x => x === arr[i]).length - 1, "Frequency")
      let last_index = i + arr.filter(x => x === arr[i]).length - 1;
   
      // If frequency of current element is more than 1, then print it
      if (last_index - first_index > freq ) {
        result.push(arr[i])
        // console.log(arr[i]);
      }
   
      // Update index to last index plus 1
      i = last_index + 1;
    }

    return result;
  }

  export function toFindDuplicates(arry) {
    const uniqueElements = new Set(arry);
    const filteredElements = arry.filter(item => {
        if (uniqueElements.has(item)) {
            uniqueElements.delete(item);
        } else {
            return item;
        }
    });

    return [...new Set(uniqueElements)]
}

export function getArrayAvg(array) {
    if (Array.isArray(array)){
        const total = array.reduce((acc, c) => acc + c, 0);
        return total / array.length;
    }
    return 0;
  }

  export function getArrayMax(array) {
    if (Array.isArray(array)){
        const max = array.reduce((acc, c) => Math.max(acc, c), -Infinity);
        return max;
    }
    return 0;
  }

  export function getArrayMin(array) {
    if (Array.isArray(array)){
        const min = array.reduce((acc, c) => Math.min(acc, c), -Infinity);
        return min;
    }
    return 0;
  }

  export function getMaxArray(array) {
    if (Array.isArray(array)){
        let max = array[0];
        for (let i = 1; i < array.Length; ++i) {
            if (array[i] > max) {
              max = array[i];
            }
          }
        return max;
    }
    return 0;
  }


 /** */
  export function getMinArray(array) {
    if (Array.isArray(array)){
        let min = array[0];
        for (let i = 1; i < array.Length; ++i) {
            if (array[i] < min) {
              min = array[i];
            }
          }
        return min;
    }
    return 0;
  }

