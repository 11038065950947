import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { useField, useFormikContext } from "formik";
import moment from 'moment'
import {
  CLabel, CFormGroup, CRow, CCol
} from '@coreui/react'

const SyncReactDatesSingleDatePicker = ({ ...props }) => {

  const { setFieldValue, values, errors, touched } = useFormikContext();
  const [field] = useField(props);
  const { isRequired = false, label, showLabel = false, maxWidth = 0 } = props
  let currentValue = values[field.name];
  const [focused, setFocused] = useState(false);

  return (
    <CFormGroup>
      {showLabel ? <CLabel style={{ width: "100%" }} for={props.id || props.name} className={"label-color"}>{label}</CLabel> : ""}
      
      <CRow>
        <CCol style={maxWidth <= 0 ? {paddingRight: "0px"} : {paddingRight: "0px", maxWidth: maxWidth + 35}} xs={`${currentValue === "" && isRequired ? "11" : "12"}`}>
        <SingleDatePicker
            {...field}
            {...props}
          
            numberOfMonths={1}
            onDateChange={val => {
              setFieldValue(field.name, val);
            }}
            onFocusChange={({ focused }) => setFocused(focused)}
            focused={focused}
            date={(field.value && moment(field.value)) || null}
            required={isRequired}
            displayFormat="YYYY-MM-DD"
            //   isDayBlocked={m => m.day() === 6 || m.day() === 0}
            hideKeyboardShortcutsPanel
            //   maxDate={moment().add(1, 'months').endOf('month')}
            // withPortal
            withFullScreenPortal={window.innerWidth < 400}

          />
         
        </CCol >
        {currentValue === "" && isRequired && <CCol className="nopadding" xs="1"> <span className="text-danger">&nbsp;*</span> </CCol> }
      </CRow>
     

      {/* <label>{isRequired && <span className="text-danger">&nbsp;*</span>}</label> */}

      {touched[field.name] && errors[field.name] && (
        <div className="error text-danger">&nbsp;{errors[field.name]}</div>
      )}
    </CFormGroup>
  );
}

export default SyncReactDatesSingleDatePicker
