import React from "react";
import InfiniteLoader from 'react-window-infinite-loader';

/**
 * Implementing react-window-infinite-loader ExampleWrapper.
 */


const InfiniteLoaderWrapper = ({ hasNextPage, isNextPageLoading, minimumBatchSize, totalItems, loadNextPage, ref, children }) => {

    // If there are more items to be loaded then add an extra row to hold a loading indicator.
    const itemCount = hasNextPage ? minimumBatchSize ? totalItems + minimumBatchSize : totalItems + 10 : totalItems;

    // Only load 1 page of items at a time.
    // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
    const loadMoreItems = isNextPageLoading  ? () => null : loadNextPage === undefined ? () => null : loadNextPage

    // Every row is loaded except for our loading indicator row.
    const isItemLoaded = (index) => {
     return !hasNextPage || index < totalItems;
    }

    
    //console.log("Prospect List LoadMoreItems", loadMoreItems, itemCount, hasNextPage, isNextPageLoading)
    return (
        <InfiniteLoader
        ref={ref}
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      minimumBatchSize={minimumBatchSize}
    >
      {({onItemsRendered, ref}) => (
          children({
            ref: ref,
            onItemsRendered
          })
        
      )}
    </InfiniteLoader>
    );

}

export default InfiniteLoaderWrapper;