export function regionFrom(lat, lon, accuracy) {
    const oneDegreeOfLongitudeInMeters = 111.32 * 1000;
    const circumference = (40075 / 360) * 1000;
  
    const latDelta = accuracy * (1 / (Math.cos(lat) * circumference));
    const lonDelta = (accuracy / oneDegreeOfLongitudeInMeters);
  
    return {
      latitude: lat,
      longitude: lon,
      latitudeDelta: Math.max(0, latDelta),
      longitudeDelta: Math.max(0, lonDelta)
    };
  }
  
  
  export function getLatLonDiffInMeters(lat1, lon1, lat2, lon2) {
    var R = 6371; // radius of the earth in km
    var dLat = deg2rad(lat2-lat1); // deg2rad below
    var dLon = deg2rad(lon2-lon1); 
    var a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) * 
      Math.sin(dLon/2) * Math.sin(dLon/2)
      ; 
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
    var d = R * c; // distance in km
    return d * 1000;
  }
  
  
  function deg2rad(deg) {
    return deg * (Math.PI/180)
  }

  export function getGoogleLocationCoordinates(placeDetails)
  {
      var result = {
          latitude: 0,
        longitude: 0,
        latitudeDelta: 0,
        longitudeDelta: 0,
      }
      if (placeDetails)
      {
        if (placeDetails.geometry)
        {
          if (placeDetails.geometry.viewport.northeast)
          {
            result.latitudeDelta = Number(placeDetails.geometry.viewport.northeast.lat) - Number(placeDetails.geometry.viewport.southwest.lat) ?? 0
            result.longitudeDelta = Number(placeDetails.geometry.viewport.northeast.lng) - Number(placeDetails.geometry.viewport.southwest.lng) ?? 0
          }

          result.latitude = placeDetails.geometry.location.lat();
          result.longitude = placeDetails.geometry.location.lng();
  
        }
         
       
       
      }

      return result;
  }


  export function getGoogleLocationDetails(placeDetails)
  {
    var result = {
      country: "",
      city: "",
      region: "",
      countryCode: "", 
      locality: "",
      route: ""
    }
    for (var i = 0; i < placeDetails.address_components.length; i++) {
      var addressType = placeDetails.address_components[i].types[0];
     
      if (addressType === "country") {
        //console.log(placeDetails.address_components[i].long_name)
        result.country = placeDetails.address_components[i].long_name;
        result.countryCode = placeDetails.address_components[i].short_name;
      }
      if (addressType === "administrative_area_level_1") {
        //console.log(placeDetails.address_components[i].long_name)
        result.region = placeDetails.address_components[i].long_name;
      }

      if (addressType === "locality") {
        //console.log(placeDetails.address_components[i].long_name)
        result.city = placeDetails.address_components[i].long_name;
        result.locality = placeDetails.address_components[i].long_name;
      }

      if (addressType === "route") {
        result.route = placeDetails.address_components[i].long_name;
      }

    }
    
    return result;
  }