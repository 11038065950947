import React, { useState } from 'react';
import { FormFeedback, Button, Input, InputGroup, InputGroupAddon, InputGroupText, Label, FormGroup } from 'reactstrap';


function PlainTextInput({ label, iconOrText, invalid = false, valid = true,
    // form: { touched, errors, setFieldValue, values },
    ...props }) {


    // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
    // which we can spread on <input> and also replace ErrorMessage entirely.
    const [borderBottomColor, setBorderBottomColor] = useState('gainsboro')
   // const [field] = useField(props);
   // const { values, errors, touched} = useFormikContext();
    const { onAppendClick, isRequired = false, showAppend = false, appendIconOrText = '', showLabel = false, appendStyle = {}, appendClass = "" } = props;
    const icon = iconOrText ?? ""
    //let currentValue = values[field.name];
    let currentValue = "";
  //  let error = errors[field.name];
    //  console.log(isRequired, field.name, "Input", errors, touched)
    //  console.log(field, "Field Information")
    const handleFocus = () => {
        setBorderBottomColor('green')
    }

    const handleBlur = () => {
        setBorderBottomColor('gainsboro')
    }
    // console.log(props)
    return (

        <div className="mb-3">
            <FormGroup>
            {showLabel ? <Label for={props.id || props.name} className={props.labelColor}>{label}</Label> : ""}
                <InputGroup >
                    {/* <label htmlFor={props.id || props.name}>{label}</label> */}
                   
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            {icon}
                        </InputGroupText>
                    </InputGroupAddon>
                    {/* <input type="text"  {...field} {...props} /> */}
                    <Input type="text"  {...props} 
                    // invalid={Boolean(touched[field.name] && errors[field.name])}
                    readonly={props.readonly}
                    invalid={invalid} 
                    valid={valid}
                    // {...(touched[field.name]  && errors[field.name]
                    //     ? { isInvalid: true, className: "is-invalid" }
                    //     : props.isRequired ? { isValid: true, className: "is-valid"} : {isValid: true})}
                    />
                    {showAppend && !props.disableAppend && <InputGroupAddon addonType="append"><Button className={appendClass} style={appendStyle} onClick={onAppendClick} >{appendIconOrText}</Button></InputGroupAddon>}
                    <label>{currentValue === "" && isRequired && <span className="text-danger">&nbsp;*</span>}</label>


                </InputGroup>
                {/* {touched[field.name] && errors[field.name] ? (
                    <div className="error text-danger">&nbsp;{errors[field.name]}</div>
                ) : null} */}
                 {/* {touched[field.name] && errors[field.name] && (
                <div className="error text-danger">&nbsp;{errors[field.name]}</div>
            )} */}

{/* {error && <FormFeedback>{error}</FormFeedback>} */}

            </FormGroup>
        </div>

    );
};

// SyncTextInput.propTypes = propTypes;
// SyncTextInput.defaultProps = defaultProps;

export default PlainTextInput