import { createSlice } from '@reduxjs/toolkit'
import { array_move, isEmpty } from '../../utils/SyncUtils'


export const initialState = {

    updatedProspects: [],
    lastRoute: "",

};

const nonPersistUpdatesSlice = createSlice({
    name: 'nonPersistUpdates',
    initialState,
    reducers: {


        addUpdatedProspect: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                let amExisting = state.updatedProspects.findIndex((element) => {
                    return element.guid.toLowerCase() === payload.guid.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.updatedProspects[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    state.updatedProspects.unshift(payload)
                }


            }
           
        },

        clearUpdatedProspects: (state) => {

            return { ...state, updatedProspects: [] }
        },

        setUpdatedProspects: (state, { payload }) => {

            return { ...state, updatedProspects: payload }
        },
        setLastRoute: (state,  { payload }) => {
            return { ...state, lastRoute : payload  }
        },

    }

})

export const { addUpdatedProspect, clearUpdatedProspects, setUpdatedProspects,setLastRoute } = nonPersistUpdatesSlice.actions;

// A selector
export const nonPersistSelector = state => state.nonPersistUpdate

// The reducer
export default nonPersistUpdatesSlice.reducer