import React, { useEffect, useState, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "../utils/SyncUtils";
import { createYupSchema } from "../utils/yupSchemaCreator";
import DynamicInput from "./DynamicInput";
import { Formik } from "formik";
import { CCol, CRow } from "@coreui/react";
import * as yup from "yup";
import { useMediaQuery } from "react-responsive";
import moment from "moment";
import { LoadingFullPageSpinner } from '../components/UtilWidgets'
import {DynamicFormValue} from "./"
// import propTypes from "prop-types";

/** */
function DynamicFormDisplay({ definition, onSubmitSuccess, submitButtonText, reloadDefinition,  resetButtonText,
   showResetButton, ...props }) {
  const formikRef = useRef(null);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const [initialValues] = useState(() => {
    const result = {};
    if (!isEmpty(definition)) {
      Object.keys(definition).forEach((e) => {
        const { defaultValue, name } = definition[e];
        result[name] = defaultValue || "";
      });
    }
    return result;
  });

  const [resetForm, setResetForm] = useState(false)

  const [schema] = useState(() => {
    var definitionArray = Object.keys(definition).map((e) => {
      return definition[e];
    });
    // console.log(definitionArray)
    const yupSchema = definitionArray
      .filter((x) => x.validations.length > 0 && x.visible === true)
      .reduce(createYupSchema, {});
    // console.log(yupSchema, "Yup Schema");
    const validateSchema = yup.object().shape(yupSchema);
    // console.log(validateSchema, "Validate Schema");
    return validateSchema;
  });

  useEffect(() => {
    if (!isEmpty(definition)) {
      if (formikRef.current !== null) {
        Object.keys(definition).forEach((e) => {
          const { name, required, visible } = definition[e];
          if (required && visible) {
            formikRef.current.setFieldTouched(name, true);
          }
        });
      }
    } else {
      reloadDefinition();
    }
    
  }, []);

  // const formInputs = Object.keys(definition).map((e) => {
  //   console.log("Form Inputs")
  //   return (
  //     <CRow key={e}>
  //       <CCol md="12" lg="12" xl="12" sm="12" key={e}>
  //         <DynamicInput {...definition[e]}></DynamicInput>
  //       </CCol>
  //     </CRow>
  //   );
  // });

  const moreInputs = () => {
    // console.log("More Inputs")
    var result = Object.keys(definition).map((e) => {
      const { visible } = definition[e];
      if (visible){
        var moreValues = {defaultValue: DynamicFormValue(definition[e], definition[e].defaultValue)}
        return (
          <CRow key={e}>
            <CCol md="12" lg="12" xl="12" sm="12" key={e}>
              <DynamicInput reset={resetForm} {...definition[e]} {...moreValues}></DynamicInput>
            </CCol>
          </CRow>
        );
      } else {
       return ( <Fragment></Fragment>)
      }
     
    });

    return result;
  };

  // const callbackInputs = useCallback(() => {
  //   console.log("Callback Inputs")
  //   var result = Object.keys(definition).map((e) => {
  //     return (
  //       <CRow key={e}>
  //         <CCol md="12" lg="12" xl="12" sm="12" key={e}>
  //           <DynamicInput {...definition[e]}></DynamicInput>
  //         </CCol>
  //       </CRow>
  //     );
  // })
  // return result;
  // }, [definition]);

  const handleSubmit = (values, callback) => {
    //console.log(values, "This is submit Info")
   // var resultValues = JSON.parse(JSON.stringify(values))
   var resultValues = {...values};

   /// Converting Multiselect Result form Array of Objects to Array of string
    if (!isMobile){
      Object.keys(definition)
      .map((e) => {
        return definition[e];
      })
      .filter((x) => x.htmlType === "multiselect" && x.visible === true).map(u => u.name).forEach(v => {
        if (values.hasOwnProperty(v)){
          if (Array.isArray(values[v])) {
            var item = values[v].map(x => x.value);
            resultValues[v] = item;
          } else {
            resultValues[v] = []
          }
         
        }
      });
    }

    ///Converting Date
    Object.keys(definition)
      .map((e) => {
        return definition[e];
      })
      .filter((x) => x.htmlType === "date" && x.visible === true).map(u => u.name).forEach(v => {
        if (values.hasOwnProperty(v)){
          
          var convertedDate = moment(values[v]).utc().format();
         // console.log(values[v], "Original Date")
         // console.log(convertedDate, "Converted Date")
        //  console.log(moment(values[v]).utc().format(), "UTC Date")
          resultValues[v] = convertedDate;
        } else {
          console.log("No owned property")
        }
      });
   
    if (onSubmitSuccess) {
      if (callback) {
        onSubmitSuccess(resultValues, callback);
      } else {
        onSubmitSuccess(resultValues);
      }
    }
  };

  if (isEmpty(definition)){
    return (<div>
<LoadingFullPageSpinner message="Error occured while loading Form Definition"></LoadingFullPageSpinner>
    </div>)
  }

  return (
    <div>
      <Formik
        enableReinitialize
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={(values, { setSubmitting, setErrors, resetForm }) => {
          setSubmitting(true);
          handleSubmit(values, (success) => {
            setSubmitting(false);
            // console.log("Callback Called");
            if (success) {
              setResetForm(true);
               resetForm({});
               resetForm({values: ""});
               if (formikRef.current !== null) {
                Object.keys(definition).forEach((e) => {
                  const { name, required } = definition[e];
                  if (required) {
                    formikRef.current.setFieldTouched(name, true);
                  }
                });

                setResetForm(false);
              }
            }
          });
        }}
      >
        {(formikProps) => (
          <form onSubmit={formikProps.handleSubmit} onReset={formikProps.handleReset} {...formikProps}>
            {moreInputs()}
            {/* {formInputs} */}
            {/* {callbackInputs()} */}
            <div className="mt-4">
              <button
                type="submit"
                className="btn btn-success ml-auto"
                disabled={
                  !(formikProps.isValid && formikProps.dirty) ||
                  formikProps.isSubmitting
                }
              >
                {submitButtonText}
              </button>
              {/* <button
                type="submit"
                className="btn btn-success ml-auto"
               
              >
                Submit
              </button> */}
              {showResetButton &&  <button type="reset" className="btn btn-dark float-right">
              {resetButtonText}
              </button>}
             
            </div>
            {/* <pre>{JSON.stringify(formikProps, null, 2)}</pre> */}
            {/* <pre>{JSON.stringify(touched, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
            <pre>{JSON.stringify(dirty, null, 2)}</pre>
            <pre>{JSON.stringify(isValid, null, 2)}</pre> */}
          </form>
        )}
      </Formik>
    </div>
  );
}
DynamicFormDisplay.propTypes = {
  // tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  // notification: PropTypes.object,
  definition: PropTypes.object.isRequired,
  onSubmitSuccess: PropTypes.func.isRequired,
  reloadDefinition: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
  resetButtonText: PropTypes.string.isRequired,
  showResetButton: PropTypes.bool,
};

DynamicFormDisplay.defaultProps = {
  definition: {},
  submitButtonText: "Submit",
  resetButtonText: "Reset",
  showResetButton: true
};

export default DynamicFormDisplay;
