import store from '../app/store'
import { isEmpty } from './SyncUtils'
import moment from 'moment'


/** */
const optionTypes = [
  { value: 'ContactMode', label: 'ContactMode' },
  { value: 'Gender', label: 'Gender' },
  { value: 'MaritalStatus', label: 'Marital Status' },
  { value: 'ProspectStatus', label: 'Prospect Status' },
  { value: 'SpiritualCondition', label: 'Spiritual Condition' },
]

const maritalStatus = [
    { value: 'None', label: 'None' },
    { value: 'Single', label: 'Single' },
    { value: 'Married', label: 'Married' },
    { value: 'Separated', label: 'Separated' },
    { value: 'Divorced', label: 'Divorced' },
    { value: 'Widowed', label: 'Widowed' },
  ]

const groupType = [
    { value: 'Lite', label: 'Lite (1 - 5)' },
    { value: 'Small', label: 'Small (5 - 20)' },
    { value: 'Medium', label: 'Medium (20 - 50)' },
    { value: 'Large', label: 'Large (50 - 200)' },
    { value: 'Congregation', label: 'Congregation (200 & above)' },
]  

const gender = [
    { value: 'Male', label: 'Male' },
    { value: 'Female', label: 'Female' },
  ]

const spiritualCondition = [
    { value: 'Baptism', label: 'Baptism' },
    { value: 'Saved', label: 'Saved' },
    { value: 'Attended', label: 'Attended' },
    { value: 'Member', label: 'Member' },
    { value: 'Sunday School', label: 'Sunday School' },
  ]

const prospectStatus = [
    { value: 'Inactive', label: 'Inactive' },
    { value: 'Active', label: 'Active' },
    { value: 'DeadEnd', label: 'Dead End' },
    { value: 'Added', label: 'Added' },
    { value: 'Rooted', label: 'Rooted' },
  ]

const prospectOwner = [
    { value: 'SelfOnly', label: 'Self Only' },
    { value: 'SelfAndGroup', label: 'Self and Group' },
    { value: 'GroupOnly', label: 'Group Only' },
  ]

const prospectBuckets = [
    { value: 'Default', label: 'Default' },
  ]

const prospectInvite = [
    {value: 'None', label: 'None'},
    { value: 'OpenInvite', label: 'Open Invite' },
    { value: 'UnConfirmedInvite', label: 'Invite (UnConfirmed)' },
    { value: 'ConfirmedInvite', label: 'Invite (Confirmed)' },
  ]  

  const prospectInviteStatus = [
    {value: 'None', label: 'Awaiting Update'},
    { value: 'Show', label: 'Show' },
    { value: 'NoShow', label: 'No-Show' },
  ]  

const prospectSortingMode = [
    { value: 'LabelNewest', label: 'Label and Newest' },
    { value: 'Newest', label: 'Newest' },
    { value: 'Oldest', label: 'Oldest' },
    { value: 'Alphabetically', label: 'Alphabetically' },
    { value: 'RecentlyFollowedUp', label: 'Recently Follow-up' },
    { value: 'MostFollowedUp', label: 'Most Follow-up' },
    { value: 'LeastFollowedUp', label: 'Least Followed-up' },
  ]  


  const prospectToShow = [
    { value: 'All', label: 'All' },
    { value: 'Callbacks', label: 'Awaiting Call-back' },
    { value: 'CallUpdates', label: 'Awaiting Call Update' },
  ]  

  const usersListSortingMode = [
    { value: 'LabelNewest', label: 'Label and Newest' },
    { value: 'LabelAlphabetically', label: 'Label and Alphabetically' },
    { value: 'Newest', label: 'Newest' },
    { value: 'Oldest', label: 'Oldest' },
    { value: 'Alphabetically', label: 'Alphabetically' },
  ] 
  
  const prospectSearchTypes = [
    { value: 'Latest', label: 'Latest', start: moment().subtract(29, 'days').format('YYYY-MM-DD'), end:moment().add(7, "days").format('YYYY-MM-DD'), count: 37, },
    { value: 'All', label: 'All', start: '', end:'', count: 0, },
    { value: 'Today', label: 'Today', start: moment().format('YYYY-MM-DD'), end:moment().format('YYYY-MM-DD'), count: 0, },
    { value: 'SpecificDay', label: 'Specific Day', start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end:moment().subtract(1, 'days').format('YYYY-MM-DD'), count: 0, },
    // { value: 'Yesterday', label: 'Yesterday', start: moment().subtract(1, 'days').format('YYYY-MM-DD'), end:moment().subtract(1, 'days').format('YYYY-MM-DD'), count: 0, },
    { value: 'Last7Days', label: 'Last 7 Days', start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end:moment().format('YYYY-MM-DD'), count: 7, },
    { value: 'SpecificWeek', label: 'Specific Week', start: moment().subtract(6, 'days').format('YYYY-MM-DD'), end:moment().format('YYYY-MM-DD'), count: 7, },
    { value: 'Last30days', label: 'Last 30 Days', start: moment().subtract(29, 'days').format('YYYY-MM-DD'), end:moment().format('YYYY-MM-DD'), count: 30, },
    { value: 'Next7Days', label: 'Next 7 Days', start: moment().add(1, "days").format('YYYY-MM-DD'), end:moment().add(8, "days").format('YYYY-MM-DD'), count: 8, },
    { value: 'Next30Days', label: 'Next 30 Days', start: moment().add(1, "days").format('YYYY-MM-DD'), end:moment().add(31, "days").format('YYYY-MM-DD'), count: 31, },
    { value: 'ThisMonth', label: 'This Month', start: moment().startOf('month').format('YYYY-MM-DD'), end: moment().endOf('month').format('YYYY-MM-DD'), count: 30, },
    { value: 'LastMonth', label: 'Last Month', start: moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD'), end:moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD'), count: 30, },
    { value: 'Last60days', label: 'Last 60 Days', start: moment().subtract(60, 'days').format('YYYY-MM-DD'), end:moment().format('YYYY-MM-DD'), count: 60, },
    { value: 'Custom', label: 'Custom', start: moment().subtract(29, 'days').format('YYYY-MM-DD'), end:moment().format('YYYY-MM-DD'), count: 30, },
  ]  

  const attendanceMeetingDisplayMode = [
    { value: 'MeetingMembersOnly', label: 'Registered Members at Meeting Date' },
    { value: 'AllPeople', label: 'All People' },
  ]  

const contactMode = [
    { value: 'DoorKnocking', label: 'Door Knocking' },
    { value: 'FirstTimer', label: 'First Timer' },
    { value: 'GospelPresentation', label: 'Gospel Presentation' },
    { value: 'Outreach', label: 'Outreach' },
    { value: 'Rally', label: 'Rally' },
  ]

const memberRoles = [
    { value: 'Member', label: 'Member' },
    { value: 'Admin', label: 'Admin' },
  ]

const bucketCreationType = [
    {value: 'Manual', label: 'Manual'},
    {value: 'Automatic', label: 'Automatic'},
    {value: 'Hybrid', label: 'Hybrid'},
  ]

const bucketNamingType = [
    {value: 'None', label: 'None'},
    {value: 'Day', label: 'Day'},
    {value: 'Week', label: 'Week'},
    {value: 'Month', label: 'Month'},
    {value: 'Year', label: 'Year'},
  ]

  const memberMessageType = [
    { value: 'Member', label: 'Member Only' },
    { value: 'Admin', label: 'Admin Only' },
    { value: 'All', label: 'All' },
  ]


const soulWinOptions = () =>
{

  // grab current state
  const state = store.getState();

  const sowinOptions = state.mycontainer.userSoulWinOptions
//console.log(state.mycontainer)
var mStatus = null, cMode = null, pStatus = null, pUpdate = null, pResponse = null, sConditon = null, 
sexGender = null, groupContainerType = null, serviceTypes = [], pAttendance = [], pInvite = [], positiveTags = [], 
negativeTags = [], groupLabels = [], unMappedGroupLabels = [], groupLabelTypes = [], reportTypes = [],
unMappedProspectStatus = [], prospectsTodos = [], todoAutoLabels = [];
  if (!isEmpty(sowinOptions))
  {
    mStatus = sowinOptions.MaritalStatus.map(x => {
      return {value: x.id, label: x.text}
    });

    cMode = sowinOptions.ContactMode.map(x => {
      return {value: x.id, label: x.text}
    });

   unMappedProspectStatus = sowinOptions.ProspectStatus;
    pStatus = sowinOptions.ProspectStatus.map(x => {
      return {value: x.id, label: x.text}
    });

   

    sConditon = sowinOptions.SpiritualCondition.map(x => {
      return {value: x.id, label: x.text}
    });

    sexGender = sowinOptions.Gender.map(x => {
      return {value: x.id, label: x.text}
    });

    groupContainerType = sowinOptions.GroupContainerType.map(x => {
      return {value: x.id, label: x.text}
    });

    if (sowinOptions.ProspectUpdate)
    {
      pUpdate = sowinOptions.ProspectUpdate.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectResponse)
    {
      pResponse = sowinOptions.ProspectResponse.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectAttendance)
    {
      pAttendance = sowinOptions.ProspectAttendance.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectInvite)
    {
      pInvite = sowinOptions.ProspectInvite.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ServiceTypes)
    {
      serviceTypes = sowinOptions.ServiceTypes.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.PositiveTags)
    {
      positiveTags = sowinOptions.PositiveTags.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.NegativeTags)
    {
      negativeTags = sowinOptions.NegativeTags.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.GroupLabels)
    {
        unMappedGroupLabels = sowinOptions.GroupLabels;
        groupLabels = sowinOptions.GroupLabels.map(x => {
          return {value: x.id, label: x.text}
        });
    }
    if (sowinOptions.GroupLabelTypes)
    {
      groupLabelTypes = sowinOptions.GroupLabelTypes;
    }
    if (sowinOptions.ReportTypes)
    {
      reportTypes = sowinOptions.ReportTypes.filter(x => {
        return x.description !== "group"
      });
    }
    if (sowinOptions.ProspectsTodos){
      prospectsTodos = sowinOptions.ProspectsTodos;
    }
    // if (sowinOptions.TodoAutoLabels){
    //   todoAutoLabels = sowinOptions.TodoAutoLabels;
    // }
  }
 

  const options = {
    maritalStatus: mStatus || maritalStatus,
    prospectStatus: pStatus || prospectStatus,
    unMappedProspectStatus: unMappedProspectStatus || [],
    gender: sexGender || gender,
    contactMode: cMode || contactMode,
    spiritualCondition: sConditon || spiritualCondition,
    groupContainerType: groupContainerType || groupType,
    prospectUpdate: pUpdate || [],
    prospectResponse: pResponse || [],
    prospectAttendance: pAttendance || [],
    prospectInvite: pInvite || prospectInvite,
    serviceTypes: serviceTypes || [],
    positiveTags : positiveTags || [],
    negativeTags : negativeTags || [],
    groupLabels : groupLabels || [],
    unMappedGroupLabels : unMappedGroupLabels || [],
    reportTypes,
    groupLabelTypes,
    prospectsTodos: prospectsTodos || [],
    todoAutoLabels: todoAutoLabels || []
  };
 // console.log(options)
  return options;
}  

const soulWinGroupOptions = () =>
{

  // grab current state
  const state = store.getState();

  const sowinOptions = state.groups.currentGroupSoulWinOptions
//console.log(state.mycontainer)
var mStatus = null, cMode = null, pStatus = null, pUpdate = null, pResponse = null, sConditon = null, 
sexGender = null, groupContainerType = null, serviceTypes = [], pAttendance = [], pInvite = [], positiveTags = [], 
negativeTags = [], groupLabels = [], unMappedGroupLabels = [], groupLabelTypes = [], reportTypes = [],
unMappedProspectStatus = [], prospectsTodos = [], todoAutoLabels = [];
  if (!isEmpty(sowinOptions))
  {
    mStatus = sowinOptions.MaritalStatus.map(x => {
      return {value: x.id, label: x.text}
    });

    cMode = sowinOptions.ContactMode.map(x => {
      return {value: x.id, label: x.text}
    });

   unMappedProspectStatus = sowinOptions.ProspectStatus;
    pStatus = sowinOptions.ProspectStatus.map(x => {
      return {value: x.id, label: x.text}
    });

    sConditon = sowinOptions.SpiritualCondition.map(x => {
      return {value: x.id, label: x.text}
    });

    sexGender = sowinOptions.Gender.map(x => {
      return {value: x.id, label: x.text}
    });

    groupContainerType = sowinOptions.GroupContainerType.map(x => {
      return {value: x.id, label: x.text}
    });

    if (sowinOptions.ProspectUpdate)
    {
      pUpdate = sowinOptions.ProspectUpdate.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectResponse)
    {
      pResponse = sowinOptions.ProspectResponse.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectAttendance)
    {
      pAttendance = sowinOptions.ProspectAttendance.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectInvite)
    {
      pInvite = sowinOptions.ProspectInvite.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ServiceTypes)
    {
      serviceTypes = sowinOptions.ServiceTypes.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.PositiveTags)
    {
      positiveTags = sowinOptions.PositiveTags.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.NegativeTags)
    {
      negativeTags = sowinOptions.NegativeTags.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.GroupLabels)
    {
      unMappedGroupLabels = sowinOptions.GroupLabels;
      groupLabels = sowinOptions.GroupLabels.map(x => {
        return {value: x.id, label: x.text}
      });
    }
    if (sowinOptions.GroupLabelTypes)
    {
      groupLabelTypes = sowinOptions.GroupLabelTypes;
    }

    if (sowinOptions.ReportTypes)
    {
      reportTypes = sowinOptions.ReportTypes.filter(x => {
        return x.description !== "personal"
      });
    }
    if (sowinOptions.ProspectsTodos){
      prospectsTodos = sowinOptions.ProspectsTodos.map(x => {
        return {value: x.id, label: x.text}
      });
    }
    if (sowinOptions.TodoAutoLabels){
      todoAutoLabels = sowinOptions.TodoAutoLabels.map(x => {
        return {value: x.id, label: `${x.text} (${x.description})` }
      });;
    }
  }
 

  const options = {
    maritalStatus: mStatus || maritalStatus,
    prospectStatus: pStatus || prospectStatus,
    unMappedProspectStatus: unMappedProspectStatus || [],
    gender: sexGender || gender,
    contactMode: cMode || contactMode,
    spiritualCondition: sConditon || spiritualCondition,
    groupContainerType: groupContainerType || groupType,
    prospectUpdate: pUpdate || [],
    prospectResponse: pResponse || [],
    prospectAttendance: pAttendance || [],
    prospectInvite : pInvite || prospectInvite,
    serviceTypes: serviceTypes || [],
    positiveTags : positiveTags || [],
    negativeTags : negativeTags || [],
    groupLabels : groupLabels || [],
    unMappedGroupLabels : unMappedGroupLabels || [],
    reportTypes,
    groupLabelTypes,
    prospectsTodos: prospectsTodos || [],
    todoAutoLabels: todoAutoLabels || []
  };
 // console.log(options)
  return options;
} 

const setSoulWinContainerOptions = (sowinOptions) =>
{

  
  //const sowinOptions = soulWinOptions;
//console.log(state.mycontainer)
var mStatus = null, cMode = null, pStatus = null, pUpdate = null, pResponse = null, sConditon = null, 
sexGender = null, groupContainerType = null, serviceTypes = [], pAttendance = [], pInvite = [], positiveTags = [], 
negativeTags = [], groupLabels = [], unMappedGroupLabels = [], groupLabelTypes = [], reportTypes = [],
unMappedProspectStatus = [], prospectsTodos = [], todoAutoLabels = [];;
  if (!isEmpty(sowinOptions))
  {
    mStatus = sowinOptions.MaritalStatus.map(x => {
      return {value: x.id, label: x.text}
    });

    cMode = sowinOptions.ContactMode.map(x => {
      return {value: x.id, label: x.text}
    });

    unMappedProspectStatus = sowinOptions.ProspectStatus;
    pStatus = sowinOptions.ProspectStatus.map(x => {
      return {value: x.id, label: x.text}
    });

    sConditon = sowinOptions.SpiritualCondition.map(x => {
      return {value: x.id, label: x.text}
    });

    sexGender = sowinOptions.Gender.map(x => {
      return {value: x.id, label: x.text}
    });

    groupContainerType = sowinOptions.GroupContainerType.map(x => {
      return {value: x.id, label: x.text}
    });

    if (sowinOptions.ProspectUpdate)
    {
      pUpdate = sowinOptions.ProspectUpdate.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectResponse)
    {
      pResponse = sowinOptions.ProspectResponse.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectAttendance)
    {
      pAttendance = sowinOptions.ProspectAttendance.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ProspectInvite)
    {
      pInvite = sowinOptions.ProspectInvite.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.ServiceTypes)
    {
      serviceTypes = sowinOptions.ServiceTypes.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.PositiveTags)
    {
      positiveTags = sowinOptions.PositiveTags.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.NegativeTags)
    {
      negativeTags = sowinOptions.NegativeTags.map(x => {
        return {value: x.id, label: x.text}
      });
    }

    if (sowinOptions.GroupLabels)
    {
      unMappedGroupLabels = sowinOptions.GroupLabels;
      groupLabels = sowinOptions.GroupLabels.map(x => {
        return {value: x.id, label: x.text}
      });
    }
    if (sowinOptions.GroupLabelTypes)
    {
      groupLabelTypes = sowinOptions.GroupLabelTypes;
    }
    if (sowinOptions.ReportTypes)
    {
      reportTypes = sowinOptions.ReportTypes;
    }
    if (sowinOptions.ProspectsTodos){
      prospectsTodos = sowinOptions.ProspectsTodos.map(x => {
        return {value: x.id, label: x.text}
      });
    }
    if (sowinOptions.TodoAutoLabels){
      todoAutoLabels = sowinOptions.TodoAutoLabels.map(x => {
        return {value: x.id, label: `${x.text} (${x.description})` }
      });;
    }
   
  }
 

  const options = {
    maritalStatus: mStatus || maritalStatus,
    prospectStatus: pStatus || prospectStatus,
    unMappedProspectStatus: unMappedProspectStatus || [],
    gender: sexGender || gender,
    contactMode: cMode || contactMode,
    spiritualCondition: sConditon || spiritualCondition,
    groupContainerType: groupContainerType || groupType,
    prospectUpdate: pUpdate || [],
    prospectResponse: pResponse || [],
    prospectAttendance: pAttendance || [],
    prospectInvite : pInvite || prospectInvite,
    serviceTypes: serviceTypes || [],
    positiveTags : positiveTags || [],
    negativeTags : negativeTags || [],
    groupLabels: groupLabels || [],
    unMappedGroupLabels : unMappedGroupLabels || [],
    reportTypes,
    groupLabelTypes,
    prospectsTodos: prospectsTodos || [],
    todoAutoLabels: todoAutoLabels || []
  };
 // console.log(options)
  return options;
}  


const soulWinOptionTypes = () =>
{

  // grab current state
  const state = store.getState();

  const sowinOptions = state.mycontainer.soulWinOptionTypes
var oTypes = null;
  if (!isEmpty(sowinOptions))
  {
    oTypes = sowinOptions.map(x => {
      return {value: x.value, label: x.text}
    });

   
  }
  const options = oTypes || optionTypes;
  return options;
}  

const groupOptions = () =>
{

  // grab current state
  const state = store.getState();

  const sowinOptions = state.groups.currentGroupSoulWinOptions

  return sowinOptions

}

const userOptions = () =>
{

  // grab current state
  const state = store.getState();

  const sowinOptions = state.mycontainer.userSoulWinOptions

  return sowinOptions

}
  


 export {maritalStatus, groupType, gender, spiritualCondition, prospectStatus, contactMode, prospectBuckets, prospectOwner, memberRoles, memberMessageType,
bucketNamingType, bucketCreationType, prospectSortingMode, prospectToShow, usersListSortingMode, prospectInvite, prospectSearchTypes, prospectInviteStatus, 
soulWinOptions, soulWinGroupOptions, setSoulWinContainerOptions, soulWinOptionTypes, userOptions, groupOptions, attendanceMeetingDisplayMode} 