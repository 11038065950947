import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import './polyfill'
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';

import { icons } from './assets/icons'

import { Provider } from 'react-redux'
import { OidcProvider } from "redux-oidc";
// import store from './store'
import store, {persistor} from './app/store';
import userManager from "./auth/userManager";
import { setServiceWorkerInit, setServiceWorkerUpdate } from './app/reducers/ServiceWorkerReducer'
import 'pace-js'
import 'pace-js/themes/red/pace-theme-minimal.css'
// import { subscribeUser } from './subscription';
import {
  Provider as KeepAliveProvider,
} from 'react-keep-alive';
import { PersistGate } from 'redux-persist/integration/react'
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
// import {CoreUIDashboardLoader, CoreUIPhoneDashboardLoader, CoreUITabletDashboardLoader} from './skeletons'
// import { useMediaQuery } from 'react-responsive'

// const isMobile = useMediaQuery({ maxWidth: 767 })
// const isPhone = useMediaQuery({ maxWidth: 480 })
// const IsMobile = ({ children }) => {
//   const isMobile = useMediaQuery({ maxWidth: 767 })
//   return isMobile
// }

React.icons = icons

ReactDOM.render(
  <Provider store={store}>
    
    <OidcProvider store={store} userManager={userManager}>
    <PersistGate loading={null} persistor={persistor}>
      <KeepAliveProvider>
      <StyleSheetManager shouldForwardProp={shouldForwardProp}>
        <App />
        </StyleSheetManager>
      </KeepAliveProvider>
      </PersistGate>
    </OidcProvider>
  
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register({
  onSuccess: () => store.dispatch(setServiceWorkerInit()),
  onUpdate: registration =>
    store.dispatch(setServiceWorkerUpdate(registration)),

});

//serviceWorker.unregister();

//subscribeUser();


// This implements the default behavior from styled-components v5
function shouldForwardProp(propName, target) {
  if (typeof target === "string") {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
}