import React, { Component } from 'react';
import { HashRouter, BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import './home/css/style.css'
import './scss/style.scss';
import AppRoutes from './AppRoutes'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer, toast } from 'react-toastify';
import { useMediaQuery } from 'react-responsive'
import 'react-toastify/dist/ReactToastify.css';
import {CoreUIDashboardLoader, CoreUIPhoneDashboardLoader, CoreUITabletDashboardLoader} from './skeletons'
import {history} from './app/store'
import {retry} from './utils/SyncUtils'
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import 'react-tiny-fab/dist/styles.css';
// import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
//import react-modern-drawer styles 👇
import 'react-modern-drawer/dist/index.css'

library.add(fab, fas, far)

Array.prototype.index = 0;

Array.prototype.next = function() { 
    return this.index < this.length - 1 ? this[++this.index] : this[this.index = 0]; 
}; 

Array.prototype.previous = function() { 
    return this.index > 0 ? this[--this.index] : this[this.index=this.length - 1];
}; 

Array.prototype.current = function () {
    return this[this.index];
} 

Array.prototype.jump = function (key) {
    this.index = this.indexOf(key);
    return this[this.index];
}

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => retry(() => import('./containers/TheLayout')));

// Pages
// const Login = React.lazy(() => import('./views/pages/login/Login'));
// const Register = React.lazy(() => import('./views/pages/register/Register'));
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

// class App extends Component {

//   render() {
//     return (
//       <Router>
//           <React.Suspense fallback={loading}>
//             <Switch>
//               <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} />
//               <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
//               <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
//               <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
//               <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
//             </Switch>
//           </React.Suspense>
//       </Router>
//     );
//   }
// }

function App()
{
  const isMobile = useMediaQuery({ maxWidth: 767 })
  const isPhone = useMediaQuery({ maxWidth: 480 })

  

  const notify = () => toast("Wow so easy !");
  return (
    <ConnectedRouter history={history}>
     {/* <Router> */}
       <ToastContainer />
        <React.Suspense fallback={isMobile ?  isPhone ? CoreUIPhoneDashboardLoader() : CoreUITabletDashboardLoader() : CoreUIDashboardLoader()}>
       
          <div id="generalcontainer" className="Fade FadeAnim">
          <Switch>
         
          <AppRoutes>

          </AppRoutes>
          
            
          </Switch>
          </div>
        </React.Suspense>
         {/* </Router> */}
     </ConnectedRouter>
  );
}

export default App;
