import * as React from 'react';
import { FormGroup, Label } from "reactstrap";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import 'react-phone-input-2/lib/bootstrap.css'
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'


// const SyncPhoneInput2 = ({ field, form : {isSubmitting, touched, errors}, ...props }) => {
  const PlainPhoneInput = ({
     field, // { name, value, onChange, onBlur }
     onChange,
     invalid = false, valid = true,
    // form: { touched, errors, setFieldValue, setFieldTouched, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {

    let currentValue = "";
   // const [meta] = useField(props);
  const { options, inputprops, isRequired, showLabel = false, label } = props
 // console.log(field)
  //console.log(inputprops);
 // console.log(currentValue)
//  console.log(props, "Prospect List")
  const handleChange = (value, country, e, formattedValue) => {
   
   // setFieldValue(field.name, value.replace(/\s/g, ''))
    if (props.onUpdate) {
      props.onUpdate(value, country, e, formattedValue)
    }
   
    
  };
  return (
    <div>
      <FormGroup>
      {showLabel ? <Label for={props.id || props.name} className={"label-color"}>{label}</Label> : "" }
      <PhoneInput
        {...field} {...props}
        inputProps={props.inputprops}
        // value={field.value}
        disabled={props.disabled}
        // onChange={handleChange}
        onChange={handleChange}
        inputStyle={(invalid ? {borderColor: 'red'} : {})}
        
      />
     
    {/* <label>{isRequired && <span className="text-danger">&nbsp;*</span>}</label> */}
    {/* {touched[field.name] && errors[field.name] && (
        <div className="error text-danger mb-2">&nbsp;{errors[field.name]}</div>
      )} */}
       </FormGroup>
    </div>

  );
};


export default PlainPhoneInput