import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    email: '',
    phoneNumber: ''
};


const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        set: (state,  { payload }) => {
           // console.log(payload, "charles")
            return { ...state, email : payload.email, phoneNumber: payload.phoneNumber  }
        },
        reset: (state) => {
            // console.log(payload, "charles")
             return { ...state, email : '', phoneNumber: ''   }
         }
    }
});



// The Actions
export const { set, reset } = registrationSlice.actions;

// A selector
export const registrationSelector = state => state.registration

// The reducer
export default registrationSlice.reducer