import React, {useRef} from 'react'
import { Select } from 'react-responsive-select';
// import { Formik, Form, Field, FieldProps } from 'formik'
import { FormFeedback, FormGroup, Label } from "reactstrap";
// import "react-responsive-select-next/dist/react-responsive-select.css";
 import { ErrorIcon, CaretIcon } from './Icons'

// const CaretIcon2 = () => (
//     <svg
//       className="caret-icon"
//       x="0px"
//       y="0px"
//       width="11.848px"
//       height="6.338px"
//       viewBox="351.584 2118.292 11.848 6.338"
//     >
//       <g>
//         <path d="M363.311,2118.414c-0.164-0.163-0.429-0.163-0.592,0l-5.205,5.216l-5.215-5.216c-0.163-0.163-0.429-0.163-0.592,0s-0.163,0.429,0,0.592l5.501,5.501c0.082,0.082,0.184,0.123,0.296,0.123c0.103,0,0.215-0.041,0.296-0.123l5.501-5.501C363.474,2118.843,363.474,2118.577,363.311,2118.414L363.311,2118.414z" />
//       </g>
//     </svg>
//   );

const SyncRRSInput = ({
    field, // { name, value, onChange, onBlur }
    iconOrText,
    form: { touched, errors, setFieldValue, values, handleBlur }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const { options, isRequired = false, label, showLabel = false, multiselect = false, noSelectionLabel = "Please Select" } = props
   
    const icon = iconOrText ?? ""
    let error = errors[field.name];
    let touch = touched[field.name];
    let currentValue = values[field.name];
    var cIcon = useRef(<CaretIcon key={`${field.name}-caret`} />)
    // console.log(field.name, "RRSInputKey")
    
    return (
        <FormGroup>

            {showLabel ? <Label for={props.id || props.name} className={props.labelColor}>{label}</Label> : ""}
            {/* <label htmlFor={props.id || props.name}>{label}</label> */}

            <Select
                {...field}
                {...props}
                options={options}
                selectedValue={values[field.name]}
                // selectedValues={values[field.name].value }
                // multiselect={true}
                // caretIcon={<CaretIcon key={`${field.name}-caret`} />}
                // caretIcon={cIcon.current}
                // caretIcon={<div>+</div>}
                noSelectionLabel={noSelectionLabel}
                caretIcon={<CaretIcon key={`${field.name}-caret`}  />}
                onChange={(newValue) => {
                    var { value, name } = newValue;
                    if (props.onSelectChange) {
                        props.onSelectChange(newValue)
                    }
                    if (value) {
                        setFieldValue(field.name, multiselect ? value.map(x => x.value) : value || "")
                        //setFieldValue(field.name, value)
                    }
                    else {
                       // setFieldValue(field.name,  "")
                        setFieldValue(field.name, multiselect ? [] : "")
                    }
                }}
                onBlur={({ value, name }) => {
                    handleBlur({ target: { value, name } });
                }}

            />


            {touched[field.name] && errors[field.name] && (
                <div className="error text-danger">&nbsp;<ErrorIcon /> {errors[field.name]}</div>
            )}
            {error && <FormFeedback>{error}</FormFeedback>}

        </FormGroup>
    )
}

export default SyncRRSInput