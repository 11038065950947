import React from 'react';
import { useField } from 'formik';
import { FormGroup, Label, CustomInput } from 'reactstrap';

const SyncCheckbox = ({ label, children, ...props }) => {
    // We need to tell useField what type of input this is
    // since React treats radios and checkboxes differently
    // than inputs/select/textarea.
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    //console.log(field, "Field Information")
    //console.log(props, "Props Information")
    return (
        <div className="mb-3">
            <FormGroup check inline>
                <Label check>
                    {/* <input type="checkbox" {...field} {...props} />{' '} */}
                    <CustomInput type="checkbox" label={children} inline  {...field} {...props} />
                    {/* {children} */}
                </Label>

            </FormGroup>
            {meta.touched && meta.error ? (
                <div className="error text-danger">{meta.error}</div>
            ) : null}
        </div>


    );
};

export default SyncCheckbox