import React from "react";

const offlineAlert = () => {


  

  return (
    <></>
  )
}

// By default no caret icon is supplied - any valid jsx markup will do
export const CaretIcon = (props) => (
  <svg
    className="caret-icon"
    x="0px"
    y="0px"
    width="11.848px"
    height="6.338px"
    viewBox="351.584 2118.292 11.848 6.338"
    {...props}
  >
    <g>
      <path d="M363.311,2118.414c-0.164-0.163-0.429-0.163-0.592,0l-5.205,5.216l-5.215-5.216c-0.163-0.163-0.429-0.163-0.592,0s-0.163,0.429,0,0.592l5.501,5.501c0.082,0.082,0.184,0.123,0.296,0.123c0.103,0,0.215-0.041,0.296-0.123l5.501-5.501C363.474,2118.843,363.474,2118.577,363.311,2118.414L363.311,2118.414z" />
    </g>
  </svg>
);

export const ErrorIcon = () => (
  <svg
      x="0px"
      y="0px"
      width="12px"
      height="12px"
      viewBox="0 0 1000 1000"
      style={{ fill: 'currentColor', marginRight: '4px' }}
  >
      <g>
          <path d="M868,953.8H132.1c-49.3,0-89-19.3-108.8-53.1C3.5,867,5.9,822.9,29.9,779.8l371.3-665.4c24.2-43.4,60.1-68.2,98.6-68.2c38.5,0,74.4,24.8,98.6,68.2L970,779.9c24,43.1,26.5,87.1,6.7,120.8C956.9,934.5,917.3,953.8,868,953.8L868,953.8z M499.8,116.1c-12.2,0-26.2,12.1-37.6,32.4L90.9,813.9c-11.6,20.9-14.4,39.6-7.4,51.5c7,11.8,24.7,18.6,48.5,18.6H868c23.9,0,41.6-6.7,48.5-18.6c6.9-11.8,4.2-30.6-7.4-51.4L537.4,148.5C526.1,128.2,512,116.1,499.8,116.1L499.8,116.1z M500,638.7c-19.3,0-34.9-15.6-34.9-34.9V289.5c0-19.3,15.6-34.9,34.9-34.9c19.3,0,34.9,15.6,34.9,34.9v314.3C534.9,623.1,519.3,638.7,500,638.7z M447.6,761.1c0,28.9,23.5,52.4,52.4,52.4c28.9,0,52.4-23.5,52.4-52.4c0-28.9-23.5-52.4-52.4-52.4C471.1,708.7,447.6,732.2,447.6,761.1z" />
      </g>
  </svg>
);

export const CheckboxIcon = (props) => (
  <svg className="checkbox-icon" x="0px" y="0px" width="12px" height="12px" viewBox="0 0 488.878 488.878" {...props}>
    <g>
      <polygon points="143.294,340.058 50.837,247.602 0,298.439 122.009,420.447 122.149,420.306 144.423,442.58 488.878,98.123 437.055,46.298 " />
    </g>
  </svg>
);

export const MultiSelectOptionMarkup = ({ text, checkBoxKey, ...props }) => (
  <div>
    <span className="checkbox">
      <CheckboxIcon key={checkBoxKey || "checkBoxKey"} {...props} />
    </span>
    <span> {text}</span>
  </div>
);

export const CloseIcon = () => (
  <svg
      enable-background="new 0 0 32 32"
      width="32px"
      height="32px"
      viewBox="0 0 32 32"
      style={{ fill: 'currentColor', marginRight: '4px' }}
  >
      <g id="Cancel">
      <path clip-rule="evenodd" d="M16,0C7.163,0,0,7.163,0,16c0,8.836,7.163,16,16,16   c8.836,0,16-7.163,16-16C32,7.163,24.836,0,16,0z M16,30C8.268,30,2,23.732,2,16C2,8.268,8.268,2,16,2s14,6.268,14,14   C30,23.732,23.732,30,16,30z" fill="#121313" fill-rule="evenodd"/>
      <path clip-rule="evenodd" d="M22.729,21.271l-5.268-5.269l5.238-5.195   c0.395-0.391,0.395-1.024,0-1.414c-0.394-0.39-1.034-0.39-1.428,0l-5.231,5.188l-5.309-5.31c-0.394-0.396-1.034-0.396-1.428,0   c-0.394,0.395-0.394,1.037,0,1.432l5.301,5.302l-5.331,5.287c-0.394,0.391-0.394,1.024,0,1.414c0.394,0.391,1.034,0.391,1.429,0   l5.324-5.28l5.276,5.276c0.394,0.396,1.034,0.396,1.428,0C23.123,22.308,23.123,21.667,22.729,21.271z" fill="#121313" fill-rule="evenodd"/>
      </g>
      <g/><g/><g/><g/><g/><g/>
  </svg>
);

// export const MultiSelectOptionMarkup = ({ children, ...props }) => (
//   <div>
//     <span className="checkbox">
//       <svg
//         className="checkbox-icon"
//         x="0px"
//         y="0px"
//         width="12px"
//         height="12px"
//         viewBox="0 0 488.878 488.878"
//          {...props}
//       >
//         <g>
//           <polygon points="143.294,340.058 50.837,247.602 0,298.439 122.009,420.447 122.149,420.306 144.423,442.58 488.878,98.123 437.055,46.298 " />
//         </g>
//       </svg>
//     </span>
//     <span> {children}</span>
//   </div>
// );