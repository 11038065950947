import * as React from 'react'
// import { Formik, Form, Field, FieldProps } from 'formik'
import Select from 'react-select'
import { FormFeedback, FormGroup, Label } from "reactstrap";

// const ErrorMessage = styled.div`
//   color: var(--watermelon);
// `

const SyncReactSelectInput = ({
    field, // { name, value, onChange, onBlur }
    iconOrText,
    form: { touched, errors, setFieldValue, values }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
}) => {
    const { options, isRequired = false, label, maxWidth = 0, showLabel = false, isMulti = false } = props
    const icon = iconOrText ?? ""
    let error = errors[field.name];
    let touch = touched[field.name];
    let currentValue = values[field.name];

    const getValue = () => {
        if (options) {
          return isMulti
            ? options.filter(option => field.value.indexOf(option.value) >= 0)
            : options.find(option => option.value === field.value) ? options.find(option => option.value === field.value) : ''
        } else {
          return isMulti ? [] : ("");
        }
      };
    //console.log(errors)
    //console.log(error)
    //console.log(touch)
    //console.log(field.name)
    return (
        <FormGroup style={maxWidth <= 0 ? {} : {maxWidth: maxWidth + 35}}>
            {/* <label htmlFor={field.name}>{props.label}</label> */}
            {showLabel ? <Label for={props.id || props.name} className={props.labelColor}>{label}</Label> : "" }
            {/* <label htmlFor={props.id || props.name}>{label}</label> */}
            {/* <InputGroup >
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                        {icon}
                    </InputGroupText>
                </InputGroupAddon> */}
                  {/* <label>{isRequired && <span className="text-danger">&nbsp;*</span>}</label> */}
                <Select
                    {...field}
                    {...props}
                    options={options}
                    value={getValue()}
                    styles={{
                        control: (provided, state) => (Boolean(currentValue === "" && isRequired) ? {
                            ...provided, borderColor: 'red', 
                        } : provided)
                    }}
                    // value={(options ? options.find(option => option.value === field.value) ? options.find(option => option.value === field.value) : '' : '')}
                    onChange={option => {
                        if (props.onSelectChange)
                        {
                            props.onSelectChange(option)
                        }
                        if (option)
                        {
                            setFieldValue(field.name, isMulti ? option.map(x => x.value) : (option).value || "")
                        }
                        else
                        {
                            setFieldValue(field.name, isMulti ? [] : "")
                        }
                      
                    }}
                />
                
            {/* </InputGroup> */}
            {/* {errors[field.name] && (
                <div>{errors[field.name]}</div>
            )} */}
             {touched[field.name] && errors[field.name] && (
                <div className="error text-danger">&nbsp;{errors[field.name]}</div>
            )}
            {error && <FormFeedback>{error}</FormFeedback>}
            {/* {currentValue === "" && isRequired &&  <div className="error text-danger">&nbsp;Is Required</div>} */}
        </FormGroup>
    )
}

export default SyncReactSelectInput