import React, { Component } from "react";
import PlacesAutocomplete, {
  geocodeByAddress, 
  getLatLng
} from "react-places-autocomplete";
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
class FormikPlacesAutoComplete extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.field.name,
      address: props.value || "",
      icon: props.iconOrText || "",
      isRequired : props.isRequired || false
    };
  }

  handleError = error => {
    this.props.form.setFieldError(this.state.name, error);
  };

  handleChange = address => {
    this.setState(() => {
      this.props.form.setFieldTouched(`${this.state.name}.value`);
      this.props.form.setFieldTouched(`${this.state.name}.address`);
      this.props.form.setFieldValue(this.state.name, { value: address });
      return { address };
    });
  };

  handleSelect = (address) => {
    let placeDetails = {};
    geocodeByAddress(address)
      .then(results => {
        placeDetails = results[0];
       return getLatLng(results[0])
      })
      .then(latLng => {
        this.setState(() => {
          this.props.form.setFieldValue(this.state.name, {
            value: address,
            address,
            coordinates: latLng,
            placeDetails
          });
       
          return { address };
        });
      })
      .catch(error => this.props.form.setFieldError(this.state.name, error));

     
  };

  render() {
    const {
      field: { name, ...field }, // { name, value, onChange, onBlur }
      form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
      classes,
      label,
      ...props
    } = this.props;

    const error = errors[name];
    const touch = touched[name];
   // console.log(this.state);
    return (
      <PlacesAutocomplete
        name={name}
        id={name}
        {...props}
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        onError={this.handleError}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className="mb-3">
                <InputGroup >
               
                <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                       {this.state.icon}
                    </InputGroupText>
                </InputGroupAddon>
                <input
              {...getInputProps({
                placeholder: "Search Places ...",
                className: "location-search-input form-control"
              })}
            />
             <label>{this.state.isRequired && <span className="text-danger">&nbsp;*</span>}</label>
                {/* <input type="text"  {...field} {...props} /> */}
                {/* <Input type="text"  {...field} {...props} /> */}
               


            </InputGroup>

          
            <div className="autocomplete-dropdown-container">
              {/* {loading && <div>Loading...</div>} */}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? "suggestion-item--active"
                  : "suggestion-item";
                // inline style for demonstration purpose
                const style = suggestion.active
                  ? { backgroundColor: "grey", cursor: "pointer", padding: '8px' }
                  : { backgroundColor: "#ffffff", cursor: "pointer" };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default FormikPlacesAutoComplete;
