import * as yup from "yup";

export function createYupSchema(schema, config) {
  //  console.log(schema, "Schema")
  //  console.log(config, "Config")
  const { name, validationType, validations = [] } = config;

  if (!yup[validationType]) {
    return schema;
  }
  let validator = yup[validationType]();
  validations.forEach(validation => {
    const { params, type } = validation;
    if (!validator[type]) {
        // console.log(type, params, "Invalid ValidationInfo");
      return;
    }
    // console.log(type, params, "ValidationInfo");
    validator = validator[type](...params);
    // console.log(validator, "Full Validator");
  });
  schema[name] = validator;
  return schema;
}
