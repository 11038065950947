import React from "react";
import { useField, useFormikContext } from "formik";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Input, InputGroup, InputGroupAddon, InputGroupText, FormGroup, Label } from 'reactstrap';



const DatePickerField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    const { isRequired = false, label, showLabel = false } = props
    const CustomInput = ({ value, onClick, iconOrText = '', isRequired = false, ...rest }) => {
        return (
        <InputGroup >

            <InputGroupAddon addonType="prepend">
                <InputGroupText>
                    {iconOrText}
                </InputGroupText>
            </InputGroupAddon>
            {/* <input type="text"  {...field} {...props} /> */}
            <Input type="text" onClick={onClick} value={value} {...rest} />
            <label>{isRequired && <span className="text-danger">&nbsp;*</span>}</label>


        </InputGroup>
        )
    }
    return (
        <FormGroup>
                {showLabel ? <Label for={props.id || props.name} className={"label-color"}>{label}</Label> : "" }
        <DatePicker
            {...field}
            {...props}
            className="red-border"
            selected={(field.value && new Date(field.value)) || new Date()}
            onChange={val => {
                setFieldValue(field.name, val);

            }}
            customInput={<CustomInput  {...props} />}
        />

</FormGroup>
    );
};

export default DatePickerField;