import { createSlice } from '@reduxjs/toolkit'
import { array_move, isEmpty } from '../../utils/SyncUtils'


export const initialState = {

    userFollowUpTasks: [],
    userFollowUpTasksProspects: [],
    currentGroupFollowUpTasks: [],
    currentGroupFollowUpTasksProspects: [],
    loadedProspectsTaskId: [],
    loadedTaskProspects: []
};

const tasksSlice = createSlice({
    name: 'tasks',
    initialState,
    reducers: {


        setUserFollowUpTasks: (state, { payload }) => {
            return { ...state, userFollowUpTasks: payload }
        },
        setCurrentGroupFollowUpTasks: (state, { payload }) => {
            return { ...state, currentGroupFollowUpTasks: payload }
        },
        setUserFollowUpTasksProspects: (state, { payload }) => {
            return { ...state, userFollowUpTasksProspects: payload }
        },
        setCurrentGroupFollowUpTasksProspects: (state, { payload }) => {
            return { ...state, currentGroupFollowUpTasksProspects: payload }
        },
        addToUserFollowUpTasks: (state, { payload }) => {
            state.userFollowUpTasks.unshift(payload);
           // return { ...state, userNotifications: payload }
        },
        addToCurrentGrouFollowUpTasks: (state, { payload }) => {
            state.currentGroupFollowUpTasks.unshift(payload);
           // return { ...state, userNotifications: payload }
        },
        addLoadedTaskId: (state, { payload }) => {
            let amExisting = state.loadedProspectsTaskId.findIndex(x => {
                return x === payload;
            })
            if (amExisting < 0) {
                state.loadedProspectsTaskId.unshift(payload);
            }
            
        },
        addTaskProspects: (state, { payload }) => {
            let arr1 = JSON.parse(JSON.stringify(state.loadedTaskProspects));;
            //let arr2 = [payload, ...arr1,];
            var props = payload.concat(arr1);
            let hashedMoreProspects = [
                ...new Map(props.map(obj => [`${obj.guid}`, obj]))
                    .values()
            ];

            return { ...state, loadedTaskProspects: hashedMoreProspects }
            
        },
        addOrUpdateLoadedTaskProspect: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                let amExisting = state.loadedTaskProspects.findIndex((element) => {
                    return element.guid.toLowerCase() === payload.guid.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        console.log("Loaded Task Updated for payload.name")
                        state.loadedTaskProspects[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                // else {
                //     state.loadedTaskProspects.unshift(payload)
                // }


            }
           
        },

    }

})

export const { setUserFollowUpTasks, setCurrentGroupFollowUpTasks, addToCurrentGrouFollowUpTasks, addToUserFollowUpTasks, 
    addLoadedTaskId, addTaskProspects, addOrUpdateLoadedTaskProspect} = tasksSlice.actions;

// A selector
export const tasksSelector = state => state.tasks

// The reducer
export default tasksSlice.reducer