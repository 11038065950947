import React, { useEffect, useState, useRef } from 'react';
import {
    useLocation
} from "react-router-dom";

import useOnline from "@rooks/use-online";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import {groupSelector} from '../app/reducers/GroupsReducer'
import { parseBool } from '../utils/SyncUtils'
// import Ping from 'ping-url';

// Ping.config({
//     protocol: 'https'
// });

const isLocalhost = (hostname) => Boolean(
    hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    hostname === '[::1]' ||
    // 127.0.0.1/8 is considered localhost for IPv4.
    hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const offlineMessages = {
    general: "No Internet Connection. Make sure that Wi-Fi or mobile data is turned on. then try again.",
    noDesktopConnection: "Computer not connected, Make sure your computer has an active Internet connection",
    impossibleOperation: "Operation not possible, No active Internet connection",
    impossibleSyncing: "Syncing not possible, No active Internet connection",
    noMobileConnection: "No Internet Connection. Make sure that Wi-Fi or mobile data is turned on. then try again.",
    authServerOffline: "Authentication Server is offline or unavailable, Please check back soon",
    serverOffline: "Server is offline or unavailable, Please check back soon",
  };


// A custom hook that builds on useLocation to parse
// the query string for you.
export function useQuery() {
    return new URLSearchParams(useLocation().search);
}





export function usePlatformOnline() {
    const isOnline = useOnline();
    //const [platFormOnline, setPlatFormOnline] = useState(() => useOnline())



    const getPlatFormOnline = () => {

        var authorizationurl = new URL(process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL);
        var hostname = authorizationurl.hostname;
        //console.log(hostname, process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL)
        if (isLocalhost(hostname)) {
            return true;
        }
        return isOnline
    }

    const offlineMessage = (msg = "No Internet Connection. Make sure that Wi-Fi or mobile data is turned on. then try again.", isError = false) => {
        
        // var msg = offlineMessage["general"]
        // if (offlineMessage[msgType])
        // {
        //     msg = offlineMessage[msgType];
        // }
        if (isError)
        {
            return toast.error(msg, {
                position: "bottom-center",
                autoClose: 10000,
                closeButton: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }
        return toast.info(msg, {
            position: "bottom-center",
            autoClose: 10000,
            closeButton: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            className: 'toast-container-dark-notification'
        });
    }


    return [getPlatFormOnline(), offlineMessage];
}

export function onlineCheck(baseUrl) {
// console.log("Online Checking")
    let xhr = new XMLHttpRequest();
        return new Promise((resolve, reject)=>{
            xhr.onload = () => {
                // console.log("Onload")
                // Set online status
                // this.isOnline = true;
                resolve(true);
            };
            xhr.onerror = () => {
                // Set online status
                // this.isOnline = false;
                console.log("** An error occurred during online checking of " + baseUrl);
                reject(false);
            };
            xhr.open('GET', baseUrl, true);
            xhr.send();
        });
    // return new Promise((resolve, reject) => {
        
    // })
    // fetch(baseUrl, {
    //     method: 'GET'
    // }).then(response => response.json()).then(data => {
    //     return new Promise((resolve, reject) => {
    //         resolve(true)
    //       })
    // }).catch((error) => {
    //     return new Promise((resolve, reject) => {
    //         reject(false);
    //       })
    // })
}

export function useAuthPlatformOnline() {
    const isOnline = useOnline();
    //const [platFormOnline, setPlatFormOnline] = useState(() => useOnline())



    const getPlatFormOnline = () => {

        var authorizationurl = new URL(process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL);
        var hostname = authorizationurl.hostname;
        //console.log(hostname, process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL)
        if (isLocalhost(hostname)) {

            return {serverOnline: true, hasInternet: isOnline};
        } else {
            if (isOnline) {
                onlineCheck(authorizationurl).then((res) => {
                    console.log(res)
                    return {serverOnline: res, hasInternet: true};
                }).catch(() => {
                    return {serverOnline: false, hasInternet: true};
                })
                // Ping.check(authorizationurl).then(res => {
                //     return {serverOnline: res.status, hasInternet: true};
                // }, res => {
                //     return {serverOnline: false, hasInternet: true};
                // })

            } else {
                return {serverOnline: false, hasInternet: false};
            }
        }
       
    }

    const offlineMessage = (msg = "No Internet Connection. Make sure that Wi-Fi or mobile data is turned on. then try again.", isError = false) => {
        
        // var msg = offlineMessage["general"]
        // if (offlineMessage[msgType])
        // {
        //     msg = offlineMessage[msgType];
        // }
        if (isError)
        {
            return toast.error(msg, {
                position: "bottom-center",
                autoClose: 10000,
                closeButton: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
            });
        }
        return toast.info(msg, {
            position: "bottom-center",
            autoClose: 10000,
            closeButton: false,
            hideProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: true,
            className: 'toast-container-dark-notification'
        });
    }


    return [getPlatFormOnline()];
}


// A custom hook to Add HomeScreen Prompt in PWA app.
export function useAddToHomescreenPrompt() {
    const [prompt, setState] = React.useState(null);

    const promptToInstall = () => {
        if (prompt) {
            return prompt.prompt();
        }
        return Promise.reject(
            new Error(
                'Tried installing before browser sent "beforeinstallprompt" event'
            )
        );
    };

    useEffect(() => {
        const ready = (e) => {
            e.preventDefault();
           // console.log("we are being triggered :D");
            setState(e);
        };


        window.addEventListener("beforeinstallprompt", ready);

        return () => {
            window.removeEventListener("beforeinstallprompt", ready);
        };

    }, []);

    return [prompt, promptToInstall];
}


export function useSowinGroupUserInfo() {
    const { currentGroup } = useSelector(groupSelector)
    const stateAuth = useSelector(state => state.auth);
    const user = stateAuth.user;

    const [isAcceptedGroupAdmin, setIsAcceptedGroupAdmin] = useState(false)
    const [isInvitedOrAcceptedGroupAdmin, setIsInvitedOrAcceptedGroupAdmin] = useState(false)
    const [isAcceptedUser, setIsAcceptedUser] = useState(false)
    const [isInvitedUser, setIsInvitedUser] = useState(false)
    const [isYouAndAcceptedMember, setIsYouAndAcceptedMember] = useState(false)
    const [isInvitedOrAcceptedMember, setIsInvitedOrAcceptedMember] = useState(false)
    // const [isNotMeButAmMember, setIsNotMeButAmMember] = useState(false)

    const handleUserInfo = () => {
        var currentUser = currentGroup.members.filter(x => {
            return x.user && x.user.id.toString() === user.profile.sub
        })

        if (currentUser.length > 0) {
            var isAcceptedAdmin = currentUser[0].isAdmin && currentUser[0].accept ? true : false;
            setIsAcceptedGroupAdmin(isAcceptedAdmin)

            var isAdmin = currentUser[0].isAdmin ? true : false;
            setIsInvitedOrAcceptedGroupAdmin(isAdmin)

            var acceptedUser = currentUser[0].accept ? true  : false;
            // console.log(user.profile.sub, "is accepted", acceptedUser)
            setIsAcceptedUser(acceptedUser)

            var invitedUser = !currentUser[0].accept ? true  : false;
            setIsInvitedUser(invitedUser)

            var isAcceptedMember = currentUser[0].isAdmin ? false : currentUser[0].accept ? true  : false;
            setIsYouAndAcceptedMember(isAcceptedMember)

            var isAcceptedOrInvitedMember = currentUser[0].isAdmin ? false : true;
            setIsInvitedOrAcceptedMember(isAcceptedOrInvitedMember)


            //console.log(acceptedUser, isAcceptedOrInvitedMember, "Checking UseEffect")

        }
        else{
            console.log("No user")
        }
    }

    useEffect(() => {
       handleUserInfo();
    })

    return {isAcceptedGroupAdmin, isInvitedOrAcceptedGroupAdmin, isAcceptedUser, isYouAndAcceptedMember, isInvitedOrAcceptedMember};
    // return isAcceptedUser;
}

export function useTaskUserInfo() {
    const { currentGroup } = useSelector(groupSelector)
    const stateAuth = useSelector(state => state.auth);
    const user = stateAuth.user;

    const [canUserCreateTask, setCanUserCreateTask] = useState(false)
    const [currentUser] = useState(() => {
        var cUser = currentGroup.members.filter(x => {
            return x.user && x.user.id.toString() === user.profile.sub
        });
      
        return cUser.length > 0 ? cUser[0] : null
    })

    const handleUserInfo = () => {
        if (currentGroup.settings.onlyAdminCanCreateTask === false)
        {
            setCanUserCreateTask(true)
        } else if (currentGroup.settings.onlyAdminCanCreateTask === true)
        {
            var isAllowed = currentGroup.settings.taskCreationMembersAllowList.filter(x => x === currentUser.guid).length > 0
            if (isAllowed) {
                setCanUserCreateTask(true)
            }
            else
            {
                setCanUserCreateTask(false)
            }
           
        }

        var isAdmin = currentUser.isAdmin ? true : false;
            if (isAdmin) {
                setCanUserCreateTask(true)
            }
    }

    useEffect(() => {
        handleUserInfo();
     }, [])

     return [canUserCreateTask];
}

export function useGroupUserLabelInfo() {
    const { currentGroup } = useSelector(groupSelector)
    const stateAuth = useSelector(state => state.auth);
    const user = stateAuth.user;

    const [isAcceptedGroupAdmin, setIsAcceptedGroupAdmin] = useState(false)
    const [isInvitedOrAcceptedGroupAdmin, setIsInvitedOrAcceptedGroupAdmin] = useState(false)
    const [isAcceptedUser, setIsAcceptedUser] = useState(false)
    const [isUserProspectLabelled, setIsProspectLabelled] = useState(false)
    const [userProspectLabels, setProspectLabels] = useState([])
    const [canUserAccessAllLabels, setCanAccessAllLabels] = useState(false)
    const [currentUser] = useState(() => {
        var cUser = currentGroup.members.filter(x => {
            return x.user && x.user.id.toString() === user.profile.sub
        });
      
        return cUser.length > 0 ? cUser[0] : null
    })

    const handleUserInfo = () => {
        // var currentUser = currentGroup.members.filter(x => {
        //     return x.user && x.user.id.toString() === user.profile.sub
        // })
//console.log(currentUser, "Charless")
        if (currentUser !== null) {
            var isAcceptedAdmin = currentUser.isAdmin && currentUser.accept ? true : false;
            setIsAcceptedGroupAdmin(isAcceptedAdmin)

            var isAdmin = currentUser.isAdmin ? true : false;
            setIsInvitedOrAcceptedGroupAdmin(isAdmin)

            var acceptedUser = currentUser.accept ? true  : false;
            setIsAcceptedUser(acceptedUser)

            var prospectLabelled = currentUser.labels.filter(y => {
                return parseBool(y.label.any) === true
            }).length > 0
            setIsProspectLabelled(prospectLabelled);

            var prospLabels = currentUser.labels.filter(y => {
                return parseBool(y.label.any) === true
            }).map(u => u.label)
           // console.log(prospLabels, "Charlesssss")
            setProspectLabels(prospLabels)

            if (prospectLabelled && currentGroup.settings.restrictMembersToAttachedProspectLabels === false)
            {
                setCanAccessAllLabels(true)
            } else if (prospectLabelled && currentGroup.settings.restrictMembersToAttachedProspectLabels === true)
            {
                var isAllowed = currentGroup.settings.labelledMembersAllowList.filter(x => x === currentUser.guid).length > 0
                if (isAllowed) {
                    setCanAccessAllLabels(true)
                }
                else
                {
                    setCanAccessAllLabels(false)
                }
               
            }

            if (!prospectLabelled && currentGroup.settings.allowUnLabelledMembersToAllLabels === true)
            {
                var isDenied = currentGroup.settings.unLabelledMembersDenyList.filter(x => x === currentUser.guid).length > 0
                if (isDenied) {
                    setCanAccessAllLabels(false)
                }
                else
                {
                    setCanAccessAllLabels(true)
                }
            }
            else if (!prospectLabelled && currentGroup.settings.allowUnLabelledMembersToAllLabels === false) {
               
                setCanAccessAllLabels(false)
               
            }


        }
        else{
            console.log("No user")
        }
    }

    useEffect(() => {
       handleUserInfo();
    }, [])

    // useEffect(() => {
    //     handleUserInfo();
    //  }, [currentUser])

    return [isAcceptedGroupAdmin, isInvitedOrAcceptedGroupAdmin, isAcceptedUser,
    isUserProspectLabelled, userProspectLabels, canUserAccessAllLabels];
    // return isAcceptedUser;
}


export function useContainerUserLabelInfo(container) {
    //const { currentGroup } = useSelector(groupSelector)
    const stateAuth = useSelector(state => state.auth);
    const user = stateAuth.user;

    const [isAcceptedGroupAdmin, setIsAcceptedGroupAdmin] = useState(false)
    const [isInvitedOrAcceptedGroupAdmin, setIsInvitedOrAcceptedGroupAdmin] = useState(false)
    const [isAcceptedUser, setIsAcceptedUser] = useState(false)
    const [isUserProspectLabelled, setIsProspectLabelled] = useState(false)
    const [userProspectLabels, setProspectLabels] = useState([])
    const [canUserAccessAllLabels, setCanAccessAllLabels] = useState(false)
    const [currentUser] = useState(() => {
        if (container === null || container === undefined) {
            return null;
        }
        var cUser = container.members.filter(x => {
            return x.user && x.user.id.toString() === user.profile.sub
        });
      
        return cUser.length > 0 ? cUser[0] : null
    })

    const handleUserInfo = () => {
        // var currentUser = currentGroup.members.filter(x => {
        //     return x.user && x.user.id.toString() === user.profile.sub
        // })
//console.log(currentUser, "Charless")
        if (currentUser !== null) {
            var isAcceptedAdmin = currentUser.isAdmin && currentUser.accept ? true : false;
            setIsAcceptedGroupAdmin(isAcceptedAdmin)

            var isAdmin = currentUser.isAdmin ? true : false;
            setIsInvitedOrAcceptedGroupAdmin(isAdmin)

            var acceptedUser = currentUser.accept ? true  : false;
            setIsAcceptedUser(acceptedUser)

            var prospectLabelled = currentUser.labels.filter(y => {
                return parseBool(y.label.any) === true
            }).length > 0
            setIsProspectLabelled(prospectLabelled);

            var prospLabels = currentUser.labels.filter(y => {
                return parseBool(y.label.any) === true
            }).map(u => u.label)
           // console.log(prospLabels, "Charlesssss")
            setProspectLabels(prospLabels)

            if (prospectLabelled && container !== null && container !== undefined && container.settings.restrictMembersToAttachedProspectLabels === false)
            {
                setCanAccessAllLabels(true)
            } else if (prospectLabelled && container !== null && container !== undefined && container.settings.restrictMembersToAttachedProspectLabels === true)
            {
                var isAllowed = container.settings.labelledMembersAllowList.filter(x => x === currentUser.guid).length > 0
                if (isAllowed) {
                    setCanAccessAllLabels(true)
                }
                else
                {
                    setCanAccessAllLabels(false)
                }
               
            }

            if (!prospectLabelled && container !== null && container !== undefined && container.settings.allowUnLabelledMembersToAllLabels === true)
            {
                var isDenied = container.settings.unLabelledMembersDenyList.filter(x => x === currentUser.guid).length > 0
                if (isDenied) {
                    setCanAccessAllLabels(false)
                }
                else
                {
                    setCanAccessAllLabels(true)
                }
            }
            else if (!prospectLabelled && container !== null && container !== undefined && container.settings.allowUnLabelledMembersToAllLabels === false) {
               
                setCanAccessAllLabels(false)
               
            }


        }
        else{
            console.log("No user")
        }
    }

    useEffect(() => {
       handleUserInfo();
    }, [])

    // useEffect(() => {
    //     handleUserInfo();
    //  }, [currentUser])

    return [isAcceptedGroupAdmin, isInvitedOrAcceptedGroupAdmin, isAcceptedUser,
    isUserProspectLabelled, userProspectLabels, canUserAccessAllLabels];
    // return isAcceptedUser;
}


export const useIsMount = () => {
    const isMountRef = useRef(true);
    useEffect(() => {
      isMountRef.current = false;
    }, []);
    return isMountRef.current;
  };


  export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }


  export const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}
    const removeFocus = () => {htmlElRef.current &&  htmlElRef.current.blur()}

    return [ htmlElRef, setFocus, removeFocus ] 
}