import React, { useState } from 'react';
import { Formik, Field } from 'formik';
import { useSelector, useDispatch } from "react-redux";
import * as Yup from 'yup';
import { Button, Card, CardBody, CardFooter, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import { SyncTextInput, SyncCheckBox, SyncPhoneInput2 } from '../../components'
//import { soulWinOptions } from '../../utils/options'
import logo from '../../assets/img/brand/logo.png'
import { Link, useHistory } from 'react-router-dom';
import { registerUser, checkEmailIsRegistered, checkPhoneNumberIsRegistered } from '../../utils/ApiUtils'
import { validateEmail } from '../../utils/SyncUtils'
import { LoadingAlertConfig, LoadingSpinner } from '../../components/UtilWidgets'
import { toast } from 'react-toastify';
// import 'react-phone-number-input/style.css'
// import PhoneInput from 'react-phone-number-input'
// import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css'
import '../../components/styles.css'
import { parsePhoneNumberFromString as parseMax } from 'libphonenumber-js/mobile'
import { usePlatformOnline, offlineMessages } from '../../hooks/SyncHooks'
import { useDebouncedCallback } from 'use-debounce';
import {set, reset} from '../../app/reducers/RegistrationReducer'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const RegisterUserFormLite = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    // const [phoneNumber, setValue] = useState()
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [isOnline, offlineMessage] = usePlatformOnline()
    const [checkAvailability, setCheckAvailability] = useState(true);
    const [checkEmailAvailability, setCheckEmailAvailability] = useState(true);
    const [passwordShown, setPasswordShown] = useState(false);
    
    const [debouncedCheckAvailabilityCallback] = useDebouncedCallback(
        // function
        (value) => {
            if (value) {
                console.log(value);
                if (parseMax(value) !== null && parseMax(value) !== undefined) {
                    if (parseMax(value).isValid()) {
                        var parsedPhone = parseMax(value);
                        //console.log(parseMax(value));
                        checkPhoneNumberIsRegistered(parsedPhone.number).then(result => {
                            setCheckAvailability(!result);
                        }).catch(err => {
                            setCheckAvailability(false);
                        })
                    }
                    else {
                        setCheckAvailability(false);
                    }
                }
                else {
                    setCheckAvailability(false);
                }

            }
            else {
                setCheckAvailability(false)
            }
            // setCheckAvailability(value);
        },
        // delay in ms
        300
    );
    const [debouncedCheckEmailAvailabilityCallback] = useDebouncedCallback(
        // function
        (value) => {
            if (value) {
                checkEmailIsRegistered(value).then(result => {

                    setCheckEmailAvailability(!result);
                }).catch(err => {
                    setCheckEmailAvailability(false);
                })
            }
            else {
                setCheckEmailAvailability(false);
            }

        },
        // delay in ms
        300
    );
    const closeBtn = <div></div>;

    const toggleSuccessMessage = () => {
        setSuccessMessage(!successMessage);
        // this.setState({
        //   large: !this.state.large,
        // });
    }

    const togglePasswordVisiblity = () => {
        setPasswordShown(!passwordShown);
      };

    const handleFormSubmit = (values, resetForm) => {
        if (isOnline) {
            // dispatch({type: 'RESET_STORE'})
            var parsedPhone = parseMax(values.phoneNumber);
            values["phoneNumber"] = parsedPhone.number;
            registerUser(values).then(res => {
               // setSubmitting(false);
                if (res.success) {
                    toast.dismiss();
                   
                    dispatch(set(values))
                    setIsSubmitting(false)
                    if (res.result.use2FA) {
                        toast.success("First Step of Registration Completed");
                        history.push("/verify-code");
                    } else {
                        toast.success("Registration Successful");
                        toggleSuccessMessage();
                    }
                    //history.push("/signupcompleted");
                }
                else {
                    toast.dismiss();
                    toast.error(`${res.errorMessage}`)
                    setIsSubmitting(false)
                }
                console.log(res)

                // if (result.)
            }).catch(err => {
                console.log(err);
                toast.error(err)
                setIsSubmitting(false)

            })

        }
        else {
            offlineMessage(offlineMessages.impossibleOperation, true)
        }
    }

    const handleFormSuccess = () => {
        setSuccessMessage(!successMessage);
        history.push("/");
    }

    return (
        <div className="app flex-row align-items-center">
            <Container>
                <Row className="justify-content-center no-gutters">

                    <Col md="9" lg="7" xl="6">
                        <Card >
                            <CardBody className="p-4">
                                <div className="flex-row justify-content-center d-flex">
                                <Link to="/">
                                <img src={logo} className="" alt="sowinna.com brand logo" height="32px" />
                                    </Link>
                                    {/* <img src={logo} className="" alt="sowinna.com logo" height="32px" /> */}
                                </div>
                                <h2>SignUp</h2>
                                {/* <Button color="secondary" onClick={toggleSuccessMessage}>Toggle</Button> */}
                                {/* <p className="text-muted">Personal Information</p> */}
                                <Formik
                                    initialValues={{
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        password: '',
                                        phoneNumber: '',
                                        confirmPassword: '',


                                        // contactdate: new Date()
                                    }}
                                    validationSchema={Yup.object({
                                        firstName: Yup.string()
                                            .max(15, 'Must be 15 characters or less')
                                            .required('First name Required'),
                                        lastName: Yup.string()
                                            .max(15, 'Must be 15 characters or less')
                                            .required('Last name Required'),
                                        phoneNumber: Yup.string()
                                            .max(25, 'Must be 25 characters or less')
                                            .required('Phone number Required')
                                            .test("is-valid-phone", "Phone Number must be valid", (value) => {
                                               // console.log("Checking Phone Valid")
                                            //    console.log(value, "parse")
                                                return new Promise((resolve, reject) => {
                                                    if (value) {
                                                        if (parseMax(value) !== null && parseMax(value) !== undefined) {
                                                            console.log(parseMax(value), "parse2")
                                                            resolve(parseMax(value).isValid());
                                                        }
                                                        else
                                                        {
                                                            resolve(false);
                                                        }
                                                       
                                                    }
                                                    else {
                                                        resolve(false)
                                                    }


                                                })
                                            })
                                            // .test('checkAvailability', 'Phone Number already registered', (value) => {

                                            //     //debouncedCheckAvailabilityCallback(value);
                                            //    // return checkAvailability
                                            //     return new Promise((resolve) => {
                                            //         debouncedCheckAvailabilityCallback(value);
                                            //         resolve(checkAvailability)
                                            //     });

                                            // }),
                                            .test('checkAvailability', 'Phone Number already registered', (value) => {

                                                return new Promise((resolve, reject) => {
                                                   // console.log("Checking Phone Reg")
                                                    
                                                    if (value) {
                                                        if (parseMax(value) !== null && parseMax(value) !== undefined) {
                                                            if (parseMax(value).isValid()) {
                                                                var parsedPhone = parseMax(value);
                                                                //console.log(parsedPhone);
                                                                checkPhoneNumberIsRegistered(parsedPhone.number).then(result => {
                                                                   // console.log("Checking Phone Reg Ajax")
                                                                    resolve(!result);
                                                                }).catch(err => {
                                                                    resolve(false);
                                                                })
                                                            }
                                                            else {
                                                                resolve(false);
                                                            }
                                                        }
                                                        else {
                                                            resolve(false)
                                                        }
                                                    }
                                                    else {
                                                        resolve(false)
                                                    }


                                                })
                                            }),
                                        //    gender: Yup.string()
                                        //         .required('Gender is Required'),
                                        email: Yup.string()
                                            .email('Invalid email address')
                                            .required('Email Required')
                                            .test('checkEmailIsRegistered', 'Email already registered', (value) => {

                                                // debouncedCheckEmailAvailabilityCallback(value);
                                                return new Promise((resolve) => {
                                                    debouncedCheckEmailAvailabilityCallback(value);
                                                    resolve(checkEmailAvailability)
                                                });

                                            }),
                                        // .test('checkEmailIsRegistered', 'Email already registered', (value) => {

                                        //     return new Promise((resolve, reject) => {
                                        //         if (validateEmail(value)) {
                                        //             checkEmailIsRegistered(value).then(result => {
                                        //                 resolve(!result);
                                        //             }).catch(err => {
                                        //                 resolve(false);
                                        //             })
                                        //         }
                                        //         else {
                                        //             resolve(false);
                                        //         }

                                        //     })
                                        // }),

                                        password: Yup.string()
                                            .required('No password provided.')
                                            .min(8, 'Password is too short - should be 8 chars minimum.')
                                            .matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
                                        confirmPassword: Yup.string()
                                            .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                                        // maritalStatus: Yup.string()
                                        // .required('Marital Status Required'),

                                        //   contactdate: Yup.date().default(function() {
                                        //     return new Date();
                                        //   }),
                                        acceptedTerms: Yup.boolean()
                                            .required('Required')
                                            .oneOf([true], 'You must accept the terms and conditions.'),

                                    })}
                                    onSubmit={(values, { setSubmitting, setErrors }) => {
                                        toast.info(<LoadingSpinner message="Please wait...." />, LoadingAlertConfig)
                                        setTimeout(() => {
                                            //  alert(JSON.stringify(values, null, 2));
                                            //  toggleSuccessMessage();
                                           handleFormSubmit(values)
                                            //setSubmitting(false);
                                            setSubmitting(isSubmitting);
                                        }, 400);
                                    }}
                                >

                                    {props => (
                                        <form onSubmit={props.handleSubmit} onReset={props.handleReset} {...props}>
                                            <Row>
                                                <Col md="6">
                                                    <SyncTextInput
                                                        label="First Name"
                                                        iconOrText={<i className="fas fa-user"></i>}
                                                        name="firstName"
                                                        type="text"
                                                        placeholder="First Name"
                                                        isRequired={true}
                                                    />
                                                </Col>
                                                <Col md="6">
                                                    <SyncTextInput
                                                        label="Last Name"
                                                        iconOrText={<i className="fas fa-user"></i>}
                                                        name="lastName"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        isRequired={true}
                                                    />
                                                </Col>
                                                <Col md="12">
                                                    <SyncTextInput
                                                        label="Email"
                                                        iconOrText="@"
                                                        name="email"
                                                        type="text"
                                                        placeholder="Email"
                                                        autoComplete="email"
                                                        isRequired={true}
                                                    />
                                                </Col>
                                                <Col md="12">

                                                    <Field placeholder="Enter Phone Number" isRequired={true}
                                                        country="ng" preferredCountries={['ng', 'gh', 'us']}
                                                        name="phoneNumber" component={SyncPhoneInput2} inputprops={{
                                                            name: "phoneNumber",
                                                            id: "phoneNumber",
                                                            required: true,
                                                            // defaultOption: "None"
                                                        }} />


                                                </Col>

                                                
                                            </Row>
                                            <Row>
                                                <Col md="12">
                                                    <div >
                                                    <SyncTextInput
                                                        // style={{height: "200px"}}
                                                        label="Password"
                                                        iconOrText={<FontAwesomeIcon icon="key" ></FontAwesomeIcon>}
                                                        name="password"
                                                        type={passwordShown ? "text" : "password"}
                                                        placeholder="Password"
                                                        autoComplete="off"
                                                        isRequired={true}
                                                        showAppend={true}
                                                        appendIconOrText={<span className="append-icon"><i className={passwordShown ? "fa fa-eye-slash" : "fa fa-eye"}></i></span>}
                                                        onAppendClick={togglePasswordVisiblity}
                                                    />
                                                    {/* <i className="fa fa-eye"></i> */}
                                                    </div>
                                                   
                                                </Col>
                                                <Col md="12">
                                                    <SyncTextInput
                                                        label="Confirm Password"
                                                        iconOrText={<i className="fa fa-lock"></i>}
                                                        name="confirmPassword"
                                                        type="password"
                                                        placeholder="Confirm Password"
                                                        isRequired={true}
                                                        autoComplete="off"
                                                    />
                                                </Col>
                                                

                                            </Row>
                                            {/* <p className="text-muted">Contact Information</p> */}
                                            
                                            
                                            {/* 
          <MySelect label="Job Type" name="jobType">
            <option value="">Select a job type</option>
            <option value="designer">Designer</option>
            <option value="development">Developer</option>
            <option value="product">Product Manager</option>
            <option value="other">Other</option>
          </MySelect>
          <MyCheckbox name="acceptedTerms">
            I accept the terms and conditions
          </MyCheckbox> */}
                                            <SyncCheckBox name="acceptedTerms" id="acceptedTerms" label="">
                                                By using this application, you agree to the
           <a href="#0"> Terms and Conditions.</a>
                                            </SyncCheckBox>
                                            <div className="ml-auto mt-4">
                                                <button type="submit" className="btn btn-success ml-auto" disabled={!(props.isValid && props.dirty) || props.isSubmitting}>SIGN UP</button>
                                            </div>

                                        </form>
                                    )}
                                    {/* <Form>

                                       
                                    </Form> */}
                                </Formik>


                            </CardBody>
                            <CardFooter>
                                <div>
                                    <Link to="/login">
                                        <h6><strong>Already have an account?</strong></h6>
                                    </Link>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>

            </Container>
            <Modal isOpen={successMessage} toggle={toggleSuccessMessage} backdrop={"static"}
                className={'modal-md modal-confirm'}>
                <ModalHeader toggle={toggleSuccessMessage} close={closeBtn}>
                    <div class="icon-box">
                       <FontAwesomeIcon icon="check-circle" ></FontAwesomeIcon>
                    </div>
                    <h4 >Awesome!</h4>
                </ModalHeader>
                <ModalBody>
                    <p class="text-center">Your account created successfully. Check your email for verification details.</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-block" color="success" onClick={handleFormSuccess}>OK</Button>{' '}
                    {/* <Button color="secondary" onClick={toggleSuccessMessage}>Cancel</Button> */}
                </ModalFooter>
            </Modal>
        </div>

    );
};

export default RegisterUserFormLite;