import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    prospectListScroll: {x: 0, y: 0},
    notificationListScroll: {x: 0, y: 0},
    bucketListScroll: {x: 0, y: 0},
    harvestLocationListScroll: {x: 0, y: 0},
    taskListScroll: {x: 0, y: 0},
    assignedTaskListScroll: {x: 0, y: 0},
    userListScroll: {x: 0, y: 0},
};


const appScrollSlice = createSlice({
    name: 'appScroll',
    initialState,
    reducers: {
        setProspectListScroll: (state,  { payload }) => {
            return { ...state, prospectListScroll : payload  }
        },
        setNotificationListScroll: (state,  { payload }) => {
             return { ...state, notificationListScroll : payload  }
         },
         setBucketListScroll: (state,  { payload }) => {
             return { ...state, bucketListScroll : payload  }
         },
         setHarvestLocationListScroll: (state,  { payload }) => {
              return { ...state, harvestLocationListScroll : payload  }
          },
          setTaskListScroll: (state,  { payload }) => {
             return { ...state, taskListScroll : payload  }
         },
         setAssignedTaskListScroll: (state,  { payload }) => {
              return { ...state, assignedTaskListScroll : payload  }
          },
          setUserListScroll: (state,  { payload }) => {
            return { ...state, userListScroll : payload  }
        }
    }
});



// The Actions
export const { setAssignedTaskListScroll, setBucketListScroll, setHarvestLocationListScroll, setNotificationListScroll,
setProspectListScroll, setTaskListScroll, setUserListScroll} = appScrollSlice.actions;

// A selector
export const appScrollSelector = state => state.appScroll

// The reducer
export default appScrollSlice.reducer
