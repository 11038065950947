import React from 'react';
import { useField } from 'formik';
import { CustomInput } from 'reactstrap';
import {
    CLabel,
    CFormGroup,
    CSwitch
  } from '@coreui/react'

const SyncSwitch = ({ label, showLabel, placeLabelAfter = false, children, size="sm", ...props }) => {
    // We need to tell useField what type of input this is
    // since React treats radios and checkboxes differently
    // than inputs/select/textarea.
    const [field, meta] = useField({ ...props, type: 'checkbox' });
    return (
        <div>
            <CFormGroup check inline>
            {showLabel && !placeLabelAfter ? <CLabel className={`mr-3 ${props.labelColor ? props.labelColor : ""}`} style={{verticalAlign: "bottom"}} for={props.id || props.name} >{label}</CLabel> : ""}
            {/* <Label className="mx-1" style={{verticalAlign: "top"}}>Required</Label> */}
                      <CSwitch
                        {...field} {...props}
                        labelOn={"\u2713"}
                        labelOff={"\u2715"}
                      
                      />
              {showLabel && placeLabelAfter ? <CLabel className={`ml-3 ${props.labelColor ? props.labelColor : ""}`} style={{verticalAlign: "bottom"}} for={props.id || props.name} >{label}</CLabel> : ""}
                {/* <CLabel check>
                  
                    <CustomInput type="checkbox" label={children} inline  {...field} {...props} />
                  
                </CLabel> */}

            </CFormGroup>
            {meta.touched && meta.error ? (
                <div className="error text-danger">{meta.error}</div>
            ) : null}
        </div>


    );
};

export default SyncSwitch