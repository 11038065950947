import React, {Fragment, useState} from 'react';
import { CButton, CSelect, CLabel, CRow, CCol, CInputGroup, CInputGroupAppend, CInputGroupPrepend } from "@coreui/react";
import CIcon from '@coreui/icons-react'
import PropTypes from 'prop-types';
import { useStateWithCallbackLazy } from "../hooks/useStateWithCallback";

const BootstrapTableCustomSearchBar = props => {

    let input;
    const handleSearch = () => {
      props.onSearch(input.value);
    };

    return (
        <Fragment>
        <CRow className="no-gutters mx-2 mt-2 mb-4 ">
<CCol xs="12" md="6" lg="4">
           <CInputGroup >
               <input
                   className="form-control form-control-16"
                   onChange={handleSearch}
                   ref={ n => input = n }
                //    ref={inputRef}
                   style={{

                       width: '200px',
                       borderRadius: '15px 0px 0px 15px',
                       // borderRadius: '0px',
                       // borderLeft: "none",
                       borderRight: "none"
                   }}
                   placeholder="Search by First Name or Last Name"
                   size="16"
                   type="search" />
               <CInputGroupAppend>
                   <CButton size="sm"  color="light" className="float-right">
                       <CIcon name="cil-search" />
                   </CButton>
               </CInputGroupAppend>
           </CInputGroup>
       </CCol>
       </CRow>
     </Fragment>
    );
};

BootstrapTableCustomSearchBar.propTypes = {
    
};

const BootstrapTableMiniCustomSearchBar = ({placeholder = '', ...props}) => {
    const [showSearch, setShowSearch] = useStateWithCallbackLazy(false);
    let input;

    const toggleShowSearch = () => {
        setShowSearch(
          (prevState) => !prevState,
          (prevState) => {
            if (prevState === true) {
                if (input !== null && input !== undefined){
                    input.focus();
                }
             // 
            }
            if (prevState === false) {
                if (input !== null && input !== undefined){
                    input.value = "";
                }
             
            }
          }
        );
        //inputRef.current.focus();
      };


    const handleSearch = () => {
      props.onSearch(input.value);
    };

    return (
        <Fragment>
        <CRow className="no-gutters mx-2 mt-2 mb-4 ">
<CCol xs="12" md="6" lg="4">
           <CInputGroup >
           <CInputGroupPrepend>
                        <button
                          type="button"
                          onClick={() => toggleShowSearch()}
                        //   disabled={!canSave}
                          className="btn btn-light float-right"
                        >
                          <>
                            {" "}
                            <CIcon
                          name={`${showSearch ? "cil-x" : "cil-search"}`}
                        />
                          </>
                        </button>
                      </CInputGroupPrepend>
               <input
                   className="form-control form-control-16"
                   onChange={handleSearch}
                   ref={ n => input = n }
                //    ref={inputRef}
                   style={{
                       display: `${showSearch ? "block" : "none"}`,
                       minWidth: '200px',
                       borderRadius: '0px 15px 15px 0px',
                       minHeight: '38px',
                       // borderRadius: '0px',
                       // borderLeft: "none",
                       borderLeft: "none"
                   }}
                   placeholder={placeholder}
                   size="16"
                   type="search" />
           </CInputGroup>
       </CCol>
       </CRow>
     </Fragment>
    );
};

BootstrapTableMiniCustomSearchBar.propTypes = {
    
};

export default BootstrapTableCustomSearchBar;

export {BootstrapTableMiniCustomSearchBar}