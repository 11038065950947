import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
// import AuthReducer from './reducers/AuthReducer'
import { combineReducers } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import { reducer, loadUser } from 'redux-oidc';
import { connectRouter, routerMiddleware } from 'connected-react-router'
import { createBrowserHistory } from 'history'
import storage from 'redux-persist/lib/storage';
import localforage from 'localforage';
import groupsReducer from './reducers/GroupsReducer'
import myContainerReducer from './reducers/MyContainerReducer'
import serviceWorkerReducer from './reducers/ServiceWorkerReducer'
import sideBarReducer from './reducers/SideBarReducer'
import appScrollReducer from './reducers/AppScrollReducer'
import userConfigStateReducer from './reducers/UserConfigStateReducer'
import nonPersistReducer from './reducers/NonPersistUpdatesReducer'
import tasksReducer from './reducers/TasksReducer'
import invitesReducer from './reducers/InvitesReducer'
import registrationReducer from './reducers/RegistrationReducer'
import { loadingBarReducer } from 'react-redux-loading-bar'
import userManager from "../auth/userManager";

export const history = createBrowserHistory()
const middleware = [routerMiddleware(history)];


const tasksConfig = {
  key: 'tasks',
  storage: localforage,
  blacklist: ['currentGroupFollowUpTasksProspects', 'userFollowUpTasksProspects', 'loadedProspectsTaskId', 'loadedTaskProspects']
}

const invitesConfig = {
  key: 'invitations',
  storage: localforage,
  blacklist: ['userInvitesProspects', 'currentGroupInvitesProspects', 'loadedInvitesProspects', 'allInvitesProspects']
}

const appReducer = combineReducers({
  mycontainer: myContainerReducer,
  groups: groupsReducer,
  tasks: persistReducer(tasksConfig, tasksReducer),
  invitations: persistReducer(invitesConfig, invitesReducer),
  serviceworker: serviceWorkerReducer,
  auth: reducer,
  sideBar: sideBarReducer,
  appScroll: appScrollReducer,
  userConfigState: userConfigStateReducer,
  loadingBar: loadingBarReducer,
  nonPersistUpdates: nonPersistReducer,
  registration: registrationReducer,
  router: connectRouter(history)
  })

  // reset the state of a redux store
const rootReducer = (state, action) => {
  if (action.type === 'RESET_STORE') {
     // for all keys defined in your persistConfig(s)
     storage.removeItem('persist:root')
     localforage.removeItem('persist:root')

     storage.removeItem('persist:tasks')
     localforage.removeItem('persist:tasks')
     // storage.removeItem('persist:otherKey')
     
     var authUrl = process.env.REACT_APP_SOULWIN_AUTHORIZATION_SERVER_URL
     var oidcKey = `oidc.user:${authUrl}:swweb`
     storage.removeItem(oidcKey)
     localforage.removeItem(oidcKey)
     sessionStorage.removeItem(oidcKey);
    // console.log(oidcKey, "Charless")
    state = undefined;
  }
  return appReducer(state, action)
}

  const persistConfig = {
    key: 'root',
    storage: localforage,
    timeout: 5000,
    blacklist: ['router', 'serviceworker', 'appScroll', "loadingBar", "nonPersistUpdates", "tasks"]
  }
  
  const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware,
  });

  

  loadUser(store, userManager);   

  export const persistor = persistStore(store);

// export default configureStore({
//   reducer: {
//     // counter: counterReducer,
//     // oldAuth: AuthReducer,
//     groups: groupsReducer,
//     auth: reducer,
//     router: connectRouter(history)
//   },
//   middleware,
// });

export default store;
