import React from 'react';
import { Formik, Field, useField, useFormikContext} from 'formik';
import { Button, Card, CardBody, CardFooter, Col, Container, Input, InputGroup, InputGroupAddon, InputGroupText, Row, Label, FormGroup } from 'reactstrap';

 const CustomInput = ({ label, iconOrText,
    field, // { name, value, onChange, onBlur }
    // form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
    ...props
  }) => {

    const { values, errors, touched} = useFormikContext();
    const { onAppendClick, isRequired = false, showAppend = false, appendIconOrText = '', showLabel = false, appendStyle = {}, appendClass = "" } = props;
    const icon = iconOrText ?? ""
    let currentValue = values[field.name];
    // console.log(props.name, "CustomInput")
    return (
        <div className="mb-3">
            <FormGroup>
            {showLabel ? <Label for={props.id || props.name} className={props.labelColor}>{label}</Label> : ""}
                <InputGroup>
                <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            {icon}
                        </InputGroupText>
                    </InputGroupAddon>
          {/* <input type="text" {...field} {...props} /> */}
          <Input type="text"  {...field} {...props} 
                    invalid={Boolean(touched[field.name] && errors[field.name])}
                    />
          {showAppend && <InputGroupAddon addonType="append"><Button className={appendClass} style={appendStyle} onClick={onAppendClick} >{appendIconOrText}</Button></InputGroupAddon>}
          <label>{currentValue === "" && isRequired && <span className="text-danger">&nbsp;*</span>}</label>
            </InputGroup>
            {touched[field.name] &&
            errors[field.name] && <div className="error">{errors[field.name]}</div>}
            </FormGroup>
        </div>
      );
    
  } 
  export default CustomInput;