

export const sortObjectArraysToColumnKeyData = (data, headers = []) => {
  let columnData = {};
  if (Array.isArray(data) && data.length > 0 && Array.isArray(headers)) {
    if (headers.length <= 0){
      var firstObject = data[0];
      headers = Object.keys(firstObject);
    }
    headers.map((u) => {
      var headerData = [];
      data.forEach((x) => {
        if (x[u] !== null) {
          headerData.push(x[u]);
        }
      });
      columnData[u] = headerData;
    });
  }

  return columnData;
};



    


