import React, {Fragment} from "react";
import {
  PlainCheckBox,
  PlainCheckBoxList,
  PlainRadioButtonList,
  PlainTextInput,
  PlainSelectInput,
  PlainReactMultiSelectInput,
  PlainReactDatesSinglePicker,
  PlainTypeaheadSelect,
  PlainPhoneInput
} from "./";
import CIcon from "@coreui/icons-react";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import { getAcronym } from "../utils/SyncUtils";
import {DayMonthDatePicker} from '@carlospence/synccomponents-test'
import '@carlospence/synccomponents-test/dist/esm/index.css'


function PlainDynamicInput({
  value,
  onChange,
  htmlType,
  type,
  maxWidth = 450,
  readonly = false,
  disabled = false,
  ...props
}) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  // console.log(props.name, "DynamicInput")
  switch (htmlType) {
    case "text":
      return (
        <PlainTextInput
          label={props?.label}
          // iconOrText={<i className="fa fa-sticky-note"></i>}
          disabled={disabled}
          readonly={readonly}
          iconOrText={getAcronym(props?.name)}
          name={props?.name}
          type="text"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "number":
      return (
        <PlainTextInput
          label={props?.label}
          disabled={disabled}
          readonly={readonly}
          iconOrText={<i className="fa fa-hashtag"></i>}
          name={props?.name}
          type="number"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "tel":
      return (
        <PlainPhoneInput
           label={props?.label}
          placeholder={props?.placeholder}
          isRequired={props?.required}
          disabled={disabled}
          readonly={readonly}
          country="ng"
          preferredCountries={["ng", "gh", "us"]}
          name={props?.name}
          showLabel={true}
          style={{ maxWidth: maxWidth + 50 }}
          inputprops={{
            name: props?.name,
            id: props?.name,
            required: props?.required,
          }}
        >

        </PlainPhoneInput>
      
      );
    case "email":
      return (
        <PlainTextInput
          label={props?.label}
          iconOrText={<i className="fa fa-at"></i>}
          disabled={disabled}
          readonly={readonly}
          // iconOrText="@"
          name={props?.name}
          type="email"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "textarea":
      return (
        <PlainTextInput
          label={props?.label}
          iconOrText={<i className="fa fa-sticky-note"></i>}
          disabled={disabled}
          readonly={readonly}
          name={props?.name}
          type="textarea"
          placeholder={props?.placeholder}
          showLabel={true}
          style={{ maxWidth: maxWidth }}
          // required={props?.required}
          isRequired={props?.required}
        />
      );
    case "dropdown":
      return (
      <PlainSelectInput
         name={props?.name}
          label={props?.label}
          showLabel={true}
          disabled={disabled}
          readonly={readonly}
          // iconOrText={<i className="fa fa-handshake"></i>}
          iconOrText={getAcronym(props?.name)}
          isRequired={props?.required}
          style={{ maxWidth: maxWidth }}
          required={props?.required}
          inputprops={{
            name: props?.name,
            id: props?.name,
            options: props.optionLabels ? props?.optionLabels : props?.options,
            placeholder: props?.placeholder,
            defaultOption: props?.defaultValue,
            isRequired: props?.required,
            disabled: disabled,
            readonly: readonly
          }}
      >

      </PlainSelectInput>
       
      );
    case "checkbox":
      if (Array.isArray(props?.options) && props?.options.length === 0) {
        return (
          <PlainCheckBox
            name={props?.name}
            id={props?.name}
            label={props?.label}
            disabled={disabled}
            readonly={readonly}
          >
            {props?.label}
          </PlainCheckBox>
        );
      }
      if (Array.isArray(props?.options) && props?.options.length > 0) {
        return (
          <PlainCheckBoxList
            name={props?.name}
            label={props?.label}
            showLabel={true}
            isRequired={props?.required}
            disabled={disabled}
            readonly={readonly}
            inline={
              isMobile ? (props?.options.length > 3 ? false : true) : true
            }
            options={props.optionLabels ? props?.optionLabels : props?.options}
            id={props?.name}
            inputprops={{
              name: props?.name,
              id: props?.name,
              options: props.optionLabels ? props?.optionLabels : props?.options,
              placeholder: props?.placeholder,
              defaultOption: props?.defaultValue,
              isRequired: props?.required,
              disabled: disabled,
              readonly: readonly
            }}
            
          ></PlainCheckBoxList>
     
        );
      }
     
      return <> </>;
    case "radio":
      if (Array.isArray(props?.options) && props?.options.length > 0) {
        return (
          <PlainRadioButtonList
            name={props?.name}
            label={props?.label}
            showLabel={true}
            disabled={disabled}
            readonly={readonly}
            // required={props?.required}
            inline={
              isMobile ? (props?.options.length > 3 ? false : true) : true
            }
            isRequired={props?.required}
            options={props.optionLabels ? props?.optionLabels : props?.options}
            id={props?.name}
            inputprops={{
              name: props?.name,
              id: props?.name,
              options: props.optionLabels ? props?.optionLabels : props?.options,
              placeholder: props?.placeholder,
              defaultOption: props?.defaultValue,
              isRequired: props?.required,
              disabled: disabled,
              readonly: readonly
            }}
           
          ></PlainRadioButtonList>
          
        );
      }
      return <> </>;
    case "multiselect":
      if (isMobile) {
        return (
          <PlainSelectInput
         name={props?.name}
          label={props?.label}
          showLabel={true}
          disabled={disabled}
          readonly={readonly}
          // iconOrText={<i className="fa fa-handshake"></i>}
          iconOrText={getAcronym(props?.name)}
          isRequired={props?.required}
          style={{ maxWidth: maxWidth }}
          required={props?.required}
          inputprops={{
            name: props?.name,
            id: props?.name,
            options: props.optionLabels ? props?.optionLabels : props?.options,
            placeholder: props?.placeholder,
            defaultOption: props?.defaultValue,
            isRequired: props?.required,
            disabled: disabled,
            readonly: readonly
          }}
      >

      </PlainSelectInput>
        );
      } else {
        return (
          <PlainReactMultiSelectInput
            name={props?.name}
            iconOrText={<i className="fa fa-sort-numeric-asc"></i>}
            options={props.optionLabels ? props?.optionLabels : props?.options.map((x) => {
              return { value: x, label: x };
            })}
            
            placeholder={props?.placeholder}
            maxWidth={maxWidth}
            showLabel={true}
            label={props?.label}
            hasSelectAll={false}
            selectValueOnly={true}
            disabled={disabled}
            // required={props?.required}
            //onSelectChange={handleSelectChange}
            isRequired={props?.required}
            disableSearch={
              isMobile ? true : props?.options.length > 20 ? false : true
            }
            onMenuToggle={(expanded) => {
              //handleMultiSelectToggle(expanded, "services-select")
            }}
          />
        );
      }
    case "date":
    case "datetime":
      return (
        <PlainReactDatesSinglePicker
          name={props?.name}
          label={props?.label}
          isRequired={props?.required}
          placeholder={props?.placeholder}
          placeholderText={props?.placeholder}
          iconOrText={<i className="fa fa-calendar-day"></i>}
          enableOutsideDays={true}
          showLabel={true}
          maxWidth={maxWidth}
          // isOutsideRange={() => false}
          isOutsideRange={(date) =>
            date.isBefore(moment().subtract(180, "days")) ||
            date.isAfter(moment().add(0, "days"))
          }
          small={true}
          customInputIcon={<CIcon name="cil-calendar" className="mfe-2" />}
          inputIconPosition="after"
          disabled={disabled}
          readonly={readonly}
        ></PlainReactDatesSinglePicker>
      );
      case "monthday":
      return (
        <Fragment>
<label style={{ width: "100%" }}>{props?.label}</label>
 <DayMonthDatePicker
          // className="mb-2"
          onChange={(data) => {
            console.log(data)
          }}
          value={"2023-01-01"}
          name={props?.name}
          label={props?.label}
          isRequired={props?.required}
          placeholder={props?.placeholder}
          placeholderText={props?.placeholder}
          leading={<i className="fa fa-calendar-day mx-1"></i>}
          disabled={disabled}
          readonly={readonly}
          style={{ maxWidth: maxWidth }}
        ></DayMonthDatePicker>
        </Fragment>
       
      );
    case "autocomplete":
      // console.log((props.defaultValue))
      //console.log(JSON.stringify(props))
      var theOptions = props.optionLabels ? props?.optionLabels.map((x) => {
        return { label: x.label, id: x.value };
      }) ?? [] :
        props?.options.map((x) => {
          return { label: x, id: x };
        }) ?? [];


      return (
        <PlainTypeaheadSelect
          {...props}
          name={props?.name}
          label={props?.label}
          isRequired={props?.required}
          placeholder={props?.placeholder}
          placeholderText={props?.placeholder}
          iconOrText={<i className="fa fa-calendar-day"></i>}
          showLabel={true}
          options={theOptions}
          labelKey={"label"}
          maxWidth={maxWidth}
          small={true}
          multiple={props?.multipleSelection}
          defaultSelected={props.defaultValue ?? []}
          customInputIcon={<CIcon name="cil-calendar" className="mfe-2" />}
          disabled={disabled}
          readonly={readonly}
        ></PlainTypeaheadSelect>
      );
   

    default:
      return <div>{`Unknown Type ${htmlType}`}</div>;
  }
}

export default PlainDynamicInput;
