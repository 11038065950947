import React from "react";
// import { useField } from "formik";
import { FormGroup, Label, FormFeedback, CustomInput } from "reactstrap";
import {removeWhiteSpace} from '../utils/SyncUtils'

const PlainCheckBoxList = ({ label, options, children, 
  onChange, invalid = false, valid = true,
  selectedValues = [],
  // form: { touched, errors, setFieldValue, values },
  ...props }) => {
  // We need to tell useField what type of input this is
  // since React treats radios and checkboxes differently
  // than inputs/select/textarea.
  const {showLabel = false, inline = true, inputprops } = props;
 // const [field, meta] = useField({ ...props, ...inputprops, type: "checkbox" });
  //let error = errors[field.name];
 // console.log(field, "Field Information")
//  console.log(props, "Props Information")
  const handleChange = (e) => {

    //console.log(e.target.value)
    var newValues = selectedValues || [];
    const index = newValues.indexOf(e.target.value);
    if (index === -1) {
      newValues.push(e.target.value);
    } else {
      newValues.splice(index, 1);
    }
    if (onChange){
      onChange(newValues);
    }
   
  //  console.log(newValues, "Values Change")
  }
 // console.log(values, "Values");
  var inputs = options.map((x) => {
    let value = x;
    let label = x;
    if (x.label){
      label = x.label;
      value = x.value;
    }
    return (
      <Label check className={inline === false && `mx-2`}>
        <CustomInput value={value}  onChange={(e) => handleChange(e)} type="checkbox" label={label} inline={inline} {...props} id={removeWhiteSpace(value)} />
      </Label>
    );
  });
 
  return (
    <div className="mb-3">
        {showLabel ? <><Label for={props.id || props.name} className={props.labelColor}>{label}</Label><br/></> : ""}
      <FormGroup check inline={inline}>
        {inputs}
      </FormGroup>
      {/* {meta.touched && meta.error ? (
        <div className="error text-danger">{meta.error}</div>
      ) : null} */}
       {/* {touched[field.name] && errors[field.name] && (
                <div className="error text-danger">&nbsp;{errors[field.name]}</div>
            )}
            {error && <FormFeedback>{error}</FormFeedback>} */}
    </div>
  );
};

export default PlainCheckBoxList;
