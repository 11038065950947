import config, {API_HOST} from '../config';
import qs, {stringify} from 'query-string'
import {kaappsHeader, authHeader, allHeader} from './helpers'
import {isEmpty} from './SyncUtils'

export async function testApi() {
   
    ///var url = 'http://localhost:8070' + "/api/v1/place/getplaces/" 
    var url = API_HOST + "/api/v1/soulwin/registration/Identity" 
    // console.log(API_HOST)
    // console.log(url)
   
        try
        {
            let response = await fetch(url);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function testServerUI() {
   
    ///var url = 'http://localhost:8070' + "/api/v1/place/getplaces/" 
    var url = API_HOST + "/account/testemails" 
    // console.log(API_HOST)
    // console.log(url)
   
        try
        {
            let response = await fetch(url);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function testServerUIv2() {
   
    ///var url = 'http://localhost:8070' + "/api/v1/place/getplaces/" 
    var url = API_HOST + "/account/confirmemails" 
    // console.log(API_HOST)
    // console.log(url)
   
        try
        {
            let response = await fetch(url);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function testPostApi(query = {}) {
   
    ///var url = 'http://localhost:8070' + "/api/v1/place/getplaces/" 
    //query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/registration/AddUser"
    const config = {
        headers: {...kaappsHeader(), 'Content-Type': 'application/json'},
        method: "post",
      body: JSON.stringify(query)
    }; 
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function testSecurePostApi(query = {}) {
   
    ///var url = 'http://localhost:8070' + "/api/v1/place/getplaces/" 
    //query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/Index"

  var  header = await allHeader();

    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "get",
    //   body: JSON.stringify(query)
    }; 
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function registerUser(query) {
   
   // console.log(...authHeader())
    var url = API_HOST + "/api/v1/soulwin/registration/AddUser";
    const config = {
        headers: {...kaappsHeader(), 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(query)
    };
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     console.log(errorMessage);
                     reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function verifyCode(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/registration/VerifyCode";
     const config = {
         headers: {...kaappsHeader(), 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }
 
  export async function resendCode(email, phoneNumber) {
   
    var query = {email: email, phoneNumber : phoneNumber}
    let queryString  = stringify(query, {sort: false})
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/registration/ResendCode?" + queryString 
     const config = {
         headers: {...kaappsHeader(), 'Content-Type': 'application/json'},
         method: "GET",
        //  body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }
 

 export async function checkEmailIsRegistered(email) {
   
    var query = {email: email}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/userpro/CheckEmailIsRegistered?" + query 
   
   
        try
        {
            let response = await fetch(url);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function checkEmailIsContainerUser(email, containerGuid) {
   
    var query = {email: email, containerGuid: containerGuid}
    let queryString  = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/CheckEmailExistingInContainer?" + queryString
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function checkPhoneIsContainerUser(phoneNumber, containerGuid) {
   
    var query = {phoneNumber: phoneNumber, containerGuid: containerGuid}
    let queryString  = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/CheckPhoneNumberExistingInContainer?" + queryString
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function checkPhoneNumberIsRegistered(phone) {
   
    var query = {phone: phone}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/userpro/CheckPhoneNumberIsRegistered?" + query 
   
   
        try
        {
            let response = await fetch(url);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function checkGroupNameIsExisting(name) {
   
    var query = {name: name}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/CheckGroupNameExisting?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function createGroup(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AddGroup";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function inviteMember(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AddUsertoContainer";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function inviteMemberByEmail(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AddUsertoContainerByEmail";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function inviteMemberByPhone(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AddUsertoContainerByPhone";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function resendMemberInvite(containerUserGuid, containerGuid) {
   
    var query = {containerUserGuid, containerGuid}
    let queryString  = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/ResendInvite?" + queryString
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

  export async function getUserProfile(username) {
   
    var query = {username: username}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/UserGroups?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

  export async function getUserGroups(username, containerSearchType = "Groups", primaryContainerGuid = "") {
   
    var query = {username: username, containerSearchType, primaryContainerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/UserGroups?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function getUserGroup(email, containerGuid) {
   
    var query = {username: email, containerGuid: containerGuid}
    //let queryString  = stringify(query, {sort: false})
    //var query = {username: username}
    let queryString = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/UserGroup?" + queryString 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function removeUserFromGroup(email, containerGuid) {
   
    var query = {username: email, containerGuid: containerGuid}
    //let queryString  = stringify(query, {sort: false})
    //var query = {username: username}
    let queryString = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/RemoveUserFromGroup?" + queryString 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function removeUserFromGroupByPhone(phoneNumber, containerGuid) {
   
    var query = {phoneNumber: phoneNumber, containerGuid: containerGuid}
    //let queryString  = stringify(query, {sort: false})
    //var query = {username: username}
    let queryString = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/RemoveUserFromGroupByPhone?" + queryString 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function changeUserRoleInGroup(query) {
   
   // var query = {username: email, containerGuid: containerGuid, role: role};
    //let queryString  = stringify(query, {sort: false})
    //var query = {username: username}
    let queryString = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/ChangeGroupUserRole?" + queryString 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function updateGroupInfo(query) {
   
    // var query = {username: email, containerGuid: containerGuid, role: role};
     //let queryString  = stringify(query, {sort: false})
     //var query = {username: username}
     let queryString = stringify(query, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/UpdateGroupInfo?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "GET"
     };
    
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      reject({errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

 export async function acceptInviteToGroup(email, containerGuid) {
   
     var query = {username: email, containerGuid: containerGuid};
     //let queryString  = stringify(query, {sort: false})
     //var query = {username: username}
     let queryString = stringify(query, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/AcceptInviteFromGroup?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "GET"
     };
    
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      reject({errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function checkBucketNameIsExisting(name, containerGuid) {
   
    var query = {name: name, containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/bucket/CheckBucketNameInContainer?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }



  export async function createBucket(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/bucket/AddBucket";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function changeBucketStatus(bucketGuid, containerGuid, status) {
   
    
    var query = {bucketGuid: bucketGuid, containerGuid: containerGuid, status: status}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/bucket/ChangeBucketStatus?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function updateBucket(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/bucket/Update";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

 export async function removeBucket(bucketGuid, containerGuid) {
   
    
    var query = {bucketGuid: bucketGuid, containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/bucket/RemoveBucket?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }





 export async function checkHarvestLocNameIsExisting(name, containerGuid) {
   
    var query = {name: name, containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/harvest/CheckHarvestLocationNameInContainer?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }



  export async function createHarvestLoc(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/harvest/AddHarvestLocation";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function changeHarvestLocStatus(harvestLocationGuid, containerGuid, status) {
   
    
    var query = {harvestLocationGuid: harvestLocationGuid, containerGuid: containerGuid, status: status}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/harvest/ChangeHarvestLocationStatus?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function updateHarvestLoc(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/harvest/Update";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

 export async function removeHarvestLoc(harvestLocationGuid, containerGuid) {
   
    
    var query = {harvestLocationGuid: harvestLocationGuid, containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/harvest/RemoveHarvestLocation?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function updateContainerSetting(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/UpdateContainerSettings";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function registerProspect(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/Add";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function registerProspects(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/Batch";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function attachProspect(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/attach";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function updateProspect(query) {
   
    // console.log(...authHeader())
   
     var url = API_HOST + "/api/v1/soulwin/prospect/Update";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function followUpProspect(query, prospectGuid, containerGuid) {
   
    // console.log(...authHeader())
    var queryString = {prospectGuid, containerGuid}
    queryString = stringify(queryString, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/prospect/FollowUp?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function followUpProspects(query, containerGuid) {
   
    // console.log(...authHeader())
    var queryString = {containerGuid}
    queryString = stringify(queryString, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/prospect/FollowUpProspects?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function deleteProspect(prospectGuid, containerGuid) {
   
    
    var query = {prospectGuid, containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/prospect/Delete?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function deleteProspects(prospectGuids, containerGuid) {
   
    
    var query = {prospectGuids, containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/prospect/DeleteProspects?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function sendSmsToProspects(prospectGuids, containerGuid, message, taskGuid) {
   
    
    var query = {containerGuid, message, taskGuid}
    if (taskGuid == null ) {
        query = {containerGuid, message}
    }
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/prospect/SendBatchSMS?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(prospectGuids)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function addPhoneCallFollowUp(prospectGuids, containerGuid, message, taskGuid) {
   
    
    var query = {containerGuid, message, taskGuid}
    if (taskGuid == null ) {
        query = {containerGuid, message}
    }
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/prospect/AddPhoneCall?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(prospectGuids)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function sendGroupNotification(role, containerGuid, message) {
   
    
    var query = {role, containerGuid, message}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/SendNotification?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET",
        // body: JSON.stringify(prospectGuids)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function sendNotificationToMembers(userGuids, containerGuid, message) {
   
    
    var query = {containerGuid, message}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/SendUserNotification?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(userGuids)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function resendEmailActivation(username) {
   
    var query = {username: username}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/user/SendEmailActivation?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function getSoulWinOptions(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/utils/OptionsByContainerGuid?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function getPlatformOptions() {
   
   // var query = {containerGuid: containerGuid}
  //  query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/kaapps/utils/PlatformOptions"
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function getSoulWinOptionTypes() {
   
    //var query = {containerGuid: containerGuid}
    //query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/utils/OptionTypes"
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function removeOptionTag(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/RemoveOptionTag";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function addOptionTag(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AddOptionTag";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function updateOptionTag(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/UpdateOptionTag";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function updateOptionTags(optionTags, email, containerGuid, optionTagType) {
   
    // console.log(...authHeader())
    var queryString = {email, containerGuid, optionTagType}
    queryString = stringify(queryString, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/UpdateOptionTags?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(optionTags)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function alterOptionTag(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AlterOptionTag";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function alterOptionTags(optionTags, email, containerGuid, optionTagType) {
   
    // console.log(...authHeader())
    var queryString = {email, containerGuid, optionTagType}
    queryString = stringify(queryString, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/AlterOptionTags?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(optionTags)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function addGroupLabel(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AddLabel";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function addGroupLabelType(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/AddLabelType";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function removeGroupLabel(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/RemoveLabel";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function removeGroupLabelType(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/RemoveLabelType";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function updateGroupLabel(query, allowAttendance) {
   
    // console.log(...authHeader())
    var queryParams = {allowAttendance}
    queryParams = stringify(queryParams, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/UpdateLabel?" + queryParams;
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function updateGroupLabelType(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/container/UpdateLabelType";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }



  export async function addUserLabels(labels, email, containerGuid) {
   
    // console.log(...authHeader())
    var queryString = {email, containerGuid}
    queryString = stringify(queryString, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/AddUserLabels?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(labels)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function clearUserLabels(email, containerGuid) {
   
    // console.log(...authHeader())
    var queryString = {email, containerGuid}
    queryString = stringify(queryString, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/clearUserLabels?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
        //  body: JSON.stringify(labels)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function updateUserProfile(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/user/UpdateProfile";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function markAsRead(notificationGuid) {
   
    var query = {notificationGuid: notificationGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/notification/MarkAsRead?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function markAllAsRead() {
   
   // var query = {userGuid: userGuid}
   // query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/notification/MarkAllAsRead";
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
           try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function dashBoardQuery(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/dashboard";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function reportQuery(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/report";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function addProspectLabels(labels, prospectGuid, containerGuid) {
   
    // console.log(...authHeader())
    var queryString = {prospectGuid, containerGuid}
    queryString = stringify(queryString, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/prospect/AddProspectLabels?" + queryString 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(labels)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function getProspects(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/search";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function getProspectsSharingInfo(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/GetProspectsSharingInfo";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function searchProspects(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/fastsearch";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function exportReport(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/soulwinreports/export";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }



 export async function exportPDF(query) {
   
    var searchQuery =  JSON.stringify(query)
     
    var query = {searchQueryString: searchQuery}
     query = stringify(query, {sort: false})
     var url = API_HOST + "/soulwinreports/exportpdf?" + query 
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json', 'Content-Disposition': 'attachment; filename="Acuivities109.pdf"'},
         method: "GET"
     };
    
    
         try
         {
            fetch(url, config)
            .then( res => res.blob() )
            .then( blob => {
              var file = window.URL.createObjectURL(blob);
              window.location.assign(file);
            });
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      reject({errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function addFollowUpTask(followUpTask) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/task/addfollowuptask";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(followUpTask)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function testSubscription() {
  
    // var serializedSub = JSON.parse(JSON.stringify(subscription))
    var sub = {};
    // sub["endpoint"] = subscription.endpoint;   
    // sub["key"] = serializedSub.keys["p256dh"]
    // sub["authSecret"] = serializedSub.keys["auth"]
  
    var  header = await allHeader();
  //   console.log(sub);
    return fetch(`${API_HOST}/api/v1/syncpush/test-notification`, {
      method: 'POST',
      body: JSON.stringify(sub),
      headers: {
          ...header, 
        'Content-Type': 'application/json'
      }
    })
  }



  export async function prospectInvites(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/invites";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function invitesDashboard(searchQuery) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/prospect/UserAndGroupInvites";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(searchQuery)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function updatePendingInvites(containerGuid, prospectGuid, followUpGuid, inviteResponse) {
   
    
    var query = {containerGuid, prospectGuid, followUpGuid, inviteResponse}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/prospect/UpdateInviteResponse?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        // body: JSON.stringify(prospectGuids)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function getUserGroupTasks(email, containerGuid) {
   
    var query = {username: email, containerGuid: containerGuid}
    //let queryString  = stringify(query, {sort: false})
    //var query = {username: username}
    let queryString = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/UserGroupTasks?" + queryString 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function getBeginnersFormDefinition(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/BeginnerClassDynamicForm?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function saveBeginnersForm(containerGuid, formValues = {}) {
    
    let formData = new FormData();
    if (!isEmpty(formValues)){
        Object.keys(formValues).forEach(u => {
        //   console.log(u, formValues[u], "Recycle")
          formData.append(u, formValues[u])
        })
    }
    var query = {containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/SaveBeginnerClassDynamicForm?" + query 
    var  header = await allHeader();
    const config = {
        // headers: {...header, 'Content-Type': 'application/json'},
        headers: {...header},
        method: "POST",
        body: formData
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
 }

 export async function getBeginnersData(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/GetBeginnerClassList?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }





 export async function getOutreachFormDefinition(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/OutreachDynamicForm?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function saveOutreachForm(containerGuid, formValues = {}) {
    
    let formData = new FormData();
    if (!isEmpty(formValues)){
        Object.keys(formValues).forEach(u => {
        //   console.log(u, formValues[u], "Recycle")
          formData.append(u, formValues[u])
        })
    }
    var query = {containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/SaveOutreachDynamicForm?" + query 
    var  header = await allHeader();
    const config = {
        // headers: {...header, 'Content-Type': 'application/json'},
        headers: {...header},
        method: "POST",
        body: formData
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
 }

 export async function getOutreachData(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/GetOutreachList?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }









 export async function getMembershipFormDefinition(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/MembershipDynamicForm?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function saveMembershipForm(containerGuid, formValues = {}) {
    
    let formData = new FormData();
    if (!isEmpty(formValues)){
        Object.keys(formValues).forEach(u => {
        //   console.log(u, formValues[u], "Recycle")
          formData.append(u, formValues[u])
        })
    }
    var query = {containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/SaveMembershipDynamicForm?" + query 
    var  header = await allHeader();
    const config = {
        // headers: {...header, 'Content-Type': 'application/json'},
        headers: {...header},
        method: "POST",
        body: formData
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
 }

 export async function getMembershipData(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/GetMembershipList?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function getDynamicFormDefinitions(containerGuid, formType) {
   
    var query = {containerGuid: containerGuid, formType: formType}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/GetDynamicFormDefinitions?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function getDynamicFormPosts(containerGuid, formType) {
   
    var query = {containerGuid: containerGuid, formType: formType}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/GetDynamicFormPosts?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function saveDynamicFormPost(containerGuid, formType,  formValues = {}) {
    
    let formData = new FormData();
    if (!isEmpty(formValues)){
        Object.keys(formValues).forEach(u => {
        //   console.log(u, formValues[u], "Recycle")
          formData.append(u, formValues[u])
        })
    }
    var query = {containerGuid: containerGuid, formType: formType}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/SaveDynamicFormPost?" + query 
    var  header = await allHeader();
    const config = {
        // headers: {...header, 'Content-Type': 'application/json'},
        headers: {...header},
        method: "POST",
        body: formData
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
 }


 export async function updateDynamicForm(containerGuid, formDataId, formValues = {}) {
    
    let formData = new FormData();
    if (!isEmpty(formValues)){
        Object.keys(formValues).forEach(u => {
        //   console.log(u, formValues[u], "Recycle")
          formData.append(u, formValues[u])
        })
    }
    var query = {containerGuid, formDataId}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/UpdateContainerDynamicForm?" + query 
    var  header = await allHeader();
    const config = {
        // headers: {...header, 'Content-Type': 'application/json'},
        headers: {...header},
        method: "POST",
        body: formData
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
 }



 export async function updateDynamicFormDefinition(containerGuid, formType, definition = {}) {
    
   
    var query = {containerGuid, formType}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/UpdateDynamicFormDefinition?" + query;
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        // headers: {...header},
        method: "POST",
        body: JSON.stringify(definition)
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
 }



 export async function updateRolePermissions(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/user/UpdateRolePermissions";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }


  export async function updateOperationPermissionSettings(query) {
   
    // console.log(...authHeader())
     var url = API_HOST + "/api/v1/soulwin/user/UpdateOperationPermissions";
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(query)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  export async function getGroupLabelsInfo(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/GetGroupLabelsInfo?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function getGroupAttendances(containerGuid) {
   
    var query = {containerGuid: containerGuid}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/GetGroupAttendances?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function activateGroupAttendances(containerGuid, name, allowSyncMembers) {
   
    var query = {containerGuid, name, allowSyncMembers}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/ActivateGroupAttendances?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function createGroupAttendance(containerGuid, name, allowSyncMembers) {
   
    var query = {containerGuid, name, allowSyncMembers}
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/CreateGroupAttendance?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "GET"
    };
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 export async function addOrRemoveAttendanceMembers(membersId, containerGuid, attendanceGuid, isAdd = true) {
   
    
    var query = {containerGuid, attendanceGuid, isAdd}
   
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/AddOrRemoveGroupAttendanceMembers?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(membersId)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 export async function addOrRemoveAttendanceMeeting(containerGuid, attendanceGuid, meetingData, isAddOrUpdate = true) {
   
    // console.log(...authHeader())
    var query = {containerGuid, attendanceGuid, isAddOrUpdate}
    query = stringify(query, {sort: false})
     var url = API_HOST + "/api/v1/soulwin/container/AddOrUpdateGroupAttendanceMeeting?" + query;
     var  header = await allHeader();
     const config = {
         headers: {...header, 'Content-Type': 'application/json'},
         method: "POST",
         body: JSON.stringify(meetingData)
     };
    
         try
         {
             let response = await fetch(url, config);
             if (!response.ok)
             {
                 throw response;
             }
             let responseJson = await response.json(); //we only get here if there is no error
             return responseJson;
         } catch(error){
            console.log(error);
            return new Promise((resolve, reject) => {
              if (error.text)
              {
                  error.text().then(errorMessage => {
                      console.log(errorMessage);
                      reject({errorMessage: errorMessage, isError: true, errorType: "Response Error"})
                  })
              }
              else
              {
                  reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
              }
            })
         }
     
  }

  /**
  *Add or Remove Local Guest in Attendance
  *
  * @export
  * @param {*} guestsId
  * @param {*} containerGuid
  * @param {*} attendanceGuid
  * @param {boolean} [isAdd=true]
  * @return {*} 
  */
  export async function addUpdateOrRemoveAttendanceLocalGuest(guestInfo, containerGuid, attendanceGuid, isAddOrUpdate = true) {
   
    
    var query = {containerGuid, attendanceGuid, isAddOrUpdate}
   
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/AddOrUpdateGroupAttendanceLocalGuest?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(guestInfo)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 

 /**
  *Add or Remove Guest (Local or Global) into a particular Attendance Members
  *
  * @export
  * @param {*} guestsId
  * @param {*} containerGuid
  * @param {*} attendanceGuid
  * @param {boolean} [isAdd=true]
  * @return {*} 
  */
 export async function addOrRemoveGuestInAttendanceMembers(guestsId, containerGuid, attendanceGuid, isAdd = true) {
   
    
    var query = {containerGuid, attendanceGuid, isAdd}
   
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/AddOrRemoveAttendanceGuestInMembers?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(guestsId)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }


 /**
  *Remove people (Guest or Membership) from Attendance
  *
  * @export
  * @param {array} membersInfo an array of object {id: 1, source: "membership"}
  * @param {*} containerGuid
  * @param {*} attendanceGuid
  * @param {boolean} [isAdd=true]
  * @return {*} 
  */
  export async function removeAttendanceMembers(membersInfo = [], containerGuid, attendanceGuid) {
   
    
    var query = {containerGuid, attendanceGuid}
   
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/RemoveAttendanceMembers?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(membersInfo)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }

 /**
  *Remove person (Guest or Membership) from Attendance
  *
  * @export
  * @param {object} memberInfo object {id: 1, source: "membership"}
  * @param {*} containerGuid
  * @param {*} attendanceGuid
  * @param {boolean} [isAdd=true]
  * @return {*} 
  */
  export async function removeAttendanceMember(memberInfo, containerGuid, attendanceGuid) {
   
    
    var query = {containerGuid, attendanceGuid}
   
    query = stringify(query, {sort: false})
    var url = API_HOST + "/api/v1/soulwin/container/RemoveAttendanceMember?" + query 
    var  header = await allHeader();
    const config = {
        headers: {...header, 'Content-Type': 'application/json'},
        method: "POST",
        body: JSON.stringify(memberInfo)
    };
   
   
        try
        {
            let response = await fetch(url, config);
            if (!response.ok)
            {
                throw response;
            }
            let responseJson = await response.json(); //we only get here if there is no error
            return responseJson;
        } catch(error){
           console.log(error);
           return new Promise((resolve, reject) => {
             if (error.text)
             {
                 error.text().then(errorMessage => {
                     reject({errorMessage, isError: true, errorType: "Response Error"})
                 })
             }
             else
             {
                 reject({isError: true, errorMessage: error.message, errorType: "Unknown Error"})  // Hardcoded error here
             }
           })
        }
    
 }



