import React, {Fragment, useRef, useEffect} from 'react'
import { useField } from "formik";
import { FormGroup, Label } from "reactstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import {splitCamelCase} from '../utils/SyncUtils'


const SyncSelectSearch = ({ field,
    form: { touched, errors, setFieldValue, values },
    ...props }) => {
    const [meta, helper] = useField(field.name);
    const {showLabel = false, inline = true, inputprops, innerStyle = {} } = props;
    // const { error, touched } = meta;
    let error = errors[field.name];
    let touch = touched[field.name];
    const typeaheadRef = useRef(null);
  //   const [selections, setSelections] = useState([]);
  //   useEffect(() => {
  //      if (!multiple){
  //             const value = selections.length > 0 ? selections[0].label : "";
  //             helper.setValue(value);
  //            } else {
  //             helper.setValue(selections.map(x => x.label));
  //            }
  // }, [selections])
    useEffect(() => {
      //typeaheadRef.current.clear();
      // console.log(props, field.name,  "TypeHead", inputprops.allowNew, inputprops.options)

    }, [])

    return (
      <Fragment>
        <FormGroup controlId={`form-${field.name}`}>
          {props.showLabel && <Label>{props.label}</Label>}
          <Typeahead
            // {...inputprops}
            {...field}
            {...props}
           clearButton
           ref={typeaheadRef}
            id={field.name}
            multiple={inputprops.multiple}
            // style={innerStyle}
            // selected={selections}
            // onChange={setSelections}
            onChange={(selected) => {
             // console.log(selected)
             if (!inputprops.multiple){
              const value = selected.length > 0 ? selected[0].label : "";
              setFieldValue(field.name, value);
             } else {
              // const value = selected.length > 0 ? selected[0] : "";
              //helper.setValue(selected.map(x => x.label));
              setFieldValue(field.name, selected.map(x => x.label));
              typeaheadRef.current.toggleMenu();
             }
            
            }}
            onInputChange={(text) => setFieldValue(field.name, text)}
            placeholder={inputprops.placeholder}
            // onBlur={() => helper.setTouched(true)}
            allowNew={inputprops.allowNew}
            labelKey={inputprops.labelKey}
            newSelectionPrefix={`Add a new ${splitCamelCase(field.name)} : `}
            options={inputprops.options}
            inputProps={{ style: innerStyle }}
            // {...(meta.touched && meta.error
            //   ? { isInvalid: true, className: "is-invalid" }
            //   : meta.touched ? { isValid: true} : {})}
            // {...props}
          />
          {/* {meta.touched && meta.error ? (
            <div className="invalid-feedback">{meta.error}</div>
          ) : null} */}
        </FormGroup>
        {/* <pre>{...props}</pre> */}
           {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      </Fragment>
    );
  };


  export default SyncSelectSearch