import React from "react";
import { css } from "goober";
// import { FormGroup, Label, Input, CustomInput } from 'reactstrap';
// import { SyncCheckBox } from './'
import 'pretty-checkbox/dist/pretty-checkbox.css'

const DefaultRenderer = css({
  "& input,& span": {
    //   verticalAlign: "middle",
    margin: 0,
  },
  span: {
    display: "inline-block",
    paddingLeft: "15px",
  },
  "&.disabled": {
    opacity: 0.5,
  },
});

const MultiSelectItemRenderer = ({
  checked,
  option,
  onClick,
  disabled,
}) => {
  return (
    <div
      className={`${DefaultRenderer} item-renderer ${disabled && "disabled"}`}
    >
      <div className="pretty p-svg p-curve">
        <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
        />

        <div className="state p-success">
        <svg class="svg svg-icon" viewBox="0 0 20 20">
                <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style={{stroke: "white",fill:"white"}}></path>
            </svg>
          <label className="ml-2">{option.label}</label>
        </div>

      </div>

      {/* <FormGroup check inline>
                <Label check>
                   
                  
                </Label>
                <CustomInput type="checkbox" onChange={onClick} label={option.label} inline disabled={disabled} checked={checked} />
            </FormGroup> */}
{/* <input
          type="checkbox"
          onChange={onClick}
          checked={checked}
          tabIndex={-1}
          disabled={disabled}
        />
      <span>{option.label}</span> */}
    </div>
  );
};

export const multiSelectToggle = (expanded, classsName) => {
  var selectElement = document.getElementsByClassName(classsName)
  if (expanded) {

    if (selectElement !== null && selectElement !== undefined) {
      selectElement[0].classList.add("select-panel");
    }

  }
  else {
    if (selectElement !== null && selectElement !== undefined) {
      selectElement[0].classList.remove("select-panel");
    }
  }
  // console.log(expanded);
}

export default MultiSelectItemRenderer;