/* eslint-disable no-unused-vars */
import React, { Component, Suspense, useState, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
// import { AppPageHeader } from '../components'
import { UncontrolledAlert, Spinner, Container, Button, ButtonGroup, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
// import { Link, useHistory } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive'
// import { toast } from 'react-toastify';
import { GoogleMap, useLoadScript, Autocomplete, Marker, DrawingManager, Polygon } from '@react-google-maps/api'
import { getGoogleLocationDetails, getGoogleLocationCoordinates } from '../../utils/location'
import classNames from 'classnames'
import Geocode from 'react-geocode'
import {
    geocodeByPlaceId,
} from 'react-places-autocomplete';

const libraries = ["places", "drawing"];


const center = {
    lat: 6.6015324999999994,
    lng: 3.3968535
}
// const center = { lat: 24.886, lng: -70.268 }

const paths = [
  { lat: 25.774, lng: -80.19 },
  { lat: 18.466, lng: -66.118 },
  { lat: 32.321, lng: -64.757 },
  { lat: 25.774, lng: -80.19 }
]

const options = {
    fillColor: "lightblue",
    fillOpacity: 1,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1
  }

function MapPoints({ onMarkerDragEnd, ...props }) {

    const { container } = useSelector(state => state.mycontainer)
    const isMobile = useMediaQuery({ maxWidth: 767 })
    const loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>
    const loadingSpinner = () => <div style={{ width: '100%', height: '100%' }} className="animated fadeIn pt-1 text-center"><Spinner></Spinner></div>
    Geocode.setApiKey(container.settings.mapsApiKey);
    const [locationPaths, setLocationPaths] = useState(props.paths || []);
    const [mapOverlays, setMapOverlays] = useState([]);
    const [locationName, setLocationName] = useState('');
    const [mapCenter, setMapCenter] = useState(props.latlng || center);
    const [locationLngLat, setLocationLngLat] = useState(props.latlng || center);
    const [locationDetails, setLocationDetails] = useState({});
    const [allowAddLocation, setAllowAddLocation] = useState(false);
    const [showConfirmLocationForm, setShowConfirmLocationForm] = useState(false);
    const [isHybrid, setIsHybrid] = useState(false);
    const { isLoaded, loadError, url } = useLoadScript({
        id: "map",
        googleMapsApiKey: container.settings.mapsApiKey,
        libraries // ,
        // ...otherOptions
    })
    const [searchBoxValue, setSearchBoxValue] = useState(props.address || '');
    const drawingManager = useRef(null);;
    let addressMap = useRef(null);
    let searchBox = useRef(null);

    const handleAddMapAddress = () => {
        // setShowAddHarvestLocationForm(!showAddHarvestLocationForm);
        setShowConfirmLocationForm(!showConfirmLocationForm)

    }

    const handleSelectionSuccess = () => {
        setShowConfirmLocationForm(!showConfirmLocationForm)
        let mapInfo = { address: locationName, latlng: locationLngLat, details: locationDetails, paths: locationPaths }
        props.onSelectSuccess(mapInfo)
        // console.log("Selection Successfull")
    }

    const onMapLoad = (map) => {
        console.log('map: ', map)
        addressMap.current = map;
    }

    const onMapClick = (clickDetails) => {
        console.log(clickDetails)
        const newLocation = { lat: clickDetails.latLng.lat(), lng: clickDetails.latLng.lng() };
        let paths = [];
       paths.push({ latitude: clickDetails.latLng.lat(), longitude: clickDetails.latLng.lng() });
       // paths.push(newLocation)
        setLocationPaths(paths)
        setLocationLngLat(newLocation)
        console.log('MapClick: ', newLocation)
        if (clickDetails.placeId) {
            geocodeByPlaceId(clickDetails.placeId).then(result => {
                console.log(result[0]);
                const address = result[0].formatted_address;
                setSearchBoxValue(address)
                var res = getGoogleLocationDetails(result[0]);
                console.log(address)
                setLocationName(address)
                setAllowAddLocation(true);
                setLocationDetails(result[0]);

            })

        }
        else {
            Geocode.fromLatLng(newLocation.lat, newLocation.lng).then(
                response => {
                    const address = response.results[0].formatted_address;
                    var result = getGoogleLocationDetails(response.results[0]);
                    setSearchBoxValue(address)
                    console.log(address)
                    setLocationName(address)
                    setAllowAddLocation(true);
                    setLocationDetails(result[0]);
                },
                error => {
                    console.error(error);
                }
            );
        }

    }

    const onMapDbClick = (clickDetails) => {
        console.log('MapDbClick: ', clickDetails)
    }

    const onLoad = (autocomplete) => {
        console.log('autocomplete: ', autocomplete)
        searchBox.current = autocomplete;
    }

    const onLoadDrawing = (drawing) => {
        console.log('drawingManager: ', drawing)
        drawingManager.current = drawing;
    }
    const onLoadPolygon = polygon => {
        console.log("polygon: ", polygon);
      }


    const changeMapType = () => {
        setIsHybrid(!isHybrid)
    }

    const onPolygonComplete = poly => {
        // console.log(poly)
        const polyArray = poly.getPath().getArray();
        let paths = [];
        polyArray.forEach(function (path) {
            paths.push({ latitude: path.lat(), longitude: path.lng() });
        });
        if (paths.length > 0) {
            setLocationPaths(paths);
            let path = paths[0];
            Geocode.fromLatLng(path.latitude, path.longitude).then(
                response => {
                    const address = response.results[0].formatted_address;
                    setSearchBoxValue(address)
                    var result = getGoogleLocationDetails(response.results[0]);
                    if (result.route !== '') {
                        // console.log(result.route);
                        setLocationName(result.route)

                    }
                    else {
                        // console.log(address);
                        setLocationName(address)
                    }


                    setAllowAddLocation(true);
                },
                error => {
                    console.error(error);
                }
            );

        }

        console.log(paths)
    }

    const clearMap = () => {
        for (var i = 0; i < mapOverlays.length; i++) {
            if (mapOverlays[i].overlay) {
                mapOverlays[i].overlay.setMap(null);
            }

        }
        setMapOverlays([]);
        setAllowAddLocation(false);
        setLocationPaths([]);

    }

    const onOverlayComplete = overlay => {
        //harvestMap.current = overlay;
        console.log("overlay here")
        let overlays = mapOverlays;
        overlays.push(overlay)
        setMapOverlays(overlays);
        console.log(mapOverlays)
    }


    const onPlaceChanged = () => {
        //  console.log(place)
        //  console.log("Got here")
        if (searchBox.current !== null) {

            console.log(searchBox.current)
            var placeDetails = searchBox.current.getPlace()
            //autocompleteObj.getPlace();
            console.log(placeDetails)
            // console.log(placeDetails.formatted_address)
            setLocationDetails(placeDetails)
            setLocationName(placeDetails.formatted_address)
            setSearchBoxValue(placeDetails.formatted_address)
            //console.log(getGoogleLocationCoordinates(autocompleteObj.getPlace()))
            let result = getGoogleLocationCoordinates(placeDetails);
            var newCenter = {
                lat: result.latitude,
                lng: result.longitude
            }
            console.log(newCenter)
            setMapCenter(newCenter)
            setLocationLngLat(newCenter)
            let paths = [];
            paths.push({ latitude: result.latitude, longitude: result.longitude });
            //paths.push(newCenter)
            setLocationPaths(paths)
            setAllowAddLocation(true);
        } else {
            console.log(searchBox.current)
            console.log('Autocomplete is not loaded yet!')
        }
    }
    if (loadError) {
        // toast.error("Unable to load maps and places now, sorry, please check your internet connection");
        //props.onCancel();
        return <UncontrolledAlert color="danger" style={{ width: '100%', height: '100%' }} className="animated fadeIn pt-1 text-center mt-2">
            Unable to load maps and places now, sorry, please check your internet connection
        </UncontrolledAlert>
    }
    const lead = { style: '', color: '', classes: '' };
    lead.classes = classNames("map-controlbox float-right", { "controlbox-mobile": isMobile, "controlbox-desktop": !isMobile });

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Suspense fallback={loading()}>
                <React.Fragment>

                    {isLoaded ?

                        <div style={{ width: '100%', height: '100%' }} className="map-box">
                            <GoogleMap
                                onLoad={onMapLoad}
                                onClick={onMapClick}
                                onDbClick={onMapDbClick}
                                id='example-map'
                                mapContainerStyle={{
                                    height: '100%',
                                    minHeight: isMobile ? "550px" : "500px",
                                    width: "100%"
                                }}
                                options={{ fullscreenControl: false }}
                                zoom={15}
                                mapTypeId={isHybrid ? "hybrid" : "roadmap"}
                                center={mapCenter}
                            >
                                <Polygon
                                    onLoad={onLoadPolygon}
                                    paths={locationPaths}
                                    options={options}
                                />
                                <Marker draggable onDragEnd={onMarkerDragEnd} position={mapCenter} />
                                
                                <Autocomplete
                                    onLoad={onLoad}
                                    onPlaceChanged={onPlaceChanged}
                                >
                                    <InputGroup className="map-inputgroup">

                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText>
                                                <i className="fa fa-location-arrow"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input value={searchBoxValue} onChange={(e) => setSearchBoxValue(e.target.value)} style={{
                                            height: `50px`,
                                        }} type="text" />
                                        {/* <input
      type="text"
      className="form-control"
      placeholder="Search Places"
      style={{
        boxSizing: `border-box`,
        border: `1px solid transparent`,
        width: `300px`,
        height: `50px`,
        padding: `0 12px`,
        borderRadius: `3px`,
        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
        fontSize: `14px`,
        outline: `none`,
        textOverflow: `ellipses`,
        position: "absolute",
        // left: "50%",
        // marginLeft: "-120px"
      }}
    /> */}
                                    </InputGroup>
                                </Autocomplete>

                                 <DrawingManager
                                    drawingMode={"polygon"}
                                    options={{
                                        // defaultDrawingMode:"polygon",
                                        drawingMode: null,
                                        drawingControl: true,
                                        drawingControlOptions: {
                                            position: isMobile ? "10" : "2",
                                            drawingModes: [
                                                // 'marker',
                                                // 'polyline',
                                                'polygon',

                                                // 'rectangle',
                                            ],
                                        },
                                        polygonOptions: {
                                            // fillColor: `red`,
                                            // fillOpacity: 1,
                                            strokeColor: 'red',
                                            strokeWeight: 5,
                                            clickable: false,
                                            editable: true,
                                            zIndex: 1,
                                        },
                                        circleOptions: {
                                            fillColor: `red`,
                                            fillOpacity: 1,
                                            strokeWeight: 5,
                                            clickable: false,
                                            editable: true,
                                            zIndex: 1,
                                        },
                                    }}
                                    onPolygonComplete={onPolygonComplete}
                                    onLoad={onLoadDrawing}
                                    onOverlayComplete={onOverlayComplete}
                                >

                                </DrawingManager> 



                                <div className={lead.classes} >


                                    <Button id='mapadd-address' onClick={handleAddMapAddress} disabled={!allowAddLocation} className="btn-circle btn-md btn-shadow btn-white mb-2"><i className="fa fa-plus fa-2x"></i></Button>
                                    <UncontrolledTooltip placement="left" target="mapadd-address">
                                        Select Map Address
</UncontrolledTooltip >

                                    <Button id='harvestlocrefreshmap' onClick={clearMap} className="btn-circle btn-md btn-shadow btn-white mb-2"><i className="fa fa-sync fa-2x"></i></Button>
                                    <UncontrolledTooltip placement="left" target="harvestlocrefreshmap">
                                        Refresh Map
      </UncontrolledTooltip>

                                    <Button id='mapadd-help' className="btn-circle btn-md btn-shadow btn-white mb-2"><i className="fa fa-info fa-2x"></i></Button>
                                    <UncontrolledTooltip placement="left" target="mapadd-help">
                                        Help
</UncontrolledTooltip>
                                    <Button id='mapadd-showsatelliteview' onClick={changeMapType} className="btn-circle btn-md btn-shadow btn-white"><i className="fa fa-globe fa-2x"></i></Button>
                                    <UncontrolledTooltip placement="bottom" target="mapadd-showsatelliteview">
                                        {isHybrid ? "Road Map View" : "Satellite View"}
                                    </UncontrolledTooltip>
                                </div>


                            </GoogleMap>
                        </div> : loadingSpinner()}
                </React.Fragment>
            </Suspense>
            <Modal isOpen={showConfirmLocationForm} toggle={handleAddMapAddress} backdrop={"static"}
                className={'modal-md'}>
                <ModalHeader toggle={() => handleAddMapAddress()} >
                    <div class="d-flex">
                        <i className="far fa-address-card"><span className="ml-3 modal-form-title">Confirm Selected Address</span> </i>

                    </div>

                </ModalHeader>
                <ModalBody>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">
                            <InputGroupText>Address</InputGroupText>
                        </InputGroupAddon>
                        <Input
                            name="locationaddress"
                            type="text"
                            placeholder="Address"
                            value={locationName}
                        />
                    </InputGroup>

                    {/* <CreateHarvestLocMapForm containerGuid={container.guid} locationName={locationName} locationPaths={locationPaths} onSubmitSuccess={handleAddHarvestLocationSuccess} onSubmitError={handleAddHarvestLocationSubmitError} onCancel={handleAddHarvestLocation}></CreateHarvestLocMapForm> */}

                </ModalBody>
                <ModalFooter>
                    <Button className="btn-block" color="success" onClick={handleSelectionSuccess}>OK</Button>{' '}
                    <Button className="btn btn-danger float-right" color="secondary" onClick={handleAddMapAddress}>Cancel</Button>
                    <div className="mt-4">
                        {/* <button type="submit" className="btn btn-success" >Create</button>
                                                <button className="btn btn-danger float-right"  >Cancel</button> */}
                    </div>
                </ModalFooter>
            </Modal>
        </div>
    );

}



export default MapPoints;