import React, {useState} from 'react';
import Oidc from 'oidc-client'
import { useHistory } from "react-router-dom";
import {connect, useSelector, useDispatch} from "react-redux"
import { CallbackComponent } from "redux-oidc";
import userManager from "../userManager";
import { Button, Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { toast } from 'react-toastify';
import { isNullOrEmpty } from '../../utils/SyncUtils';
import { setLastRoute } from '../../app/reducers/UserConfigStateReducer'
//import {} from './app/'

// function Callback(props) {
//     let history = useHistory();

//     new Oidc.UserManager({response_mode:"query"}).signinRedirectCallback().then(function(user) {
//         //window.location = "index.html";
//         console.log("Charles", user)
//         history.push('/dashboard')
//     }).catch(function(e) {
//         console.error(e);
//     });

//   return (
//       <div>
//           Callback Page
//       </div>
//   )
//   }

const Callback = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [showErrorBox, setShowErrorBox] = useState(false)
    const { lastRoute } = useSelector(state => state.userConfigState)
    
    const handleShowErrorBox = () => {
      setShowErrorBox(!showErrorBox);
  }

  const handleShowError = () => {
    setShowErrorBox(false);
  }
    return (
      <CallbackComponent
        userManager={userManager}
        successCallback={() => {
          //handleShowErrorBox();
         // toast.success(`Prospects Removal Successful`);
        //  toast.error("Either Date-Time is wrong or Time-zone is not properly set", {
        //   position: "top-center",
        //   autoClose: false,
        //   hideProgressBar: true,
        //   closeOnClick: false,
        //   pauseOnHover: true,
        //   draggable: true
        //   })
          var callbackRoute = "/dashboard";
          if (!isNullOrEmpty(lastRoute)){
            if (lastRoute !== "/"){
              callbackRoute = lastRoute;
              dispatch(setLastRoute(""))
            }
          }
          console.log("Success", callbackRoute);
          history.push(callbackRoute);
        }}
        errorCallback={error => {
          //console.log(error, "Ejedawe");
          if (error)
          {
            //console.error(error.message, "Carlospence", error.name)
            if (error.message.includes("exp is in the past") || error.message.includes("iat is in the future"))
            {
              //handleShowErrorBox();
              toast.error("Either Date-Time is wrong or Time-zone is not properly set", {
                position: "top-center",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                })
            }
          }
         
          //handleShowErrorBox();
          history.push("/dashboard");
          console.error(error);
        }}
      >
        <div>Redirecting...
        <Modal isOpen={showErrorBox} toggle={handleShowErrorBox} 
                className={'modal-md modal-confirm danger'}>
                <ModalHeader toggle={handleShowErrorBox}>
                    <div class="icon-box danger">
                        <i class="far fa-times-circle danger"></i>
                    </div>
                    <h4 > Error</h4>
                </ModalHeader>
                <ModalBody>
                    <p class="text-center">Either Date-Time is wrong or Time-zone is not properly set</p>
                </ModalBody>
                <ModalFooter>
                    <Button className="btn-block" color="danger" onClick={handleShowError}>OK</Button>{' '}
                </ModalFooter>
            </Modal>
        </div>
       
      </CallbackComponent>
    );
  };

  export default Callback;