import React, {Fragment} from 'react'
import NoData from '../assets/img/NoData.png'
import { Spinner, Col } from 'reactstrap';
import { CCol, CRow } from "@coreui/react";
import {CloseIcon} from './Icons'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMediaQuery } from "react-responsive";

export const TableCaption = (props) => {

    return (
<div className="group-caption">{props.caption || ""}</div>
    )

}

export const UserTableCaption = (props) => {

  return (
<div className="user-caption">{props.caption || ""}</div>
  )

}

export const DynamicTableCaption = (props) => {

  return (
<div className="dynamic-table-caption">{props.caption || ""}</div>
  )

}

export const EmptyRowsView = (props) => {
    const message = props.message || "No data to show at the moment..";
    return (
        <div
            style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px 25px 100px 25px" }}
        >
            <img src={NoData} alt={message} height="35px" className="mb-2"/>
            <h5>{message}</h5>
        </div>
    );
};

export const LoadingRowsView = (props) => {
  const message = props.message || "Still Loading, please wait..";
  return (
      <div
          style={{ textAlign: "center", backgroundColor: "#ddd", padding: "100px" }}
      >
          <Spinner  color="danger" className="ml-2 mr-3 mb-3" />
          <h4>{message}</h4>
      </div>
  );
};

export const LoadingSpinner = ({ closeToast, message = 'Loading...', color = "white" }) => (
    <div>
     <div className="mr-3">
     <Spinner size="sm" color={color} className="ml-2 mr-3" />
     {message}
     </div>
    
    </div>
  )

  export const LoadingFullPageSpinner = ({
    showMessage = true,
    message = "Loading...",
    diffSize = 90
  }) => (
    <Fragment>
      <div
        className="d-flex justify-content-center  align-items-center px-4 mt-5"
        style={{ minHeight: `calc(100vh - ${diffSize}px)` }}
      >
        {/* <strong>Loading...</strong> */}
        <CRow>
          <CCol sm={12} className="d-flex justify-content-center ">
          <Spinner  animation="border" role="status">
          <span className="visually-hidden">{message}</span>
        </Spinner>
          </CCol>
          {showMessage &&  <Col sm={12} className="d-flex justify-content-center mt-2">
          <p>{message}</p>
          </Col>}
         
        </CRow>
      </div>
    </Fragment>
  );

  export const SimpleLoadingSpinner = () => (
  <div style={{ width: '100%', height: '100%' }} className="animated fadeIn pt-1 text-center">
    <Spinner></Spinner>
  
    </div>)

export const SmallLoadingSpinner = () => (
  <div style={{ width: '100%', height: '100%' }} className="animated fadeIn pt-1 text-center">
    <Spinner size="sm"></Spinner>
  
    </div>)

export const LoadingSpinnerWithText = ({message = ''}) => (
  <div style={{ width: '100%', height: '100%' }} className="animated fadeIn pt-1 text-center">
    <Spinner></Spinner>
    <div>
    {message}
    </div>
   
    </div>)

  export const UpdateServiceWorker = ({ closeToast, message = 'There is a new version available.', buttonText = "Update", onClick }) => (
    <div>
     <div className="">
     {message} {buttonText && <button className="btn btn-success btn-sm" onClick={onClick}>{buttonText}</button>}
     </div>
    
    </div>
  )

  export const InstallPWA = ({ closeToast, message = 'Install soWin App.', buttonText = "Install", onClick }) => (
    <div>
     <div className="">
     {message} {buttonText && <button className="btn btn-warning btn-sm" onClick={onClick}>{buttonText}</button>}
     </div>
    
    </div>
  )

  export const NotificationPermission = ({ closeToast, message = 'Receive notifications from soWin and your Groups', buttonText = "Accept", onClick }) => (
    <div>
     <div className="">
     {message} {buttonText && <button className="btn btn-warning btn-sm" onClick={onClick}>{buttonText}</button>}
     </div>
    
    </div>
  )

  export const LoadingAlertConfig = {  position: 'top-center', 
    autoClose: false, 
    closeButton: false,
     className:'toast-container-alert'}



     export const FallbackComponent = ({ error, componentStack, resetErrorBoundary }) => {
      return (
        <div>
          <h1>An error occurred: {error.message}</h1>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      );
    };
    
    export function ErrorFallback({error, componentStack, resetErrorBoundary}) {
      return (
        <div role="alert">
          <p>Something went wrong:</p>
          <pre style={{color: 'red'}}>{error.message}</pre>
          <pre>{componentStack}</pre>
          <button onClick={resetErrorBoundary}>Try again</button>
        </div>
      )
    }


    export function DrawerHeader({onClose, header}) {
      const isMobile = useMediaQuery({ maxWidth: 767 });
      const handleClose = () => {
        if (onClose){
          onClose();
        }
      }

      return (
         <Fragment>
          <div className='app-page-header'>
         <div className="h5 font-bold text-ellipsis text-truncate ml-2"  style={isMobile ? { maxWidth: "250px" } : {}}>
         {header}
            </div>

            <div className="ml-auto d-flex align-items-center">
            <button className='btn-drawer-header' onClick={handleClose}>   <FontAwesomeIcon
                          icon="times"
                          size='lg'
                        ></FontAwesomeIcon></button>
            </div>
            </div>
         </Fragment>
          //  <div class="drawer-header" >
          //  <div className=''>
          //    {header}
          //  </div>
          //  <div className='ml-auto'>
          //  <button className='btn-drawer-header' onClick={handleClose}>   <FontAwesomeIcon
          //                  icon="times"
          //                  size='lg'
          //                ></FontAwesomeIcon></button>
          //  </div>
          //  </div>       
      )
    }

    export function DrawerHeaderBackButton({onClose, header}) {
      const isMobile = useMediaQuery({ maxWidth: 767 });
      const handleClose = () => {
        if (onClose){
          onClose();
        }
      }

      return (
         <Fragment>
          <div className='app-page-header'>
         <div className="font-bold text-ellipsis text-truncate ml-2"  style={isMobile ? { maxWidth: "250px" } : {}}>
        
         <button className='d-flex btn-drawer-header' onClick={handleClose}>   <FontAwesomeIcon
                          icon={isMobile ? "arrow-left" : "times"}
                          className="fa-1-5x mfe-2"
                        ></FontAwesomeIcon></button>
                        {/* <div className="ml-auto d-flex align-items-center">
            {header}
            </div> */}
            </div>

            <div className="d-flex align-items-center">
            {header}
            </div>
            </div>
         </Fragment>
          //  <div class="drawer-header" >
          //  <div className=''>
          //    {header}
          //  </div>
          //  <div className='ml-auto'>
          //  <button className='btn-drawer-header' onClick={handleClose}>   <FontAwesomeIcon
          //                  icon="times"
          //                  size='lg'
          //                ></FontAwesomeIcon></button>
          //  </div>
          //  </div>       
      )
    }


