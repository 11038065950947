
import config from '../../config'
import Oidc from 'oidc-client'
import userManager from '../../auth/userManager'
// import AuthService from '../services/AuthService'


export async function allHeader(){
    let result = await authHeader()
    //console.log(result);
    return result;
}

export async function accessToken(){
    let result = await authToken()
    //console.log(result);
    return result;
}

export function authToken() {
    return new Promise((resolve) => {
        userManager.getUser().then(function (user) {
            if (user && !user.expired) {
                // console.log("User logged in", user.profile);
                
                 resolve(user.access_token)
                //log("User logged in", user.profile)
            }
            else{
                resolve("")
            }
        
            
        }).finally(() => {
            resolve("")
        });
    });
}

export function authHeader() {
    // return authorization header with jwt token
    //var Auth = new AuthService();
    var result = {
        "kaapps-app-clientid": config.api.app_id,
        "kaapps-app-publickey": config.api.app_public_key,
    };
   // var user = null;
    

    return new Promise((resolve) => {
        userManager.getUser().then(function (user) {
            if (user && !user.expired) {
                // console.log("User logged in", user.profile);
                result =  {
                    'Authorization': 'Bearer ' + user.access_token,
                     "X-Requested-With": "XMLHttpRequest",
                     "kaapps-app-clientid": config.api.app_id,
                     "kaapps-app-publickey": config.api.app_public_key,
                 };
                 resolve(result)
                //log("User logged in", user.profile)
            }
            else{
              result = {
                "kaapps-app-clientid": "config.api.app_id",
                "kaapps-app-publickey": "config.api.app_public_key",
                };
                resolve(result)
            }
        
            
        }).finally(() => {
            resolve(result)
        });
    });
    // if (Auth.loggedIn()) {
    //     return {
    //         'Authorization': 'Bearer ' + Auth.getToken(),
    //         "X-Requested-With": "XMLHttpRequest",
    //         "Kaapps-App-ClientID": config.api.app_id,
    //         "Kaapps-App-PublicKey": config.api.app_public_key,
    //     };
    // } else {
    //     return {
    //         "Kaapps-App-ClientID": config.api.app_id,
    //         "Kaapps-App-PublicKey": config.api.app_public_key,
    //     };
    // }
}

export function kaappsHeader()
{
    return {
        "kaapps-app-clientid": config.api.app_id,
        "kaapps-app-publickey": config.api.app_public_key,
    };
}