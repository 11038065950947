import { createSlice } from '@reduxjs/toolkit'
import { array_move, isEmpty } from '../../utils/SyncUtils'

export const initialState = {
    prospectSettingsState: {prospectWinner: "", contactMode: {}},
    lastProspectSortMode: "LabelNewest",
    lastUsersListSortMode: "Oldest",
    lastReportDateSelectionType: "DateRange",
    myProspectsLastUpdate: null,
    myDashboardLastUpdate: null,
    myDashboardDataLastUpdate: null,
    myGroupsLastUpdate: null,
    groupProspectsLastUpdate: null,
    groupDashboardLastUpdate: null,
    groupDashboardDataLastUpdate: null,
    lastUpdatedGroup: null,
    lastAction: null,
    initialization: null,
    lastRoute: "",
    followUpAttemptPhoneCalls: [],
    followUpCallbacks: [],
    modalOpened: false,
    closeAllModals: false,
    modalStates: [],
    openedDrawers: [],
    drawerToClose: {},


};


const userConfigStateSlice = createSlice({
    name: 'userConfigState',
    initialState,
    reducers: {
        setModalOpened: (state,  { payload }) => {
            //  console.log(payload, "setting Modal Opened State")
             return { ...state, modalOpened : payload  }
         },
         setCloseAllModals: (state,  { payload }) => {
            // console.log(payload, "setting Close All Modals State")
            return { ...state, closeAllModals : payload  }
        },
        setProspectSettingsState: (state,  { payload }) => {
            return { ...state, prospectSettingsState : payload  }
        },
        setLastProspectSortMode: (state,  { payload }) => {
            return { ...state, lastProspectSortMode : payload  }
        },
        setLastUsersListSortMode: (state,  { payload }) => {
            return { ...state, lastUsersListSortMode : payload  }
        },
        setLastReportDateSelectionType: (state,  { payload }) => {
            return { ...state, lastReportDateSelectionType : payload  }
        },
        setMyProspectsLastUpdate: (state,  { payload }) => {
            return { ...state, myProspectsLastUpdate : payload  }
        },
        setMyDashboardLastUpdate: (state,  { payload }) => {
            return { ...state, myDashboardLastUpdate : payload  }
        },
        setMyDashboardDataLastUpdate: (state,  { payload }) => {
            return { ...state, myDashboardDataLastUpdate : payload  }
        },
        setGroupProspectsLastUpdate: (state,  { payload }) => {
            return { ...state, groupProspectsLastUpdate : payload  }
        },
        setGroupDashboardLastUpdate: (state,  { payload }) => {
            return { ...state, groupDashboardLastUpdate : payload  }
        },
        setGroupDashboardDataLastUpdate: (state,  { payload }) => {
            return { ...state, groupDashboardDataLastUpdate : payload  }
        },
        setLastUpdatedGroup: (state,  { payload }) => {
            return { ...state, lastUpdatedGroup : payload  }
        },
        setLastAction: (state,  { payload }) => {
            return { ...state, lastAction : payload  }
        },
        setInitialization: (state,  { payload }) => {
            return { ...state, initialization : payload  }
        },
        setMyGroupsLastUpdate: (state,  { payload }) => {
            return { ...state, myGroupsLastUpdate : payload  }
        },
        setLastRoute: (state,  { payload }) => {
            return { ...state, lastRoute : payload  }
        },
        addPhoneCallAttempt: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                if (state.followUpAttemptPhoneCalls !== undefined) {
                    let amExisting = state.followUpAttemptPhoneCalls.findIndex((element) => {
                        return element.guid.toLowerCase() === payload.guid.toLowerCase();
                    })
                    if (amExisting >= 0) {
                        if (!isEmpty(serializedPayload)) {
                            state.followUpAttemptPhoneCalls[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.
    
                        }
                    }
                    else {
                        state.followUpAttemptPhoneCalls.unshift(payload)
                    }
                } else {
                    console.log("Please close and reload application")
                }
            }
           
        },
        removePhoneCallAttempt: (state, { payload }) => {
            var newPhoneCallAttempts = state.followUpAttemptPhoneCalls.filter(x => {
                return x.guid !== payload.guid;
            })
            return { ...state, followUpAttemptPhoneCalls: newPhoneCallAttempts }
        },

        clearPhoneCallAttempts: (state) => {

            return { ...state, followUpAttemptPhoneCalls: [] }
        },

        setPhoneCallAttempts: (state, { payload }) => {

            return { ...state, followUpAttemptPhoneCalls: payload }
        },
        addPhoneCallback: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.guid !== null && payload.guid !== undefined) {
                if (state.followUpCallbacks !== undefined) {
                    let amExisting = state.followUpCallbacks.findIndex((element) => {
                        return element.guid.toLowerCase() === payload.guid.toLowerCase();
                    })
                    if (amExisting >= 0) {
                        if (!isEmpty(serializedPayload)) {
                            state.followUpCallbacks[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.
    
                        }
                    }
                    else {
                        state.followUpCallbacks.unshift(payload)
                    }
                } else {
                    console.log("Please close and reload application")
                }
                


            }
           
        },
        removePhoneCallback: (state, { payload }) => {
            var newPhoneCallbacks = state.followUpCallbacks.filter(x => {
                return x.guid !== payload.guid;
            })
            return { ...state, followUpCallbacks: newPhoneCallbacks }
        },

        clearPhoneCallbacks: (state) => {

            return { ...state, followUpCallbacks: [] }
        },

        setPhoneCallbacks: (state, { payload }) => {

            return { ...state, followUpCallbacks: payload }
        },
        addOrUpdateModalStates: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))

            if (payload.url !== null && payload.url !== undefined && state.modalStates !== undefined) {
                let amExisting = state.modalStates.findIndex((element) => {
                    return element.url.toLowerCase() === payload.url.toLowerCase() && element.key.toLowerCase() === payload.key.toLowerCase();
                })
                if (amExisting >= 0) {
                    if (!isEmpty(serializedPayload)) {
                        state.modalStates[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.

                    }
                }
                else {
                    state.modalStates.unshift(payload)
                }


            }
           
        },
        removeModalState: (state, { payload }) => {
            var newModalStates = state.modalStates.filter(x => {
                return x.key.toLowerCase() !== payload.key.toLowerCase() && x.url.toLowerCase() !== payload.url.toLowerCase();
            })
            return { ...state, modalStates: newModalStates }
        },
        removeModalStateByUrl: (state, { payload }) => {
            var newModalStates = state.modalStates.filter(x => {
                return x.url.toLowerCase() !== payload.url.toLowerCase();
            })
            return { ...state, modalStates: newModalStates }
        },
        keepModalStateByUrl: (state, { payload }) => {
            var newModalStates = state.modalStates.filter(x => {
                return x.url.toLowerCase() === payload.url.toLowerCase();
            })
            return { ...state, modalStates: newModalStates }
        },
        clearModalStates: (state) => {

            return { ...state, modalStates: [] }
        },
        addOrUpdateDrawerInfo: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))
            if (state.openedDrawers !== null && state.openedDrawers !== undefined){
                if (payload.url !== null && payload.url !== undefined) {
                    let amExisting = state.openedDrawers.findIndex((element) => {
                        return element.url.toLowerCase() === payload.url.toLowerCase() && element.key.toLowerCase() === payload.key.toLowerCase();
                    })
                    if (amExisting >= 0) {
                        if (!isEmpty(serializedPayload)) {
                            state.openedDrawers[amExisting] = serializedPayload; //This Update works because am using createReducer from Redux Toolkit and Immer.
    
                        }
                    }
                    else {
                        state.openedDrawers.push(payload)
                    }
    
    
                }
            } else {
                var dataInfo = [];
                dataInfo.push(payload);
                return { ...state, openedDrawers: dataInfo }
            }
            
           
        },
        removeDrawerInfo: (state, { payload }) => {
            var newDrawerInfos = state.openedDrawers.filter(x => {
                return x.key.toLowerCase() !== payload.key.toLowerCase() && x.url.toLowerCase() !== payload.url.toLowerCase();
            })
            return { ...state, openedDrawers: newDrawerInfos }
        },
        removeDrawerInfoByUrl: (state, { payload }) => {
            var newDrawerInfos = state.openedDrawers.filter(x => {
                return x.url.toLowerCase() !== payload.url.toLowerCase();
            })
            return { ...state, openedDrawers: newDrawerInfos }
        },
        clearDrawerInfo: (state) => {

            return { ...state, openedDrawers: [] }
        },
        setDrawerToClose: (state, { payload }) => {
            var serializedPayload = JSON.parse(JSON.stringify(payload))
            if (state.drawerToClose !== null && state.drawerToClose !== undefined){
                return { ...state, drawerToClose: serializedPayload }
            } else {
                return { ...state, drawerToClose: {} }
            }
           
           
        },
        resetDrawerToClose: (state) => {
            return { ...state, drawerToClose: {} }
           
        },
    }
});



// The Actions
export const {setProspectSettingsState, setLastProspectSortMode, setLastUsersListSortMode,
setLastReportDateSelectionType, setGroupDashboardLastUpdate, setGroupDashboardDataLastUpdate, setGroupProspectsLastUpdate, setLastUpdatedGroup,
setMyDashboardLastUpdate, setMyDashboardDataLastUpdate, setMyProspectsLastUpdate, setLastAction, setInitialization, setMyGroupsLastUpdate, setLastRoute,
addPhoneCallAttempt, removePhoneCallAttempt, clearPhoneCallAttempts, setPhoneCallAttempts, setModalOpened, setCloseAllModals, addOrUpdateModalStates, clearModalStates, 
removeModalState, removeModalStateByUrl, keepModalStateByUrl, addPhoneCallback, removePhoneCallback, clearPhoneCallbacks, setPhoneCallbacks,
addOrUpdateDrawerInfo, removeDrawerInfo, removeDrawerInfoByUrl, clearDrawerInfo, setDrawerToClose,  resetDrawerToClose } = userConfigStateSlice.actions;

// A selector
export const userConfigStateSelector = state => state.userConfigState

// The reducer
export default userConfigStateSlice.reducer
